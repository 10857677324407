import React, { useState, useEffect } from 'react';
import Notification from '../../components/Notification';
import { X } from 'lucide-react';
import API_CONFIG from '../../config/api';
interface User {
  _id: string;
  email: string;
  actif: boolean;
  dateCreation: string;
  dernierConnexion?: string;
  nomClient: string;
  nom: string;
  prenom: string;
}

interface PasswordChangeForm {
  userId: string;
  newPassword: string;
}

interface NotificationState {
  show: boolean;
  message: string;
  type: 'success' | 'error';
}

const CLIENTS = [
  'ABN AMRO',
  'ACE4RSE',
  'BPMED',
  'CRÉDIT AGRICOLE NORMANDIE',
  'CRÉDIT AGRICOLE SUD MEDITERRANÉE',
  'CAIXA GERAL DE DEPOSITOS',
  'BANQUE CHAABI DU MAROC',
  'CRCAM SUD RHÔNE ALPES',
  'CRÉDIT AGRICOLE ATLANTIQUE VENDÉE',
  'CRÉDIT AGRICOLE BRIE PICARDIE',
  'CRÉDIT AGRICOLE CENTRE France',
  'CRÉDIT AGRICOLE CENTRE OUEST',
  'CRÉDIT AGRICOLE CHAMPAGNE BOURGOGNE',
  'CRÉDIT AGRICOLE CORSE',
  'CRÉDIT AGRICOLE DE GUADELOUPE',
  'CRÉDIT AGRICOLE FINISTERE',
  'CRÉDIT AGRICOLE LANGUEDOC',
  'CRÉDIT AGRICOLE LOIRE HAUTE LOIRE',
  'CRÉDIT AGRICOLE MORBIHAN',
  'CRÉDIT AGRICOLE MUTUEL PYRÉNÉES GASCOGNE',
  'CRÉDIT AGRICOLE NORD DE FRANCE',
  'CRÉDIT AGRICOLE NORD EST',
  'CRÉDIT AGRICOLE PROVENCE CÔTE D\'AZUR',
  'CREDIT AGRICOLE VAL DE FRANCE',
  'GIE USCC / CRÉDIT COOPÉRATIF',
  'MILLEIS / BARCLAY',
  'SANTANDER',
  'TECHDATA France',
  'CLIENTS',
  'CADIF'
];

const UserManagement: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    nomClient: '',
    nom: '',
    prenom: ''
  });
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [passwordForm, setPasswordForm] = useState<PasswordChangeForm>({
    userId: '',
    newPassword: ''
  });
  const [notification, setNotification] = useState<NotificationState>({
    show: false,
    message: '',
    type: 'success'
  });

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem('adminToken');
      const response = await fetch(`${API_CONFIG.baseURL}/api/users`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      setUsers(data.users || []);
    } catch (error) {
      console.error('Erreur lors de la récupération des utilisateurs:', error);
      showNotification('Erreur lors de la récupération des utilisateurs', 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const showNotification = (message: string, type: 'success' | 'error') => {
    setNotification({
      show: true,
      message,
      type
    });
  };

  const handleCreateUser = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('adminToken');
      
      const response = await fetch(`${API_CONFIG.baseURL}/api/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(formData)
      });

      const responseData = await response.json();

      if (response.ok) {
        setShowCreateForm(false);
        setFormData({
          email: '',
          password: '',
          nomClient: '',
          nom: '',
          prenom: ''
        });
        fetchUsers();
        showNotification('Utilisateur créé avec succès', 'success');
      } else {
        showNotification(responseData.message || 'Erreur lors de la création', 'error');
      }
    } catch (error) {
      showNotification('Erreur lors de la création de l\'utilisateur', 'error');
    }
  };

  const handleToggleStatus = async (userId: string, currentStatus: boolean) => {
    try {
      const token = localStorage.getItem('adminToken');
      const response = await fetch(`${API_CONFIG.baseURL}/api/users/${userId}/status`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ actif: !currentStatus })
      });

      if (response.ok) {
        fetchUsers();
        showNotification(
          `Utilisateur ${!currentStatus ? 'activé' : 'désactivé'} avec succès`,
          'success'
        );
      } else {
        showNotification('Erreur lors de la mise à jour du statut', 'error');
      }
    } catch (error) {
      showNotification('Erreur lors de la mise à jour du statut', 'error');
    }
  };

  const handlePasswordChange = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('adminToken');
      const response = await fetch(`${API_CONFIG.baseURL}/api/users/${passwordForm.userId}/password`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ newPassword: passwordForm.newPassword })
      });

      if (response.ok) {
        setShowPasswordForm(false);
        setPasswordForm({
          userId: '',
          newPassword: ''
        });
        showNotification('Mot de passe modifié avec succès', 'success');
      } else {
        showNotification('Erreur lors de la modification du mot de passe', 'error');
      }
    } catch (error) {
      showNotification('Erreur lors de la modification du mot de passe', 'error');
    }
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <div className="min-h-screen bg-gray-50 p-8">
      {notification.show && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification({ ...notification, show: false })}
        />
      )}

      <div className="max-w-7xl mx-auto">
        <div className="mb-8 grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-white rounded-xl p-6 shadow-sm">
            <h3 className="text-gray-500 text-sm font-medium">Total Utilisateurs</h3>
            <p className="text-3xl font-bold mt-2">{users.length}</p>
          </div>
          <div className="bg-white rounded-xl p-6 shadow-sm">
            <h3 className="text-gray-500 text-sm font-medium">Utilisateurs Actifs</h3>
            <p className="text-3xl font-bold mt-2 text-green-600">
              {users.filter(u => u.actif).length}
            </p>
          </div>
          <div className="bg-white rounded-xl p-6 shadow-sm">
            <h3 className="text-gray-500 text-sm font-medium">Utilisateurs Inactifs</h3>
            <p className="text-3xl font-bold mt-2 text-red-600">
              {users.filter(u => !u.actif).length}
            </p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-between items-center mb-8 gap-4">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Gestion des Utilisateurs</h1>
            <p className="text-gray-500 mt-1">Gérez vos utilisateurs et leurs accès</p>
          </div>
          <button
            onClick={() => setShowCreateForm(true)}
            className="bg-indigo-600 text-white px-6 py-3 rounded-lg hover:bg-indigo-700 
                       transition-colors duration-200 flex items-center gap-2 shadow-sm"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
            </svg>
            Nouvel Utilisateur
          </button>
        </div>

        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
          </div>
        ) : (
          <div className="bg-white rounded-xl shadow-sm overflow-hidden">
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr className="bg-gray-50">
                    <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Client
                    </th>
                    <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Utilisateur
                    </th>
                    <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Statut
                    </th>
                    <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Dernière Activité
                    </th>
                    <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {users.map((user) => (
                    <tr key={user._id} className="hover:bg-gray-50 transition-colors duration-200">
                      <td className="px-6 py-4">
                        <div className="text-sm font-medium text-gray-900">{user.nomClient}</div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="flex items-center">
                          <div className="h-10 w-10 rounded-full bg-indigo-100 flex items-center justify-center">
                            <span className="text-indigo-600 font-medium text-sm">
                              {user.email.charAt(0).toUpperCase()}
                            </span>
                          </div>
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">{user.email}</div>
                            <div className="text-sm text-gray-500">
                              Créé le {formatDate(user.dateCreation)}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        <span className={`px-3 py-1 inline-flex text-xs leading-5 font-semibold rounded-full
                          ${user.actif 
                            ? 'bg-green-100 text-green-800' 
                            : 'bg-red-100 text-red-800'}`}
                        >
                          {user.actif ? 'Actif' : 'Inactif'}
                        </span>
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500">
                        {user.dernierConnexion 
                          ? formatDate(user.dernierConnexion)
                          : 'Jamais connecté'}
                      </td>
                      <td className="px-6 py-4">
                        <div className="flex space-x-4">
                          <button
                            onClick={() => handleToggleStatus(user._id, user.actif)}
                            className={`inline-flex items-center px-3 py-1 rounded-md text-sm font-medium
                              ${user.actif 
                                ? 'text-red-700 bg-red-50 hover:bg-red-100' 
                                : 'text-green-700 bg-green-50 hover:bg-green-100'
                              } transition-colors duration-200`}
                          >
                            {user.actif ? 'Désactiver' : 'Activer'}
                          </button>
                          <button
                            onClick={() => {
                              setPasswordForm({ userId: user._id, newPassword: '' });
                              setShowPasswordForm(true);
                            }}
                            className="inline-flex items-center px-3 py-1 rounded-md text-sm font-medium
                                     text-indigo-700 bg-indigo-50 hover:bg-indigo-100 transition-colors duration-200"
                          >
                            Modifier MDP
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {showCreateForm && (
          <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50">
            <div className="bg-white rounded-xl shadow-xl w-full max-w-md p-6 transform transition-all">
              <div className="flex justify-between items-start mb-4">
                <h3 className="text-xl font-bold text-gray-900">Créer un utilisateur</h3>
                <button
                  onClick={() => setShowCreateForm(false)}
                  className="text-gray-400 hover:text-gray-600"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>

              <form onSubmit={handleCreateUser} className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Client
                  </label>
                  <select
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                    value={formData.nomClient}
                    onChange={(e) => setFormData({...formData, nomClient: e.target.value})}
                  >
                    <option value="">Sélectionnez un client</option>
                    {CLIENTS.map((client) => (
                      <option key={client} value={client}>{client}</option>
                    ))}
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Nom
                  </label>
                  <input
                    type="text"
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                    value={formData.nom}
                    onChange={(e) => setFormData({...formData, nom: e.target.value})}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Prénom
                  </label>
                  <input
                    type="text"
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                    value={formData.prenom}
                    onChange={(e) => setFormData({...formData, prenom: e.target.value})}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <input
                    type="email"
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                    value={formData.email}
                    onChange={(e) => setFormData({...formData, email: e.target.value})}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Mot de passe
                  </label>
                  <input
                    type="password"
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                    value={formData.password}
                    onChange={(e) => setFormData({...formData, password: e.target.value})}
                  />
                </div>

                <div className="flex justify-end space-x-3">
                  <button
                    type="button"
                    onClick={() => setShowCreateForm(false)}
                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                  >
                    Annuler
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
                  >
                    Créer
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {showPasswordForm && (
          <div className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm flex items-center justify-center z-50">
            <div className="bg-white rounded-xl shadow-xl w-full max-w-md p-6">
              <h3 className="text-xl font-bold text-gray-900 mb-6">Modifier le mot de passe</h3>
              <form onSubmit={handlePasswordChange} className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Nouveau mot de passe
                  </label>
                  <input
                    type="password"
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                    value={passwordForm.newPassword}
                    onChange={(e) => setPasswordForm({
                      ...passwordForm,
                      newPassword: e.target.value
                    })}
                  />
                </div>
                <div className="flex justify-end space-x-3">
                  <button
                    type="button"
                    onClick={() => setShowPasswordForm(false)}
                    className="px-4 py-2 border rounded-md"
                  >
                    Annuler
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-blue-600 text-white rounded-md"
                  >
                    Modifier
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserManagement; 