import React, { useState, useEffect } from 'react';
import ImageViewer from '../../components/ui/ImageViewer';
import { motion } from 'framer-motion';
import { MultiStepLoader } from '../../components/ui/multi-step-loader';
import { IconSquareRoundedX } from "@tabler/icons-react";
import { PlaceholdersAndVanishInput } from '../../components/ui/placeholders-and-vanish-input';
import JSZip from 'jszip';
import API_CONFIG from '../../config/api';

interface Demande {
  _id: string;
  reference: string;
  nom: string;
  prenom: string;
  email: string;
  typeDocument: string;
  pays: string;
  dateDemande: string;
  dateEnvoi?: string;
  commentaires: string;
  images: string[];
  certificates: string[];
  statut: 'en_attente' | 'en_cours' | 'terminé' | 'rejeté';
  resultatExpertise: 'OK' | 'KO' | null;
}

interface ImageViewerState {
  isOpen: boolean;
  imageUrl: string;
  fileName: string;
}

// Ajout des interfaces pour les filtres
interface Filters {
  typeDocument: string;
  pays: string;
  statut: string;
  resultat: string;
  month: string;
  year: string;
}

const loadingStates = [
  {
    text: "Réception de votre demande",
    status: 'en_attente'
  },
  {
    text: "Vérification des documents",
    status: 'en_cours'
  },
  {
    text: "Analyse de votre dossier",
    status: 'en_cours'
  },
  {
    text: "Traitement en cours",
    status: 'en_cours'
  },
  {
    text: "Finalisation de votre demande",
    status: 'terminé'
  }
];

const getStepIndex = (status: string) => {
  switch (status) {
    case 'en_attente':
      return 0;
    case 'en_cours':
      return 3; // Jusqu'à "Traitement en cours"
    case 'terminé':
      return 4; // Jusqu'à la fin
    case 'rejeté':
      return 1; // S'arrête après la vérification
    default:
      return 0;
  }
};

const placeholders = [
  "Rechercher par type de document...",
  "Rechercher par pays...",
  "Ex: Passeport France",
  "Ex: Visa Chine",
  "Ex: Carte consulaire Maroc"
];

const HistoriqueDemandes: React.FC = () => {
  const [demandes, setDemandes] = useState<Demande[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState<Filters>({
    typeDocument: '',
    pays: '',
    statut: '',
    resultat: '',
    month: '',
    year: ''
  });
  const [selectedDemande, setSelectedDemande] = useState<Demande | null>(null);
  const [showLoader, setShowLoader] = useState(false);
  const [imageViewer, setImageViewer] = useState<ImageViewerState>({
    isOpen: false,
    imageUrl: '',
    fileName: ''
  });

  useEffect(() => {
    fetchDemandes(currentPage);
  }, [currentPage, searchTerm, filters]);

  const fetchDemandes = async (page: number) => {
    try {
      setLoading(true);
      const token = localStorage.getItem('userToken');
      const searchParams = new URLSearchParams({
        page: page.toString(),
        search: searchTerm,
        ...filters
      });

      const response = await fetch(`${API_CONFIG.baseURL}/api/demandes/mes-demandes?${searchParams}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const data = await response.json();
      setDemandes(data.demandes);
      setTotalPages(data.pagination.pages);
      setLoading(false);
    } catch (error) {
      console.error('Erreur lors de la récupération des demandes:', error);
      setLoading(false);
    }
  };

  // Composant de pagination
  const Pagination = () => (
    <div className="flex justify-center space-x-2 mt-4">
      <button
        onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
        disabled={currentPage === 1}
        className="px-4 py-2 border rounded-md disabled:opacity-50"
      >
        Précédent
      </button>
      
      {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
        <button
          key={page}
          onClick={() => setCurrentPage(page)}
          className={`px-4 py-2 border rounded-md ${
            currentPage === page ? 'bg-blue-500 text-white' : ''
          }`}
        >
          {page}
        </button>
      ))}

      <button
        onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))}
        disabled={currentPage === totalPages}
        className="px-4 py-2 border rounded-md disabled:opacity-50"
      >
        Suivant
      </button>
    </div>
  );

  // Fonctions utilitaires
  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'en_attente':
        return 'bg-yellow-100 text-yellow-800';
      case 'en_cours':
        return 'bg-blue-100 text-blue-800';
      case 'terminé':
        return 'bg-green-100 text-green-800';
      case 'rejeté':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getImageUrl = (filename: string) => {
    const token = localStorage.getItem('userToken');
    return `${API_CONFIG.baseURL}/uploads/images/${filename}`;
  };

  const getCertificateUrl = (filename: string) => {
    const token = localStorage.getItem('userToken');
    return `${API_CONFIG.baseURL}/uploads/certificates/${filename}`;
  };

  const handleImageClick = (filename: string) => {
    setImageViewer({
      isOpen: true,
      imageUrl: getImageUrl(filename),
      fileName: filename
    });
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    fetchDemandes(1); // Réinitialiser à la première page lors d'une recherche
  };

  const resetFilters = () => {
    setFilters({
      typeDocument: '',
      pays: '',
      statut: '',
      resultat: '',
      month: '',
      year: ''
    });
    setCurrentPage(1); // Réinitialiser à la première page
  };

  const getUniqueOptions = (field: keyof Demande) => {
    const values = demandes.map(d => d[field]);
    return Array.from(new Set(values))
      .filter((value): value is string => 
        typeof value === 'string' && Boolean(value)
      );
  };

  // Générer les options pour les années (5 dernières années)
  const getYearOptions = () => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: 5 }, (_, i) => currentYear - i);
  };

  // Générer les options pour les mois
  const monthOptions = [
    { value: '1', label: 'Janvier' },
    { value: '2', label: 'Février' },
    { value: '3', label: 'Mars' },
    { value: '4', label: 'Avril' },
    { value: '5', label: 'Mai' },
    { value: '6', label: 'Juin' },
    { value: '7', label: 'Juillet' },
    { value: '8', label: 'Août' },
    { value: '9', label: 'Septembre' },
    { value: '10', label: 'Octobre' },
    { value: '11', label: 'Novembre' },
    { value: '12', label: 'Décembre' }
  ];

  // Ajouter une vérification de sécurité pour les compteurs
  const statusCounts = {
    en_attente: demandes?.filter(d => d.statut === 'en_attente').length || 0,
    en_cours: demandes?.filter(d => d.statut === 'en_cours').length || 0,
    terminé: demandes?.filter(d => d.statut === 'terminé').length || 0
  };

  // Nouvelle fonction pour télécharger tous les certificats
  const handleDownloadAllCertificates = async (certificates: string[]) => {
    try {
      // Créer un dossier temporaire pour le zip si plusieurs certificats
      if (certificates.length > 1) {
        const zip = new JSZip();
        
        // Ajouter chaque certificat au zip
        for (const certificate of certificates) {
          const response = await fetch(getCertificateUrl(certificate));
          const blob = await response.blob();
          // Utiliser le nom complet du fichier sans modification
          zip.file(certificate, blob);
        }
        
        // Générer et télécharger le zip
        const zipBlob = await zip.generateAsync({ type: 'blob' });
        const zipUrl = window.URL.createObjectURL(zipBlob);
        const a = document.createElement('a');
        a.href = zipUrl;
        a.download = 'certificats.zip';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(zipUrl);
        document.body.removeChild(a);
      } else {
        // Si un seul certificat, le télécharger directement
        const response = await fetch(getCertificateUrl(certificates[0]));
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        // Utiliser le nom complet du fichier sans modification
        a.download = certificates[0];
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    } catch (error) {
      console.error('Erreur lors du téléchargement des certificats:', error);
    }
  };

  return (
    <div className="space-y-6">
      {/* MultiStepLoader */}
      <MultiStepLoader 
        loadingStates={loadingStates} 
        loading={loading} 
        duration={2000}
        maxStep={demandes.length > 0 ? getStepIndex(demandes[0].statut) : 0}
        loop={false}
      />

      {/* Bouton de fermeture du loader */}
      {loading && (
        <button
          className="fixed top-4 right-4 text-black dark:text-white z-[120]"
          onClick={() => setLoading(false)}
        >
          <IconSquareRoundedX className="h-10 w-10" />
        </button>
      )}

      {/* En-tête avec titre et barre de recherche */}
      <div className="bg-white shadow-sm rounded-lg p-6">
        <div className="flex flex-col space-y-4">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-semibold text-gray-900">
              Historique des demandes
            </h2>
            <div className="flex items-center space-x-6 text-sm">
              <div className="flex items-center space-x-2">
                <div className="w-2 h-2 rounded-full bg-yellow-400"></div>
                <span>En attente: {statusCounts.en_attente}</span>
              </div>
              <div className="flex items-center space-x-2">
                <div className="w-2 h-2 rounded-full bg-blue-400"></div>
                <span>En cours: {statusCounts.en_cours}</span>
              </div>
              <div className="flex items-center space-x-2">
                <div className="w-2 h-2 rounded-full bg-green-400"></div>
                <span>Terminé: {statusCounts.terminé}</span>
              </div>
            </div>
          </div>

          {/* Barre de recherche avec compteur de résultats */}
          <div className="flex flex-col space-y-2">
            <div className="max-w-sm">
              <div className="scale-90 origin-left">
                <PlaceholdersAndVanishInput
                  placeholders={[
                    "Rechercher un type de document...",
                    "Rechercher par pays...",
                    "Rechercher par nom...",
                    "Ex: Passeport",
                    "Ex: Dupont"
                  ]}
                  onChange={handleSearchChange}
                  onSubmit={handleSearchSubmit}
                />
              </div>
            </div>
            {searchTerm && (
              <div className="text-sm text-blue-600 font-medium ml-2">
                {demandes.length} résultat{demandes.length !== 1 ? 's' : ''} trouvé{demandes.length !== 1 ? 's' : ''}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Filtres */}
      <div className="bg-white shadow-sm rounded-lg overflow-hidden">
        <div className="p-4 border-b border-gray-100">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-sm font-medium text-gray-700">Filtres avancés</h3>
            {Object.values(filters).some(Boolean) && (
              <button
                onClick={resetFilters}
                className="inline-flex items-center px-3 py-1.5 text-xs font-medium text-gray-600 bg-gray-50 hover:bg-gray-100 rounded-full transition-colors duration-200"
              >
                <svg className="w-3 h-3 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
                Réinitialiser les filtres
              </button>
            )}
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-6 gap-4">
            {/* Select Type de document */}
            <div className="space-y-1.5">
              <label className="block text-xs font-medium text-gray-500">
                Type de document
              </label>
              <div className="relative group">
                <select
                  value={filters.typeDocument}
                  onChange={(e) => setFilters(prev => ({ ...prev, typeDocument: e.target.value }))}
                  className="w-full pl-4 pr-10 py-2.5 text-sm bg-white border border-gray-200 rounded-lg 
                  focus:ring-2 focus:ring-blue-100 focus:border-blue-400 
                  hover:border-gray-300 appearance-none cursor-pointer transition-all duration-200
                  shadow-sm group-hover:shadow-md"
                >
                  <option value="" className="text-gray-500">Tous les types</option>
                  {getUniqueOptions('typeDocument').map((type: string) => (
                    <option key={type} value={type} className="py-2">{type}</option>
                  ))}
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none text-gray-400 group-hover:text-blue-500 transition-colors">
                  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 9L12 15L18 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </div>
              </div>
            </div>

            {/* Select Pays */}
            <div className="space-y-1.5">
              <label className="block text-xs font-medium text-gray-500">
                Pays
              </label>
              <div className="relative group">
                <select
                  value={filters.pays}
                  onChange={(e) => setFilters(prev => ({ ...prev, pays: e.target.value }))}
                  className="w-full pl-4 pr-10 py-2.5 text-sm bg-white border border-gray-200 rounded-lg 
                  focus:ring-2 focus:ring-blue-100 focus:border-blue-400 
                  hover:border-gray-300 appearance-none cursor-pointer transition-all duration-200
                  shadow-sm group-hover:shadow-md"
                >
                  <option value="" className="text-gray-500">Tous les pays</option>
                  {getUniqueOptions('pays').map((pays: string) => (
                    <option key={pays} value={pays} className="py-2">{pays}</option>
                  ))}
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none text-gray-400 group-hover:text-blue-500 transition-colors">
                  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 9L12 15L18 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </div>
              </div>
            </div>

            {/* Select Statut */}
            <div className="space-y-1.5">
              <label className="block text-xs font-medium text-gray-500">
                Statut
              </label>
              <div className="relative group">
                <select
                  value={filters.statut}
                  onChange={(e) => setFilters(prev => ({ ...prev, statut: e.target.value }))}
                  className="w-full pl-4 pr-10 py-2.5 text-sm bg-white border border-gray-200 rounded-lg 
                  focus:ring-2 focus:ring-blue-100 focus:border-blue-400 
                  hover:border-gray-300 appearance-none cursor-pointer transition-all duration-200
                  shadow-sm group-hover:shadow-md"
                >
                  <option value="" className="text-gray-500">Tous les statuts</option>
                  <option value="en_attente" className="text-yellow-600">En attente</option>
                  <option value="en_cours" className="text-blue-600">En cours</option>
                  <option value="terminé" className="text-green-600">Terminé</option>
                  <option value="rejeté" className="text-red-600">Rejeté</option>
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none text-gray-400 group-hover:text-blue-500 transition-colors">
                  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 9L12 15L18 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </div>
              </div>
            </div>

            {/* Select Résultat */}
            <div className="space-y-1.5">
              <label className="block text-xs font-medium text-gray-500">
                Résultat
              </label>
              <div className="relative group">
                <select
                  value={filters.resultat}
                  onChange={(e) => setFilters(prev => ({ ...prev, resultat: e.target.value }))}
                  className="w-full pl-4 pr-10 py-2.5 text-sm bg-white border border-gray-200 rounded-lg 
                  focus:ring-2 focus:ring-blue-100 focus:border-blue-400 
                  hover:border-gray-300 appearance-none cursor-pointer transition-all duration-200
                  shadow-sm group-hover:shadow-md"
                >
                  <option value="" className="text-gray-500">Tous les résultats</option>
                  <option value="OK" className="text-green-600">Document valide</option>
                  <option value="KO" className="text-red-600">Document non valide</option>
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none text-gray-400 group-hover:text-blue-500 transition-colors">
                  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 9L12 15L18 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </div>
              </div>
            </div>

            {/* Filtre par année */}
            <div className="space-y-1.5">
              <label className="block text-xs font-medium text-gray-500">
                Année
              </label>
              <div className="relative group">
                <select
                  value={filters.year}
                  onChange={(e) => setFilters(prev => ({ ...prev, year: e.target.value }))}
                  className="w-full pl-4 pr-10 py-2.5 text-sm bg-white border border-gray-200 rounded-lg 
                  focus:ring-2 focus:ring-blue-100 focus:border-blue-400 
                  hover:border-gray-300 appearance-none cursor-pointer transition-all duration-200
                  shadow-sm group-hover:shadow-md"
                >
                  <option value="">Toutes les années</option>
                  {getYearOptions().map(year => (
                    <option key={year} value={year}>{year}</option>
                  ))}
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none text-gray-400">
                  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
                    <path d="M6 9L12 15L18 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </div>
              </div>
            </div>

            {/* Filtre par mois */}
            <div className="space-y-1.5">
              <label className="block text-xs font-medium text-gray-500">
                Mois
              </label>
              <div className="relative group">
                <select
                  value={filters.month}
                  onChange={(e) => setFilters(prev => ({ ...prev, month: e.target.value }))}
                  className="w-full pl-4 pr-10 py-2.5 text-sm bg-white border border-gray-200 rounded-lg 
                  focus:ring-2 focus:ring-blue-100 focus:border-blue-400 
                  hover:border-gray-300 appearance-none cursor-pointer transition-all duration-200
                  shadow-sm group-hover:shadow-md"
                  disabled={!filters.year} // Désactiver si aucune année n'est sélectionnée
                >
                  <option value="">Tous les mois</option>
                  {monthOptions.map(month => (
                    <option key={month.value} value={month.value}>{month.label}</option>
                  ))}
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none text-gray-400">
                  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
                    <path d="M6 9L12 15L18 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </div>
              </div>
            </div>
          </div>

          {/* Filtres actifs */}
          {Object.values(filters).some(Boolean) && (
            <div className="mt-4 flex flex-wrap gap-2">
              {Object.entries(filters).map(([key, value]) => value && (
                <span
                  key={key}
                  className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-blue-50 text-blue-700 border border-blue-100"
                >
                  {key === 'typeDocument' && 'Type: '}
                  {key === 'pays' && 'Pays: '}
                  {key === 'statut' && 'Statut: '}
                  {key === 'resultat' && 'Résultat: '}
                  {key === 'year' && 'Année: '}
                  {key === 'month' && 'Mois: '}
                  {value}
                  <button
                    onClick={() => setFilters(prev => ({ ...prev, [key]: '' }))}
                    className="ml-1.5 hover:text-blue-800 focus:outline-none"
                  >
                    <svg className="w-3 h-3" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                    </svg>
                  </button>
                </span>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Tableau des demandes */}
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <div className="overflow-x-auto" style={{ minWidth: '1200px' }}>
          <table className="w-full table-fixed divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="w-[8%] px-3 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  Référence
                </th>
                <th className="w-[10%] px-3 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  Demandeur
                </th>
                <th className="w-[13%] px-3 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  Type de document
                </th>
                <th className="w-[8%] px-3 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  Pays
                </th>
                <th className="w-[10%] px-3 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  Date demande
                </th>
                <th className="w-[8%] px-3 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  Statut
                </th>
                <th className="w-[13%] px-3 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  Documents
                </th>
                <th className="w-[10%] px-3 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  Résultat
                </th>
                <th className="w-[10%] px-3 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  Retour RESOEXPERT
                </th>
                <th className="w-[10%] px-3 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  Certificat(s)
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {demandes.map((demande) => (
                <tr key={demande._id} className="hover:bg-gray-50 transition-colors">
                  <td className="px-3 py-3 text-center">
                    <span className="text-sm font-medium text-gray-900">
                      {demande.reference}
                    </span>
                  </td>
                  <td className="px-3 py-3 text-center">
                    <span className="text-sm font-semibold text-gray-900">
                      {demande.nom} {demande.prenom}
                    </span>
                  </td>
                  <td className="px-3 py-3 text-center">
                    <span className="text-sm text-gray-900">
                      {demande.typeDocument}
                    </span>
                  </td>
                  <td className="px-3 py-3 text-center">
                    <span className="text-sm text-gray-900">
                      {demande.pays}
                    </span>
                  </td>
                  <td className="px-3 py-3 text-center">
                    <span className="text-sm text-gray-500">
                      {formatDate(demande.dateDemande)}
                    </span>
                  </td>
                  <td className="px-3 py-3 text-center">
                    <button
                      onClick={() => {
                        setSelectedDemande(demande);
                        setShowLoader(true);
                      }}
                      className={`group relative px-3 py-1 inline-flex items-center justify-center text-sm font-semibold rounded-full transition-all duration-200 ${getStatusColor(demande.statut)}`}
                    >
                      <span>{demande.statut.replace('_', ' ')}</span>
                    </button>
                  </td>
                  <td className="px-3 py-3">
                    <div className="flex justify-center space-x-2">
                      {demande.images.map((image, index) => (
                        <motion.img
                          key={index}
                          src={getImageUrl(image)}
                          alt={`Document ${index + 1}`}
                          className="h-10 w-10 object-cover rounded-md cursor-pointer hover:opacity-75 transition-opacity"
                          onClick={() => handleImageClick(image)}
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                        />
                      ))}
                    </div>
                  </td>
                  <td className="px-3 py-3 text-center">
                    {demande.statut === 'terminé' ? (
                      <span className={`inline-flex items-center justify-center px-2 py-1 rounded-full text-xs font-medium ${
                        demande.resultatExpertise === 'OK' 
                          ? 'bg-green-100 text-green-800' 
                          : 'bg-red-100 text-red-800'
                      }`}>
                        {demande.resultatExpertise === 'OK' ? (
                          <>
                            <svg className="w-3 h-3 mr-1" fill="currentColor" viewBox="0 0 20 20">
                              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                            </svg>
                            Valide
                          </>
                        ) : (
                          <>
                            <svg className="w-3 h-3 mr-1" fill="currentColor" viewBox="0 0 20 20">
                              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                            </svg>
                            Non Valide
                          </>
                        )}
                      </span>
                    ) : (
                      <span className="text-xs text-gray-500 italic">
                        En attente
                      </span>
                    )}
                  </td>
                  <td className="px-3 py-3 text-center">
                    {demande.statut === 'terminé' ? (
                      <span className="text-sm text-green-600">
                        {formatDate(demande.dateEnvoi || demande.dateDemande)}
                      </span>
                    ) : demande.statut === 'rejeté' ? (
                      <span className="text-sm text-red-600">
                        Rejetée
                      </span>
                    ) : (
                      <span className="text-sm text-gray-500 italic">
                        En cours
                      </span>
                    )}
                  </td>
                  <td className="px-3 py-3 text-center">
                    {demande.statut === 'terminé' && demande.certificates && demande.certificates.length > 0 ? (
                      <button
                        onClick={() => handleDownloadAllCertificates(demande.certificates)}
                        className="inline-flex items-center justify-center p-2 text-blue-700 bg-blue-50 rounded-full hover:bg-blue-100 transition-colors"
                        title={`Télécharger ${demande.certificates.length > 1 ? 'les certificats' : 'le certificat'} (${demande.certificates.length})`}
                      >
                        <svg 
                          className="w-5 h-5" 
                          fill="none" 
                          stroke="currentColor" 
                          viewBox="0 0 24 24"
                        >
                          <path 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            strokeWidth={2} 
                            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                          />
                        </svg>
                      </button>
                    ) : (
                      <span className="text-sm text-gray-500 italic">
                        {demande.statut === 'terminé' ? 'Non disponible' : 'En attente'}
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <ImageViewer
        isOpen={imageViewer.isOpen}
        onClose={() => setImageViewer(prev => ({ ...prev, isOpen: false }))}
        imageUrl={imageViewer.imageUrl}
        fileName={imageViewer.fileName}
      />

      {/* Ajouter la pagination en bas du tableau */}
      {!loading && demandes.length > 0 && <Pagination />}
    </div>
  );
};

export default HistoriqueDemandes; 
