import React, { useState, useEffect } from 'react';
import Notification from '../../components/Notification';
import { FileUpload } from '../../components/ui/file-upload';
import { AnimatePresence } from 'framer-motion';
import { Info, X, AlertTriangle, Shield, Lock } from 'lucide-react';
import { motion } from 'framer-motion';
import API_CONFIG from '../../config/api';

interface DemandeFormData {
  nom: string;
  prenom: string;
  email: string;
  commentaires: string;
  typeDocument: string;
  pays: string;
  images: File[];
}

interface InputFieldProps {
  label: string;
  type?: string;
  value: string;
  onChange: (value: string) => void;
  required?: boolean;
  options?: string[];
  placeholder?: string;
  readOnly?: boolean;
}

const InputField = React.memo(({ 
  label, 
  type = "text", 
  value, 
  onChange, 
  required = true,
  options = [],
  placeholder = '',
  readOnly = false
}: InputFieldProps) => (
  <div className="relative">
    <label className="block text-sm font-medium text-gray-700 mb-1">
      {label}
    </label>
    {type === 'select' ? (
      <select
        required={required}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="w-full px-3 py-2 rounded-md bg-white border border-gray-300 focus:border-blue-500 focus:ring-1 focus:ring-blue-200 transition-colors duration-200 text-sm"
      >
        <option value="">Sélectionnez une option</option>
        {options.map((opt) => (
          <option key={opt} value={opt.toLowerCase()}>{opt}</option>
        ))}
      </select>
    ) : type === 'textarea' ? (
      <textarea
        required={required}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        rows={4}
        placeholder={placeholder}
        className="w-full px-3 py-2 rounded-md bg-white border border-gray-300 focus:border-blue-500 focus:ring-1 focus:ring-blue-200 transition-colors duration-200 resize-none text-sm"
      />
    ) : (
      <input
        type={type}
        required={required}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        className="w-full px-3 py-2 rounded-md bg-white border border-gray-300 focus:border-blue-500 focus:ring-1 focus:ring-blue-200 transition-colors duration-200 text-sm"
      />
    )}
  </div>
));

// Liste des types de documents
const DOCUMENT_TYPES = [
  'Carte d\'identité',
  'Passeport',
  'Titre de séjour',
  'Visa',
  'Permis de conduire'
];

// Liste des pays
const COUNTRIES = [
  'Afghanistan', 'Afrique du Sud', 'Albanie', 'Algérie', 'Allemagne', 'Andorre', 'Angola', 'Antigua-et-Barbuda', 'Arabie Saoudite', 'Argentine',
  'Arménie', 'Australie', 'Autriche', 'Azerbaïdjan', 'Bahamas', 'Bahreïn', 'Bangladesh', 'Barbade', 'Belgique', 'Belize',
  'Bénin', 'Bhoutan', 'Biélorussie', 'Birmanie', 'Bolivie', 'Bosnie-Herzégovine', 'Botswana', 'Brésil', 'Brunei', 'Bulgarie',
  'Burkina Faso', 'Burundi', 'Cambodge', 'Cameroun', 'Canada', 'Cap-Vert', 'Chili', 'Chine', 'Chypre', 'Colombie',
  'Comores', 'Congo', 'Corée du Nord', 'Corée du Sud', 'Costa Rica', 'Côte d\'Ivoire', 'Croatie', 'Cuba', 'Danemark', 'Djibouti',
  'Dominique', 'Égypte', 'Émirats arabes unis', 'Équateur', 'Érythrée', 'Espagne', 'Estonie', 'États-Unis', 'Éthiopie', 'Fidji',
  'Finlande', 'France', 'Gabon', 'Gambie', 'Géorgie', 'Ghana', 'Grèce', 'Grenade', 'Guatemala', 'Guinée',
  'Guinée-Bissau', 'Guinée équatoriale', 'Guyana', 'Haïti', 'Honduras', 'Hongrie', 'Îles Marshall', 'Îles Salomon', 'Inde', 'Indonésie',
  'Irak', 'Iran', 'Irlande', 'Islande', 'Israël', 'Italie', 'Jamaïque', 'Japon', 'Jordanie', 'Kazakhstan',
  'Kenya', 'Kirghizistan', 'Kiribati', 'Koweït', 'Laos', 'Lesotho', 'Lettonie', 'Liban', 'Libéria', 'Libye',
  'Liechtenstein', 'Lituanie', 'Luxembourg', 'Macédoine', 'Madagascar', 'Malaisie', 'Malawi', 'Maldives', 'Mali', 'Malte',
  'Maroc', 'Maurice', 'Mauritanie', 'Mexique', 'Micronésie', 'Moldavie', 'Monaco', 'Mongolie', 'Monténégro', 'Mozambique',
  'Namibie', 'Nauru', 'Népal', 'Nicaragua', 'Niger', 'Nigeria', 'Norvège', 'Nouvelle-Zélande', 'Oman', 'Ouganda',
  'Ouzbékistan', 'Pakistan', 'Palaos', 'Palestine', 'Panama', 'Papouasie-Nouvelle-Guinée', 'Paraguay', 'Pays-Bas', 'Pérou', 'Philippines',
  'Pologne', 'Portugal', 'Qatar', 'République centrafricaine', 'République démocratique du Congo', 'République dominicaine', 'République tchèque', 'Roumanie', 'Royaume-Uni', 'Russie',
  'Rwanda', 'Saint-Kitts-et-Nevis', 'Saint-Marin', 'Saint-Vincent-et-les-Grenadines', 'Sainte-Lucie', 'Salvador', 'Samoa', 'São Tomé-et-Principe', 'Sénégal', 'Serbie',
  'Seychelles', 'Sierra Leone', 'Singapour', 'Slovaquie', 'Slovénie', 'Somalie', 'Soudan', 'Soudan du Sud', 'Sri Lanka', 'Suède',
  'Suisse', 'Suriname', 'Swaziland', 'Syrie', 'Tadjikistan', 'Tanzanie', 'Tchad', 'Thaïlande', 'Timor oriental', 'Togo',
  'Tonga', 'Trinité-et-Tobago', 'Tunisie', 'Turkménistan', 'Turquie', 'Tuvalu', 'Ukraine', 'Uruguay', 'Vanuatu', 'Vatican',
  'Venezuela', 'Vietnam', 'Yémen', 'Zambie', 'Zimbabwe'
].sort(); // Tri alphabétique des pays

interface NouvelleDemandeFormProps {
  userEmail: string;
  userName: string;
  userFirstName: string;
}

interface TermsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
  isLoading: boolean;
}

const TermsModal: React.FC<TermsModalProps> = ({ isOpen, onClose, onAccept, isLoading }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full">
        <h3 className="text-lg font-semibold mb-4">Conditions d'utilisation</h3>
        <div className="prose prose-sm mb-6">
          <p>En soumettant cette demande, vous confirmez que :</p>
          <ul className="list-disc pl-5 space-y-2">
            <li>Les informations fournies sont exactes</li>
            <li>Les documents soumis sont authentiques</li>
            <li>Vous acceptez le traitement de vos données</li>
          </ul>
        </div>
        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm text-gray-600 hover:text-gray-800"
            disabled={isLoading}
          >
            Annuler
          </button>
          <button
            onClick={onAccept}
            disabled={isLoading}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50"
          >
            {isLoading ? 'Envoi en cours...' : 'Accepter et envoyer'}
          </button>
        </div>
      </div>
    </div>
  );
};

const NouvelleDemandeForm: React.FC<NouvelleDemandeFormProps> = React.memo(({ 
  userEmail, 
  userName, 
  userFirstName 
}) => {
  const [formData, setFormData] = useState<DemandeFormData>({
    nom: userName,
    prenom: userFirstName,
    email: userEmail,
    commentaires: '',
    typeDocument: '',
    pays: '',
    images: []
  });

  const [notification, setNotification] = useState({
    show: false,
    message: '',
    type: 'success' as 'success' | 'error'
  });

  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

  const [showTermsModal, setShowTermsModal] = useState(false);
  const [pendingSubmission, setPendingSubmission] = useState(false);

  const handleFileChange = (files: File[]) => {
    setFormData(prev => ({ ...prev, images: files }));
  };

  const showNotification = (message: string, type: 'success' | 'error') => {
    setNotification({
      show: true,
      message,
      type
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (formData.images.length === 0) {
      showNotification('Veuillez ajouter au moins une image à votre demande', 'error');
      return;
    }

    try {
      const token = localStorage.getItem('userToken');
      
      const formDataToSend = new FormData();
      Object.entries(formData).forEach(([key, value]) => {
        if (key !== 'images') {
          formDataToSend.append(key, value);
        }
      });
      
      formData.images.forEach((image) => {
        formDataToSend.append('images', image);
      });

      const response = await fetch(`${API_CONFIG.baseURL}/api/demandes`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formDataToSend
      });

      if (response.ok) {
        setIsSubmitSuccess(true);
      } else {
        throw new Error('Erreur lors de l\'envoi de la demande');
      }
    } catch (error) {
      showNotification('Erreur lors de l\'envoi de la demande', 'error');
    }
  };

  // Formater la date du jour
  const today = new Date().toLocaleDateString('fr-FR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });

  // Mettre à jour les champs quand les props changent
  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      email: userEmail,
      nom: userName,
      prenom: userFirstName
    }));
  }, [userEmail, userName, userFirstName]);

  const SuccessMessage = () => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-white rounded-xl shadow-lg p-8 text-center"
    >
      <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-6">
        <svg 
          className="w-8 h-8 text-green-500" 
          fill="none" 
          stroke="currentColor" 
          viewBox="0 0 24 24"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2} 
            d="M5 13l4 4L19 7" 
          />
        </svg>
      </div>
      <h2 className="text-2xl font-bold text-gray-800 mb-4">
        Demande envoyée avec succès !
      </h2>
      <p className="text-gray-600 mb-8">
        Votre demande a été enregistrée et sera traitée dans les plus brefs délais.
      </p>
      <button
        onClick={() => window.location.reload()}
        className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200"
      >
        Nouvelle demande
      </button>
    </motion.div>
  );

  return (
    <div className="max-w-4xl mx-auto relative">
      <AnimatePresence>
        {notification.show && (
          <Notification
            message={notification.message}
            type={notification.type}
            onClose={() => setNotification({ ...notification, show: false })}
          />
        )}
      </AnimatePresence>

      {isSubmitSuccess ? (
        <SuccessMessage />
      ) : (
        <div className="bg-white rounded-xl shadow-lg overflow-hidden">
          <div className="p-8">
            <h2 className="text-2xl font-bold text-gray-800 mb-6">Nouvelle Demande</h2>
            
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="relative">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Date de la demande
                  </label>
                  <input
                    type="text"
                    readOnly
                    value={today}
                    className="w-full px-3 py-2 rounded-md bg-gray-50 border border-gray-300 text-gray-500 cursor-not-allowed text-sm"
                  />
                </div>

                <div className="relative">
                  <label className="block text-sm font-medium text-gray-700">
                    Nom du demandeur
                  </label>
                  <input
                    type="text"
                    readOnly
                    value={formData.nom}
                    className="mt-1 block w-full px-3 py-2 rounded-md bg-gray-50 border border-gray-300 text-gray-500 cursor-not-allowed text-sm"
                  />
                </div>

                <div className="relative">
                  <label className="block text-sm font-medium text-gray-700">
                    Prénom du demandeur
                  </label>
                  <input
                    type="text"
                    readOnly
                    value={formData.prenom}
                    className="mt-1 block w-full px-3 py-2 rounded-md bg-gray-50 border border-gray-300 text-gray-500 cursor-not-allowed text-sm"
                  />
                </div>

                <div className="relative">
                  <label className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <input
                    type="email"
                    readOnly
                    value={formData.email}
                    className="mt-1 block w-full px-3 py-2 rounded-md bg-gray-50 border border-gray-300 text-gray-500 cursor-not-allowed text-sm"
                  />
                </div>

                <InputField
                  label="Type de document"
                  type="select"
                  value={formData.typeDocument}
                  onChange={(value) => setFormData(prev => ({ ...prev, typeDocument: value }))}
                  options={DOCUMENT_TYPES}
                />

                <InputField
                  label="Pays"
                  type="select"
                  value={formData.pays}
                  onChange={(value) => setFormData(prev => ({ ...prev, pays: value }))}
                  options={COUNTRIES}
                />
              </div>

              <div className="space-y-4">
                <InputField
                  label="Commentaires"
                  type="textarea"
                  value={formData.commentaires}
                  onChange={(value) => setFormData(prev => ({ ...prev, commentaires: value }))}
                  required={false}
                />

                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Documents justificatifs (2 maximum)
                  </label>
                  <FileUpload
                    maxFiles={2}
                    onChange={handleFileChange}
                    currentFiles={formData.images}
                  />
                </div>
              </div>

              <button
                type="submit"
                className="w-full py-3 px-6 text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-200 transition-colors duration-200 font-medium text-sm disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={formData.images.length === 0} // Désactiver le bouton si pas d'images
              >
                {formData.images.length === 0 ? 'Ajoutez au moins une image' : 'Envoyer la demande'}
              </button>
            </form>
          </div>
        </div>
      )}

      <TermsModal
        isOpen={showTermsModal}
        onClose={() => {
          setShowTermsModal(false);
          setPendingSubmission(false);
        }}
        onAccept={() => {}}
        isLoading={pendingSubmission}
      />

    </div>
  );
});

export default NouvelleDemandeForm;