import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Mail, MailOpen, ChevronRight, Send, Reply, X, Upload, Paperclip, Download } from 'lucide-react';
import API_CONFIG from '../../config/api';

interface Message {
  _id: string;
  content: string;
  sujet: string;
  lu: boolean;
  dateSend: string;
  fromAdmin: boolean;
  attachments: string[];
  responses: {
    _id: string;
    content: string;
    dateSend: string;
    fromAdmin: boolean;
    attachments: string[];
    lu: boolean;
  }[];
  demandeId?: {
    _id: string;
    typeDocument: string;
    pays: string;
    dateDemande: string;
  };
}

interface ConversationGroup {
  demandeId: string;
  typeDocument: string;
  pays: string;
  messages: Message[];
  lastMessage: Message;
  hasUnread: boolean;
}

interface MessageModalProps {
  conversation: ConversationGroup;
  onClose: () => void;
  onMarkAsRead: (messageId: string) => void;
  onReply: (messageId: string, content: string | FormData) => void;
  onMessageSent: () => void;
}

const MessageModal: React.FC<MessageModalProps> = ({ conversation, onClose, onMarkAsRead, onReply, onMessageSent }) => {
  const [replyContent, setReplyContent] = useState('');
  const [attachments, setAttachments] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (conversation.messages[0]) {
      onMarkAsRead(conversation.messages[0]._id);
    }
    
    // Scroll to bottom on new messages
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [conversation, onMarkAsRead]);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    if (files.length + attachments.length > 3) {
      alert('Maximum 3 pièces jointes autorisées');
      return;
    }
    setAttachments(prev => [...prev, ...files]);
  };

  const removeAttachment = (index: number) => {
    setAttachments(prev => prev.filter((_, i) => i !== index));
  };

  const handleSubmitReply = async (e: React.FormEvent) => {
    e.preventDefault();
    if (replyContent.trim()) {
      try {
        const formData = new FormData();
        formData.append('content', replyContent.trim());
        
        attachments.forEach(file => {
          formData.append('attachments', file);
        });

        await onReply(conversation.lastMessage._id, formData);
        setReplyContent('');
        setAttachments([]);
        onMessageSent();
        
        setTimeout(() => {
          messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 100);
      } catch (error) {
        console.error('Error in handleSubmitReply:', error);
      }
    }
  };

  const handleDownloadAttachment = async (filename: string) => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await fetch(`${API_CONFIG.baseURL}/api/messages/attachments/${filename}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (!response.ok) throw new Error('Erreur lors du téléchargement');

      // Récupérer le blob en tant que fichier binaire
      const blob = await response.blob();
      
      // Créer un lien temporaire pour le téléchargement
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename.split('-').pop() || filename; // Récupérer le nom original du fichier
      document.body.appendChild(a);
      a.click();
      
      // Nettoyer
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Erreur lors du téléchargement:', error);
      alert('Erreur lors du téléchargement du fichier');
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <motion.div
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.95, opacity: 0 }}
        className="bg-white rounded-lg w-full max-w-3xl mx-4 overflow-hidden"
        onClick={e => e.stopPropagation()}
      >
        {/* Header */}
        <div className="p-4 border-b border-gray-200 bg-gray-50">
          <div className="flex justify-between items-start">
            <div>
              <h3 className="text-lg font-semibold text-gray-900">
                {conversation.typeDocument} - {conversation.pays}
              </h3>
              <p className="text-sm text-gray-500 mt-1">
                Conversation démarrée le {new Date(conversation.messages[0]?.dateSend).toLocaleDateString('fr-FR', {
                  day: '2-digit',
                  month: 'long',
                  year: 'numeric'
                })}
              </p>
            </div>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600 transition-colors"
            >
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>

        {/* Messages Container */}
        <div className="h-[60vh] overflow-y-auto p-4 bg-gray-50">
          <div className="space-y-4">
            {/* Afficher le message principal */}
            <div className={`flex ${conversation.messages[0].fromAdmin ? 'justify-start' : 'justify-end'}`}>
              <div className={`max-w-[70%] ${
                conversation.messages[0].fromAdmin ? 'bg-white border border-gray-200' : 'bg-blue-600 text-white'
              } rounded-lg p-4 shadow-sm`}>
                <div className="flex items-center space-x-2 mb-2">
                  <span className={`text-xs ${conversation.messages[0].fromAdmin ? 'text-gray-500' : 'text-blue-100'}`}>
                    {conversation.messages[0].fromAdmin ? 'Admin' : 'Vous'}
                  </span>
                  <span className={`text-xs ${conversation.messages[0].fromAdmin ? 'text-gray-400' : 'text-blue-100'}`}>•</span>
                  <span className={`text-xs ${conversation.messages[0].fromAdmin ? 'text-gray-500' : 'text-blue-100'}`}>
                    {new Date(conversation.messages[0].dateSend).toLocaleString('fr-FR', {
                      day: '2-digit',
                      month: 'short',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit'
                    })}
                  </span>
                </div>
                <p className={`text-sm ${conversation.messages[0].fromAdmin ? 'text-gray-800' : 'text-white'}`}>
                  {conversation.messages[0].content}
                </p>
                
                {/* Affichage des pièces jointes */}
                {conversation.messages[0].attachments && conversation.messages[0].attachments.length > 0 && (
                  <div className="mt-3 space-y-2">
                    <div className="border-t border-gray-200 pt-2">
                      <p className={`text-xs ${conversation.messages[0].fromAdmin ? 'text-gray-500' : 'text-blue-100'}`}>
                        Pièces jointes:
                      </p>
                      {conversation.messages[0].attachments.map((attachment, index) => (
                        <div key={index} className="flex items-center justify-between mt-1">
                          <span className={`text-xs ${conversation.messages[0].fromAdmin ? 'text-gray-600' : 'text-blue-50'}`}>
                            {attachment}
                          </span>
                          <button
                            onClick={() => handleDownloadAttachment(attachment)}
                            className={`p-1 rounded-full hover:bg-opacity-10 hover:bg-black`}
                          >
                            <Download className="w-4 h-4" />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Afficher les réponses */}
            {conversation.messages[0].responses?.map((response) => (
              <div key={response._id} className={`flex ${response.fromAdmin ? 'justify-start' : 'justify-end'}`}>
                <div className={`max-w-[70%] ${
                  response.fromAdmin ? 'bg-white border border-gray-200' : 'bg-blue-600 text-white'
                } rounded-lg p-4 shadow-sm`}>
                  <div className="flex items-center space-x-2 mb-2">
                    <span className={`text-xs ${response.fromAdmin ? 'text-gray-500' : 'text-blue-100'}`}>
                      {response.fromAdmin ? 'Admin' : 'Vous'}
                    </span>
                    <span className={`text-xs ${response.fromAdmin ? 'text-gray-400' : 'text-blue-100'}`}>•</span>
                    <span className={`text-xs ${response.fromAdmin ? 'text-gray-500' : 'text-blue-100'}`}>
                      {new Date(response.dateSend).toLocaleString('fr-FR', {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'
                      })}
                    </span>
                  </div>
                  <p className={`text-sm ${response.fromAdmin ? 'text-gray-800' : 'text-white'}`}>
                    {response.content}
                  </p>
                  
                  {/* Affichage des pièces jointes des réponses */}
                  {response.attachments && response.attachments.length > 0 && (
                    <div className="mt-3 space-y-2">
                      <div className="border-t border-gray-200 pt-2">
                        <p className={`text-xs ${response.fromAdmin ? 'text-gray-500' : 'text-blue-100'}`}>
                          Pièces jointes:
                        </p>
                        {response.attachments.map((attachment, index) => (
                          <div key={index} className="flex items-center justify-between mt-1">
                            <span className={`text-xs ${response.fromAdmin ? 'text-gray-600' : 'text-blue-50'}`}>
                              {attachment}
                            </span>
                            <button
                              onClick={() => handleDownloadAttachment(attachment)}
                              className={`p-1 rounded-full hover:bg-opacity-10 hover:bg-black`}
                            >
                              <Download className="w-4 h-4" />
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
        </div>

        {/* Reply Form */}
        <div className="p-4 border-t border-gray-200 bg-white">
          <form onSubmit={handleSubmitReply} className="space-y-3">
            <div className="flex space-x-3">
              <input
                type="text"
                value={replyContent}
                onChange={(e) => setReplyContent(e.target.value)}
                placeholder="Votre réponse..."
                className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
              <button
                type="button"
                onClick={() => fileInputRef.current?.click()}
                className="px-3 py-2 text-gray-600 hover:text-blue-600 transition-colors"
                title="Ajouter une pièce jointe"
              >
                <Paperclip className="w-5 h-5" />
              </button>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileSelect}
                className="hidden"
                multiple
                accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
              />
              <button
                type="submit"
                disabled={!replyContent.trim()}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2"
              >
                <Send size={16} />
                <span>Envoyer</span>
              </button>
            </div>

            {/* Aperçu des pièces jointes */}
            {attachments.length > 0 && (
              <div className="flex flex-wrap gap-2 mt-2">
                {attachments.map((file, index) => (
                  <div
                    key={index}
                    className="flex items-center gap-2 bg-gray-100 px-3 py-1 rounded-full text-sm"
                  >
                    <Paperclip className="w-4 h-4 text-gray-500" />
                    <span className="truncate max-w-[150px]">{file.name}</span>
                    <button
                      onClick={() => removeAttachment(index)}
                      className="text-gray-500 hover:text-red-500"
                    >
                      <X className="w-4 h-4" />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </form>
        </div>
      </motion.div>
    </motion.div>
  );
};

const Messages: React.FC = () => {
  const [conversations, setConversations] = useState<ConversationGroup[]>([]);
  const [selectedConversation, setSelectedConversation] = useState<ConversationGroup | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchMessages();
  }, []);

  const fetchMessages = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('userToken');
      const response = await fetch(`${API_CONFIG.baseURL}/api/messages`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des messages');
      }

      const messages: Message[] = await response.json();

      // Grouper les messages par demande
      const groupedMessages = messages.reduce((acc: { [key: string]: ConversationGroup }, message) => {
        if (!message.demandeId) return acc;

        const demandeId = message.demandeId._id;
        if (!acc[demandeId]) {
          acc[demandeId] = {
            demandeId,
            typeDocument: message.demandeId.typeDocument,
            pays: message.demandeId.pays,
            messages: [],
            lastMessage: message,
            hasUnread: false
          };
        }

        acc[demandeId].messages.push(message);
        if (new Date(message.dateSend) > new Date(acc[demandeId].lastMessage.dateSend)) {
          acc[demandeId].lastMessage = message;
        }
        if (!message.lu) {
          acc[demandeId].hasUnread = true;
        }

        return acc;
      }, {});

      const sortedConversations = Object.values(groupedMessages).sort((a, b) => 
        new Date(b.lastMessage.dateSend).getTime() - new Date(a.lastMessage.dateSend).getTime()
      );

      setConversations(sortedConversations);
      setLoading(false);
      return sortedConversations;
    } catch (error) {
      console.error('Erreur lors de la récupération des messages:', error);
      setLoading(false);
      return [];
    }
  };

  const updateUnreadCount = async () => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await fetch(`${API_CONFIG.baseURL}/api/messages/unread-count`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      
      // Émettre un événement personnalisé pour mettre à jour le compteur dans Dashboard
      window.dispatchEvent(new CustomEvent('unreadMessagesUpdate', { 
        detail: { count: data.count }
      }));
    } catch (error) {
      console.error('Erreur lors de la mise à jour du compteur:', error);
    }
  };

  const handleMarkAsRead = async (messageId: string) => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await fetch(`${API_CONFIG.baseURL}/api/messages/${messageId}/read`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (!response.ok) {
        throw new Error('Erreur lors du marquage du message');
      }
  
      const data = await response.json();
      
      setConversations(conversations.map(conversation => {
        if (conversation.messages[0]._id === messageId) {
          return {
            ...conversation,
            hasUnread: false,
            messages: conversation.messages.map(msg => ({
              ...msg,
              lu: true,
              responses: msg.responses?.map(resp => ({
                ...resp,
                lu: true
              }))
            }))
          };
        }
        return conversation;
      }));
  
      await updateUnreadCount();
    } catch (error) {
      console.error('Erreur lors du marquage du message:', error);
    }
  };
  
  const handleReply = async (messageId: string, content: string | FormData) => {
    try {
      let formDataToSend: FormData;
      
      if (content instanceof FormData) {
        formDataToSend = content;
      } else {
        // Si c'est une chaîne de caractères, créer un nouveau FormData
        formDataToSend = new FormData();
        formDataToSend.append('content', content);
      }

      const token = localStorage.getItem('userToken');
      const response = await fetch(`${API_CONFIG.baseURL}/api/messages/${messageId}/reply`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formDataToSend
      });

      if (!response.ok) {
        throw new Error('Erreur lors de l\'envoi de la réponse');
      }
    } catch (error) {
      console.error('Erreur:', error);
      // Gérer l'erreur (afficher une notification, etc.)
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      <div className="px-4 py-5 sm:px-6 border-b border-gray-200">
        <h3 className="text-lg font-medium text-gray-900">Messages</h3>
      </div>

      {loading ? (
        <div className="p-8 text-center text-gray-500">Chargement...</div>
      ) : conversations.length === 0 ? (
        <div className="p-8 text-center text-gray-500">Aucune conversation</div>
      ) : (
        <div className="divide-y divide-gray-200">
          {conversations.map((conversation) => (
            <motion.div
              key={conversation.demandeId}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className={`p-4 hover:bg-gray-50 cursor-pointer transition-colors ${
                conversation.hasUnread ? 'bg-blue-50' : ''
              }`}
              onClick={() => setSelectedConversation(conversation)}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  {conversation.hasUnread ? (
                    <Mail className="w-5 h-5 text-blue-500" />
                  ) : (
                    <MailOpen className="w-5 h-5 text-gray-400" />
                  )}
                  <div>
                    <h4 className="text-sm font-medium text-gray-900">
                      Demande de {conversation.typeDocument} - {conversation.pays}
                    </h4>
                    <p className="text-sm text-gray-500 line-clamp-1">
                      {conversation.lastMessage.content}
                    </p>
                  </div>
                </div>
                <div className="flex items-center">
                  <span className="text-sm text-gray-500">
                    {new Date(conversation.lastMessage.dateSend).toLocaleDateString('fr-FR')}
                  </span>
                  <ChevronRight className="w-5 h-5 text-gray-400 ml-2" />
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      )}

      {/* Modal de conversation */}
      {selectedConversation && (
        <MessageModal
          conversation={selectedConversation}
          onClose={() => setSelectedConversation(null)}
          onMarkAsRead={handleMarkAsRead}
          onReply={handleReply}
          onMessageSent={fetchMessages}
        />
      )}
    </div>
  );
};

export default Messages; 