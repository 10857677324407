import React, { useState, useEffect } from 'react';
import {
  BarChart2,
  TrendingUp,
  Users,
  FileText,
  Calendar,
  Globe,
  PieChart,
  CheckCircle,
  Clock,
  Activity,
  XCircle,
  Percent,
  ChevronLeft,
  ChevronRight,
  AlertCircle,
  X
} from 'lucide-react';
import {
  BarChart,
  LineChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Area,
  Pie,
  Cell
} from 'recharts';
import API_CONFIG from '../../config/api';

interface StatisticsData {
  demandesParClient: {
    client: string;
    total: number;
    enCours: number;
    terminees: number;
  }[];
  demandesParPays: {
    pays: string;
    count: number;
    percentage: number;
  }[];
  demandesParType: {
    type: string;
    count: number;
    percentage: number;
  }[];
  tempsTraitement: {
    moyen: number;
    min: number;
    max: number;
  };
  tauxValidation: number;
  tauxRejet: number;
  totalDemandes?: number;
  clientStats: ClientStat[];
}

interface PaysStats {
  pays: string;
  total: number;
  percentage: number;
  tauxValidation: number;
  validees: number;
  rejetees: number;
  enCours: number;
}

interface ExtendedStatisticsData extends Omit<StatisticsData, 'clientStats'> {
  clientStats: ClientStat[];
  evolutionMensuelle: {
    mois: string;
    total: number;
    validees: number;
    rejetees: number;
  }[];
  documentStats: {
    type: string;
    total: number;
    percentage: number;
    tauxValidation: number;
    validees: number;
    rejetees: number;
    enCours: number;
  }[];
  paysStats: PaysStats[];
  globalStats: {
    totalDemandes: number;
    tauxValidationGlobal: number;
    tempsMoyenGlobal: number;
    demandesEnCours: number;
  };
  documentPaysStats: {
    type: string;
    total: number;
    validees: number;
    rejetees: number;
    enCours: number;
    tauxFraude: number;
  }[];
}

// Définir l'interface StatCardProps en premier
interface StatCardProps {
  title: string;
  value: number | string;
  icon: React.ReactNode;
  trend: number;
  trendLabel: string;
}

// Définir le composant StatCard avant StatisticsManagement
const StatCard: React.FC<StatCardProps> = ({ title, value, icon, trend, trendLabel }) => {
  const formatTrend = (trend: number) => {
    if (isNaN(trend) || trend === undefined) return '0';
    return `${trend > 0 ? '+' : ''}${trend}`;
  };

  const formatValue = (value: number | string) => {
    if (typeof value === 'number') {
      return isNaN(value) ? '0' : value.toString();
    }
    return value;
  };

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6">
      <div className="flex items-center justify-between">
        <div className="bg-blue-50 rounded-lg p-2">
          {icon}
        </div>
        <span className={`text-sm font-medium ${trend >= 0 ? 'text-green-600' : 'text-red-600'}`}>
          {formatTrend(trend)}% {trendLabel}
        </span>
      </div>
      <div className="mt-4">
        <h3 className="text-sm font-medium text-gray-500">{title}</h3>
        <p className="text-2xl font-semibold text-gray-900 mt-1">{formatValue(value)}</p>
      </div>
    </div>
  );
};

type TabType = 'overview' | 'clients' | 'documents' | 'geography' | 'trends';

interface Demandeur {
  nom: string;
  prenom: string;
  email: string;
  statut: string;
  dateDemande: string;
  resultatExpertise?: 'OK' | 'KO' | null;
  typeDocument: string;
  pays: string; // Ajout de la propriété pays
}

interface ClientStat {
  client: string;
  total: number;
  enCours: number;
  terminees: number;
  tauxValidation: number;
  tempsMoyenTraitement: number;
  demandeurs: Demandeur[];
}

// Ajouter une interface pour les statistiques mensuelles
interface MonthlyStats {
  mois: string;
  total: number;
  ok: number;
  ko: number;
  client: string;
}

// Ajouter une interface pour les filtres de la table
interface TableFilters {
  client: string;
  month: number | null;
  year: number;
  statut: string;
  volume: string;
  tauxValidation: string;
}

// Définir les couleurs pour les graphiques
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

// Définir l'interface pour les props du label du PieChart
interface PieLabelProps {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  value: number;
  index: number;
}

// Ajouter l'interface pour le client sélectionné
interface SelectedClient extends ClientStat {
  client: string;
  total: number;
  enCours: number;
  terminees: number;
  tauxValidation: number;
  tempsMoyenTraitement: number;
  demandeurs: Demandeur[];
}

const StatisticsManagement: React.FC = () => {
  const [stats, setStats] = useState<ExtendedStatisticsData>(
    {
      demandesParClient: [],
      demandesParPays: [],
      demandesParType: [],
      tempsTraitement: {
        moyen: 0,
        min: 0,
        max: 0
      },
      tauxValidation: 0,
      tauxRejet: 0,
      clientStats: [],
      documentStats: [],
      paysStats: [],
      evolutionMensuelle: [],
      globalStats: {
        totalDemandes: 0,
        tauxValidationGlobal: 0,
        tempsMoyenGlobal: 0,
        demandesEnCours: 0
      },
      documentPaysStats: []
    }
  );
  const [loading, setLoading] = useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState<'month' | 'year' | 'all'>('month');
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState<number>(new Date().getMonth() + 1);
  const [activeTab, setActiveTab] = useState<TabType>('overview');
  const [tableFilters, setTableFilters] = useState<TableFilters>({
    client: '',
    month: null,
    year: new Date().getFullYear(),
    statut: '',
    volume: '',
    tauxValidation: ''
  });
  const [monthlyStats, setMonthlyStats] = useState<MonthlyStats[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Nombre de pays par page
  const [searchCountry, setSearchCountry] = useState('');
  const [selectedClient, setSelectedClient] = useState<SelectedClient | null>(null);

  const fetchStatistics = async () => {
    try {
      const token = localStorage.getItem('adminToken');
      const queryParams = new URLSearchParams({
        period: selectedPeriod,
        year: selectedYear.toString(),
        month: selectedMonth.toString()
      });

      const response = await fetch(
        `${API_CONFIG.baseURL}/api/demandes/statistics?${queryParams}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des statistiques');
      }

      const data = await response.json();
      setStats({ ...stats, ...data });
    } catch (error) {
      console.error('Erreur:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStatistics();
  }, [selectedPeriod, selectedYear, selectedMonth]);

  // Calculer les totaux à partir des données disponibles
  const calculateTotals = (stats: ExtendedStatisticsData | null) => {
    if (!stats) return {
      totalDemandes: 0,
      demandesEnCours: 0,
      demandesTerminees: 0,
      tauxValidation: 0
    };

    const totalDemandes = stats.clientStats.reduce((acc, client) => acc + client.total, 0);
    const demandesEnCours = stats.clientStats.reduce((acc, client) => acc + client.enCours, 0);
    const demandesTerminees = stats.clientStats.reduce((acc, client) => acc + client.terminees, 0);

    return {
      totalDemandes,
      demandesEnCours,
      demandesTerminees,
      tauxValidation: stats.tauxValidation
    };
  };

  // Dans le rendu, utiliser calculateTotals
  const totals = calculateTotals(stats);

  const tabs = [
    { id: 'overview', label: 'Vue d\'ensemble', icon: <BarChart2 className="w-4 h-4" /> },
    { id: 'clients', label: 'Par Client', icon: <Users className="w-4 h-4" /> },
    { id: 'documents', label: 'Par Document', icon: <FileText className="w-4 h-4" /> },
    { id: 'geography', label: 'Par Pays', icon: <Globe className="w-4 h-4" /> },
    { id: 'trends', label: 'Tendances', icon: <TrendingUp className="w-4 h-4" /> }
  ];

  // Ajouter un composant pour les filtres de la table
  const TableFiltersSection = () => (
    <div className="bg-white p-4 rounded-lg shadow mb-6">
      <div className="grid grid-cols-3 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Client</label>
          <select
            value={tableFilters.client}
            onChange={(e) => setTableFilters(prev => ({ ...prev, client: e.target.value }))}
            className="w-full rounded-md border-gray-300 shadow-sm"
          >
            <option value="">Tous les clients</option>
            {stats.clientStats.map(client => (
              <option key={client.client} value={client.client}>{client.client}</option>
            ))}
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Mois</label>
          <select
            value={tableFilters.month || ''}
            onChange={(e) => setTableFilters(prev => ({ 
              ...prev, 
              month: e.target.value ? parseInt(e.target.value) : null 
            }))}
            className="w-full rounded-md border-gray-300 shadow-sm"
          >
            <option value="">Tous les mois</option>
            {Array.from({ length: 12 }, (_, i) => (
              <option key={i + 1} value={i + 1}>
                {new Date(2024, i).toLocaleString('fr-FR', { month: 'long' })}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Année</label>
          <select
            value={tableFilters.year}
            onChange={(e) => setTableFilters(prev => ({ 
              ...prev, 
              year: parseInt(e.target.value) 
            }))}
            className="w-full rounded-md border-gray-300 shadow-sm"
          >
            {Array.from({ length: 5 }, (_, i) => {
              const year = new Date().getFullYear() - i;
              return <option key={year} value={year}>{year}</option>;
            })}
          </select>
        </div>
      </div>
    </div>
  );

  // Ajouter un composant pour les graphiques de synthèse
  const SynthesisCharts = () => (
    <div className="grid grid-cols-2 gap-6 mb-6">
      {/* Graphique d'évolution mensuelle */}
      <div className="bg-white p-4 rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-4">Évolution mensuelle des expertises</h3>
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={monthlyStats}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="mois" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="total" stroke="#8884d8" name="Total" />
              <Line type="monotone" dataKey="ok" stroke="#82ca9d" name="OK" />
              <Line type="monotone" dataKey="ko" stroke="#ff7f7f" name="KO" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Graphique de répartition par client */}
      <div className="bg-white p-4 rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-4">Répartition par client</h3>
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={stats.clientStats}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="client" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="total" fill="#8884d8" name="Total" />
              <Bar dataKey="terminees" fill="#82ca9d" name="Terminées" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );

  // Ajouter un composant pour les statistiques globales
  const GlobalStats = () => {
    const totalExpertises = stats.clientStats.reduce((acc, client) => acc + client.total, 0);
    const totalOK = stats.clientStats.reduce((acc, client) => 
      acc + (client.terminees * (client.tauxValidation / 100)), 0);
    const totalKO = stats.clientStats.reduce((acc, client) => 
      acc + (client.terminees * ((100 - client.tauxValidation) / 100)), 0);

    return (
      <div className="grid grid-cols-4 gap-4 mb-6">
        <StatCard
          title="Total Expertises"
          value={totalExpertises}
          icon={<FileText className="w-5 h-5 text-blue-600" />}
          trend={0}
          trendLabel="total"
        />
        <StatCard
          title="Expertises OK"
          value={Math.round(totalOK)}
          icon={<CheckCircle className="w-5 h-5 text-green-600" />}
          trend={0}
          trendLabel="validées"
        />
        <StatCard
          title="Expertises KO"
          value={Math.round(totalKO)}
          icon={<XCircle className="w-5 h-5 text-red-600" />}
          trend={0}
          trendLabel="rejetées"
        />
        <StatCard
          title="Taux de Validation"
          value={`${((totalOK / (totalOK + totalKO)) * 100).toFixed(1)}%`}
          icon={<Percent className="w-5 h-5 text-indigo-600" />}
          trend={0}
          trendLabel="global"
        />
      </div>
    );
  };

  // Fonction utilitaire pour calculer la tendance
  const calculateTrend = (value: number, previousValue: number = 0): number => {
    if (previousValue === 0) return 0;
    return ((value - previousValue) / previousValue) * 100;
  };

  // Fonction pour gérer la pagination des pays
  const paginatedPaysStats = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return stats.paysStats
      .sort((a, b) => (b.rejetees / b.total) - (a.rejetees / a.total))
      .slice(startIndex, startIndex + itemsPerPage);
  };

  // Calculer le nombre total de pages
  const totalPages = Math.ceil((stats.paysStats?.length || 0) / itemsPerPage);

  // Composant pour la pagination
  const Pagination = () => (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className={`relative inline-flex items-center rounded-md px-4 py-2 text-sm font-medium
            ${currentPage === 1
              ? 'bg-gray-100 text-gray-400'
              : 'bg-white text-gray-700 hover:bg-gray-50'}`}
        >
          Précédent
        </button>
        <button
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
          className={`relative ml-3 inline-flex items-center rounded-md px-4 py-2 text-sm font-medium
            ${currentPage === totalPages
              ? 'bg-gray-100 text-gray-400'
              : 'bg-white text-gray-700 hover:bg-gray-50'}`}
        >
          Suivant
        </button>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Affichage de{' '}
            <span className="font-medium">
              {Math.min((currentPage - 1) * itemsPerPage + 1, stats.paysStats.length)}
            </span>
            {' '}à{' '}
            <span className="font-medium">
              {Math.min(currentPage * itemsPerPage, stats.paysStats.length)}
            </span>
            {' '}sur{' '}
            <span className="font-medium">{stats.paysStats.length}</span> pays
          </p>
        </div>
        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <button
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className={`relative inline-flex items-center rounded-l-md px-2 py-2
                ${currentPage === 1
                  ? 'bg-gray-100 text-gray-400'
                  : 'bg-white text-gray-500 hover:bg-gray-50'}`}
            >
              <span className="sr-only">Précédent</span>
              <ChevronLeft className="h-5 w-5" />
            </button>
            {/* Pages numbers */}
            {Array.from({ length: totalPages }, (_, i) => i + 1)
              .filter(page => 
                page === 1 || 
                page === totalPages || 
                (page >= currentPage - 1 && page <= currentPage + 1)
              )
              .map((page, index, array) => {
                if (index > 0 && array[index - 1] !== page - 1) {
                  return [
                    <span key={`ellipsis-${page}`} className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700">
                      ...
                    </span>,
                    <button
                      key={page}
                      onClick={() => setCurrentPage(page)}
                      className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold
                        ${currentPage === page
                          ? 'z-10 bg-blue-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                          : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'}`}
                    >
                      {page}
                    </button>
                  ];
                }
                return (
                  <button
                    key={page}
                    onClick={() => setCurrentPage(page)}
                    className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold
                      ${currentPage === page
                        ? 'z-10 bg-blue-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                        : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'}`}
                  >
                    {page}
                  </button>
                );
              })}
            <button
              onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
              className={`relative inline-flex items-center rounded-r-md px-2 py-2
                ${currentPage === totalPages
                  ? 'bg-gray-100 text-gray-400'
                  : 'bg-white text-gray-500 hover:bg-gray-50'}`}
            >
              <span className="sr-only">Suivant</span>
              <ChevronRight className="h-5 w-5" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );

  // Fonction pour filtrer les pays
  const filteredPaysStats = () => {
    const filtered = stats.paysStats
      .filter(pays => 
        pays.pays.toLowerCase().includes(searchCountry.toLowerCase())
      )
      .sort((a, b) => (b.rejetees / b.total) - (a.rejetees / a.total));

    const startIndex = (currentPage - 1) * itemsPerPage;
    return filtered.slice(startIndex, startIndex + itemsPerPage);
  };

  // Calculer le nombre total de pages avec le filtre
  const totalFilteredPages = Math.ceil(
    stats.paysStats.filter(pays => 
      pays.pays.toLowerCase().includes(searchCountry.toLowerCase())
    ).length / itemsPerPage
  );

  // Fonction pour paginer les clients
  const paginatedClients = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const filteredClients = stats.clientStats.filter(client => {
      if (tableFilters.client && client.client !== tableFilters.client) return false;
      if (tableFilters.volume) {
        const total = client.total;
        switch (tableFilters.volume) {
          case 'high': return total > 100;
          case 'medium': return total >= 20 && total <= 100;
          case 'low': return total < 20;
          default: return true;
        }
      }
      if (tableFilters.tauxValidation) {
        const taux = client.tauxValidation;
        switch (tableFilters.tauxValidation) {
          case 'high': return taux > 90;
          case 'medium': return taux >= 70 && taux <= 90;
          case 'low': return taux < 70;
          default: return true;
        }
      }
      return true;
    });
    return filteredClients.slice(startIndex, startIndex + itemsPerPage);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Filtres de période */}
      <div className="bg-white rounded-xl shadow-sm p-4 border border-gray-200">
        <div className="flex items-center justify-between">
          <h2 className="text-lg font-semibold text-gray-900">Période d'analyse</h2>
          <div className="flex space-x-4">
            <select
              value={selectedPeriod}
              onChange={(e) => setSelectedPeriod(e.target.value as 'month' | 'year' | 'all')}
              className="rounded-lg border-gray-300 text-sm"
            >
              <option value="month">Ce mois</option>
              <option value="year">Cette année</option>
              <option value="all">Tout</option>
            </select>
            {selectedPeriod === 'month' && (
              <>
                <select
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(parseInt(e.target.value))}
                  className="rounded-lg border-gray-300 text-sm"
                >
                  {Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i).map(year => (
                    <option key={year} value={year}>{year}</option>
                  ))}
                </select>
                <select
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
                  className="rounded-lg border-gray-300 text-sm"
                >
                  {Array.from({ length: 12 }, (_, i) => i + 1).map(month => (
                    <option key={month} value={month}>
                      {new Date(2024, month - 1).toLocaleString('fr-FR', { month: 'long' })}
                    </option>
                  ))}
                </select>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Tabs de navigation */}
      <div className="bg-white rounded-xl shadow-sm border border-gray-200">
        <div className="border-b border-gray-200">
          <nav className="flex space-x-4 px-4" aria-label="Tabs">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id as TabType)}
                className={`
                  flex items-center space-x-2 py-4 px-4 border-b-2 font-medium text-sm
                  ${activeTab === tab.id
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                  }
                `}
              >
                {tab.icon}
                <span>{tab.label}</span>
              </button>
            ))}
          </nav>
        </div>

        <div className="p-6">
          {/* Vue d'ensemble */}
          {activeTab === 'overview' && (
            <div className="space-y-6">
              {/* Statistiques principales */}
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                {/* Total Expertises */}
                {(() => {
                  const totalExpertises = stats.clientStats.reduce((acc, client) => acc + client.total, 0);
                  const pourcentageTotal = 100; // Toujours 100% car c'est le total
                  return (
                    <StatCard
                      title="Total Expertises"
                      value={totalExpertises}
                      icon={<FileText className="w-5 h-5 text-blue-600" />}
                      trend={pourcentageTotal}
                      trendLabel="global"
                    />
                  );
                })()}

                {/* Expertises OK */}
                {(() => {
                  const expertisesOK = stats.clientStats.reduce((acc, client) => 
                    acc + client.demandeurs.filter(d => d.resultatExpertise === 'OK').length, 0);
                  const totalExpertisesTerminees = stats.clientStats.reduce((acc, client) => 
                    acc + client.demandeurs.filter(d => d.statut === 'terminé').length, 0);
                  const pourcentageOK = totalExpertisesTerminees > 0 
                    ? (expertisesOK / totalExpertisesTerminees) * 100 
                    : 0;
                  return (
                    <StatCard
                      title="Expertises OK"
                      value={expertisesOK}
                      icon={<CheckCircle className="w-5 h-5 text-green-600" />}
                      trend={pourcentageOK}
                      trendLabel="validées"
                    />
                  );
                })()}

                {/* Expertises KO */}
                {(() => {
                  const expertisesKO = stats.clientStats.reduce((acc, client) => 
                    acc + client.demandeurs.filter(d => d.resultatExpertise === 'KO').length, 0);
                  const totalExpertisesTerminees = stats.clientStats.reduce((acc, client) => 
                    acc + client.demandeurs.filter(d => d.statut === 'terminé').length, 0);
                  const pourcentageKO = totalExpertisesTerminees > 0 
                    ? (expertisesKO / totalExpertisesTerminees) * 100 
                    : 0;
                  return (
                    <StatCard
                      title="Expertises KO"
                      value={expertisesKO}
                      icon={<XCircle className="w-5 h-5 text-red-600" />}
                      trend={pourcentageKO}
                      trendLabel="rejetées"
                    />
                  );
                })()}

                {/* En Cours */}
                {(() => {
                  const enCours = stats.clientStats.reduce((acc, client) => 
                    acc + client.demandeurs.filter(d => d.statut === 'en_cours').length, 0);
                  const totalExpertises = stats.clientStats.reduce((acc, client) => acc + client.total, 0);
                  const pourcentageEnCours = totalExpertises > 0 
                    ? (enCours / totalExpertises) * 100 
                    : 0;
                  return (
                    <StatCard
                      title="En Cours"
                      value={enCours}
                      icon={<Activity className="w-5 h-5 text-yellow-600" />}
                      trend={pourcentageEnCours}
                      trendLabel="actuel"
                    />
                  );
                })()}
              </div>

              {/* Statistiques détaillées */}
              <div className="grid grid-cols-2 gap-6">
                {/* Performance globale */}
                <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-200">
                  <h3 className="text-lg font-semibold mb-4">Performance Globale</h3>
                  <div className="space-y-4">
                    <div className="flex justify-between items-center">
                      <span className="text-sm text-gray-600">Taux de validation</span>
                      <span className="text-sm font-medium text-green-600">
                        {((stats.clientStats.reduce((acc, client) => 
                          acc + client.demandeurs.filter(d => d.resultatExpertise === 'OK').length, 0
                        ) / totals.totalDemandes) * 100).toFixed(1)}%
                      </span>
                    </div>
                    <div className="flex justify-between items-center">
                      <span className="text-sm text-gray-600">Taux de rejet</span>
                      <span className="text-sm font-medium text-red-600">
                        {((stats.clientStats.reduce((acc, client) => 
                          acc + client.demandeurs.filter(d => d.resultatExpertise === 'KO').length, 0
                        ) / totals.totalDemandes) * 100).toFixed(1)}%
                      </span>
                    </div>
                    <div className="flex justify-between items-center">
                      <span className="text-sm text-gray-600">Temps moyen de traitement</span>
                      <span className="text-sm font-medium text-blue-600">
                        {stats.tempsTraitement.moyen.toFixed(1)}h
                      </span>
                    </div>
                  </div>
                </div>

                {/* Distribution par type de document */}
                <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-200">
                  <h3 className="text-lg font-semibold mb-4">Types de Documents</h3>
                  <div className="space-y-3">
                    {stats.demandesParType.map((type, index) => (
                      <div key={index} className="flex justify-between items-center">
                        <div>
                          <span className="text-sm font-medium text-gray-900">{type.type}</span>
                          <div className="text-xs text-gray-500">{type.count} demandes</div>
                        </div>
                        <div className="text-sm font-medium text-blue-600">
                          {type.percentage.toFixed(1)}%
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* Graphique d'évolution */}
              <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-200">
                <h3 className="text-lg font-semibold mb-6 flex items-center gap-2">
                  <TrendingUp className="w-5 h-5 text-blue-600" />
                  Évolution des Expertises
                </h3>
                <div className="h-[400px]">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                      data={stats.evolutionMensuelle}
                      margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                      <XAxis
                        dataKey="mois"
                        tick={{ fill: '#6B7280', fontSize: 12 }}
                        tickLine={{ stroke: '#E5E7EB' }}
                        axisLine={{ stroke: '#E5E7EB' }}
                      />
                      <YAxis
                        tick={{ fill: '#6B7280', fontSize: 12 }}
                        tickLine={{ stroke: '#E5E7EB' }}
                        axisLine={{ stroke: '#E5E7EB' }}
                      />
                      <Tooltip
                        contentStyle={{
                          backgroundColor: 'white',
                          border: '1px solid #E5E7EB',
                          borderRadius: '8px',
                          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                        }}
                        labelStyle={{ color: '#374151', fontWeight: 600 }}
                        itemStyle={{ color: '#6B7280' }}
                      />
                      <Legend
                        verticalAlign="top"
                        height={36}
                        iconType="circle"
                        iconSize={10}
                        wrapperStyle={{
                          paddingBottom: '20px'
                        }}
                      />
                      <Line
                        type="monotone"
                        dataKey="total"
                        name="Total Expertises"
                        stroke="#3B82F6"
                        strokeWidth={3}
                        dot={{
                          stroke: '#3B82F6',
                          strokeWidth: 2,
                          r: 4,
                          fill: 'white'
                        }}
                        activeDot={{
                          stroke: '#3B82F6',
                          strokeWidth: 2,
                          r: 6,
                          fill: 'white'
                        }}
                      />
                      <Line
                        type="monotone"
                        dataKey="validees"
                        name="Expertises Validées"
                        stroke="#10B981"
                        strokeWidth={3}
                        dot={{
                          stroke: '#10B981',
                          strokeWidth: 2,
                          r: 4,
                          fill: 'white'
                        }}
                        activeDot={{
                          stroke: '#10B981',
                          strokeWidth: 2,
                          r: 6,
                          fill: 'white'
                        }}
                      />
                      <Line
                        type="monotone"
                        dataKey="rejetees"
                        name="Expertises Rejetées"
                        stroke="#EF4444"
                        strokeWidth={3}
                        dot={{
                          stroke: '#EF4444',
                          strokeWidth: 2,
                          r: 4,
                          fill: 'white'
                        }}
                        activeDot={{
                          stroke: '#EF4444',
                          strokeWidth: 2,
                          r: 6,
                          fill: 'white'
                        }}
                      />
                      <defs>
                        <linearGradient id="totalGradient" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#3B82F6" stopOpacity={0.1}/>
                          <stop offset="95%" stopColor="#3B82F6" stopOpacity={0}/>
                        </linearGradient>
                      </defs>
                      <Area
                        type="monotone"
                        dataKey="total"
                        fill="url(#totalGradient)"
                        strokeWidth={0}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
                
                <div className="grid grid-cols-3 gap-4 mt-6">
                  <div className="p-4 bg-blue-50 rounded-lg">
                    <div className="text-sm font-medium text-gray-600">Total</div>
                    <div className="text-xl font-semibold text-blue-600 mt-1">
                      {stats.evolutionMensuelle.reduce((acc, curr) => acc + curr.total, 0)}
                    </div>
                  </div>
                  <div className="p-4 bg-green-50 rounded-lg">
                    <div className="text-sm font-medium text-gray-600">Validées</div>
                    <div className="text-xl font-semibold text-green-600 mt-1">
                      {stats.evolutionMensuelle.reduce((acc, curr) => acc + curr.validees, 0)}
                    </div>
                  </div>
                  <div className="p-4 bg-red-50 rounded-lg">
                    <div className="text-sm font-medium text-gray-600">Rejetées</div>
                    <div className="text-xl font-semibold text-red-600 mt-1">
                      {stats.evolutionMensuelle.reduce((acc, curr) => acc + curr.rejetees, 0)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Statistiques par client */}
          {activeTab === 'clients' && (
            <div className="space-y-6">
              {/* Filtres avancés */}
              <div className="bg-white rounded-xl p-4 shadow-sm border border-gray-200">
                <div className="grid grid-cols-4 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Client</label>
                    <select
                      value={tableFilters.client}
                      onChange={(e) => setTableFilters(prev => ({ ...prev, client: e.target.value }))}
                      className="w-full rounded-lg border-gray-300 shadow-sm text-sm"
                    >
                      <option value="">Tous les clients</option>
                      {stats.clientStats.map(client => (
                        <option key={client.client} value={client.client}>{client.client}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Volume de demandes</label>
                    <select
                      value={tableFilters.volume}
                      onChange={(e) => setTableFilters(prev => ({ ...prev, volume: e.target.value }))}
                      className="w-full rounded-lg border-gray-300 shadow-sm text-sm"
                    >
                      <option value="">Tous les volumes</option>
                      <option value="high">Volume élevé (&gt;100)</option>
                      <option value="medium">Volume moyen (20-100)</option>
                      <option value="low">Volume faible (&lt;20)</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Taux de validation</label>
                    <select
                      value={tableFilters.tauxValidation}
                      onChange={(e) => setTableFilters(prev => ({ ...prev, tauxValidation: e.target.value }))}
                      className="w-full rounded-lg border-gray-300 shadow-sm text-sm"
                    >
                      <option value="">Tous les taux</option>
                      <option value="high">Élevé (&gt;90%)</option>
                      <option value="medium">Moyen (70-90%)</option>
                      <option value="low">Faible (&lt;70%)</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Statut</label>
                    <select
                      value={tableFilters.statut}
                      onChange={(e) => setTableFilters(prev => ({ ...prev, statut: e.target.value }))}
                      className="w-full rounded-lg border-gray-300 shadow-sm text-sm"
                    >
                      <option value="">Tous les statuts</option>
                      <option value="actif">Clients actifs</option>
                      <option value="inactif">Clients inactifs (&gt;30j)</option>
                    </select>
                  </div>
                </div>
              </div>

              {/* Vue condensée des clients */}
              <div className="bg-white rounded-xl shadow-sm border border-gray-200">
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Client</th>
                        <th className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Volume</th>
                        <th className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Validation</th>
                        <th className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Temps moy.</th>
                        <th className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Dernière activité</th>
                        <th className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {paginatedClients().map((client: ClientStat, index: number) => (
                        <tr key={index} className="hover:bg-gray-50">
                          <td className="px-4 py-3 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="text-sm font-medium text-gray-900">{client.client}</div>
                            </div>
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap text-sm text-center">
                            <div className="flex flex-col items-center">
                              <span className="font-medium">{client.total}</span>
                              <span className="text-xs text-gray-500">
                                {client.enCours} en cours
                              </span>
                            </div>
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap text-center">
                            <div className="flex flex-col items-center">
                              <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                                ${client.tauxValidation > 90 
                                  ? 'bg-green-100 text-green-800'
                                  : client.tauxValidation > 70
                                    ? 'bg-yellow-100 text-yellow-800'
                                    : 'bg-red-100 text-red-800'}`}>
                                {client.tauxValidation.toFixed(1)}%
                              </span>
                              <span className="text-xs text-gray-500 mt-1">
                                {client.terminees} terminées
                              </span>
                            </div>
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap text-sm text-center">
                            {client.tempsMoyenTraitement.toFixed(1)}h
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap text-sm text-center text-gray-500">
                            {client.demandeurs.length > 0 
                              ? new Date(client.demandeurs[0].dateDemande).toLocaleDateString('fr-FR', {
                                  day: 'numeric',
                                  month: 'short'
                                })
                              : 'N/A'
                            }
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap text-sm text-center">
                            <button
                              onClick={() => setSelectedClient(client)}
                              className="text-blue-600 hover:text-blue-800"
                            >
                              Détails
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <Pagination />
              </div>

              {/* Modal de détails client */}
              {selectedClient && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                  <div className="bg-white rounded-xl shadow-xl w-full max-w-6xl p-6 max-h-[90vh] overflow-hidden">
                    {/* En-tête avec fermeture */}
                    <div className="flex justify-between items-start mb-6">
                      <div>
                        <h3 className="text-xl font-bold text-gray-900">{selectedClient.client}</h3>
                        <p className="text-sm text-gray-500 mt-1">
                          {selectedClient.total} demandes au total • {selectedClient.enCours} en cours • {selectedClient.terminees} terminées
                        </p>
                      </div>
                      <button
                        onClick={() => setSelectedClient(null)}
                        className="text-gray-400 hover:text-gray-500"
                      >
                        <X className="w-5 h-5" />
                      </button>
                    </div>

                    {/* Statistiques résumées */}
                    <div className="grid grid-cols-4 gap-4 mb-6">
                      <div className="bg-gray-50 rounded-lg p-4">
                        <span className="text-sm text-gray-600">Taux de validation</span>
                        <p className="text-2xl font-semibold text-green-600 mt-1">
                          {selectedClient.tauxValidation.toFixed(1)}%
                        </p>
                      </div>
                      <div className="bg-gray-50 rounded-lg p-4">
                        <span className="text-sm text-gray-600">Temps moyen</span>
                        <p className="text-2xl font-semibold text-blue-600 mt-1">
                          {selectedClient.tempsMoyenTraitement.toFixed(1)}h
                        </p>
                      </div>
                      <div className="bg-gray-50 rounded-lg p-4">
                        <span className="text-sm text-gray-600">Documents validés</span>
                        <p className="text-2xl font-semibold text-gray-900 mt-1">
                          {selectedClient.demandeurs.filter(d => d.resultatExpertise === 'OK').length}
                        </p>
                      </div>
                      <div className="bg-gray-50 rounded-lg p-4">
                        <span className="text-sm text-gray-600">Documents rejetés</span>
                        <p className="text-2xl font-semibold text-gray-900 mt-1">
                          {selectedClient.demandeurs.filter(d => d.resultatExpertise === 'KO').length}
                        </p>
                      </div>
                    </div>

                    {/* Filtres pour les demandes */}
                    <div className="flex space-x-4 mb-4">
                      <select 
                        className="rounded-lg border-gray-300 text-sm"
                        onChange={(e) => {
                          // Ajouter la logique de filtrage par type de document
                        }}
                      >
                        <option value="">Tous les documents</option>
                        {Array.from(new Set(selectedClient.demandeurs.map(d => d.typeDocument))).map(type => (
                          <option key={type} value={type}>{type}</option>
                        ))}
                      </select>
                      <select 
                        className="rounded-lg border-gray-300 text-sm"
                        onChange={(e) => {
                          // Ajouter la logique de filtrage par pays
                        }}
                      >
                        <option value="">Tous les pays</option>
                        {Array.from(new Set(selectedClient.demandeurs.map(d => d.pays))).map(pays => (
                          <option key={pays} value={pays}>{pays}</option>
                        ))}
                      </select>
                      <select 
                        className="rounded-lg border-gray-300 text-sm"
                        onChange={(e) => {
                          // Ajouter la logique de filtrage par statut
                        }}
                      >
                        <option value="">Tous les statuts</option>
                        <option value="en_cours">En cours</option>
                        <option value="terminé">Terminé</option>
                      </select>
                    </div>

                    {/* Liste des demandes avec pagination */}
                    <div className="overflow-y-auto" style={{ maxHeight: 'calc(90vh - 300px)' }}>
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50 sticky top-0">
                          <tr>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Date</th>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Demandeur</th>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Document</th>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Pays</th>
                            <th className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase">Statut</th>
                            <th className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase">Résultat</th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {selectedClient.demandeurs
                            .sort((a, b) => new Date(b.dateDemande).getTime() - new Date(a.dateDemande).getTime())
                            .map((demandeur, idx) => (
                              <tr key={idx} className="hover:bg-gray-50">
                                <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
                                  {new Date(demandeur.dateDemande).toLocaleDateString('fr-FR', {
                                    day: '2-digit',
                                    month: 'short',
                                    year: 'numeric'
                                  })}
                                </td>
                                <td className="px-4 py-3 whitespace-nowrap">
                                  <div>
                                    <div className="text-sm font-medium text-gray-900">
                                      {demandeur.nom} {demandeur.prenom}
                                    </div>
                                    <div className="text-sm text-gray-500">{demandeur.email}</div>
                                  </div>
                                </td>
                                <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
                                  {demandeur.typeDocument}
                                </td>
                                <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
                                  {demandeur.pays}
                                </td>
                                <td className="px-4 py-3 whitespace-nowrap text-center">
                                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                                    ${demandeur.statut === 'terminé'
                                      ? 'bg-green-100 text-green-800'
                                      : 'bg-yellow-100 text-yellow-800'
                                    }`}
                                  >
                                    {demandeur.statut}
                                  </span>
                                </td>
                                <td className="px-4 py-3 whitespace-nowrap text-center">
                                  {demandeur.statut === 'terminé' && (
                                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                                      ${demandeur.resultatExpertise === 'OK'
                                        ? 'bg-green-100 text-green-800'
                                        : 'bg-red-100 text-red-800'
                                      }`}
                                    >
                                      {demandeur.resultatExpertise}
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Statistiques par type de document */}
          {activeTab === 'documents' && (
            <div className="space-y-6">
              {/* En-tête avec résumé */}
              <div className="bg-white rounded-xl p-4 shadow-sm border border-gray-200">
                <div className="flex items-center justify-between mb-2">
                  <h3 className="text-lg font-semibold text-gray-900">Répartition par type de document</h3>
                  <div className="text-sm text-gray-500">
                    Période : {selectedPeriod === 'month' ? 'Ce mois' : selectedPeriod === 'year' ? 'Cette année' : 'Tout'}
                  </div>
                </div>
              </div>

              {/* Grille des types de documents */}
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {['carte d\'identité', 'passeport', 'titre de sejour', 'visa', 'permis de conduire'].map((docType) => {
                  const docStats = stats.documentStats.find(d => d.type === docType) || {
                    total: 0,
                    validees: 0,
                    rejetees: 0,
                    enCours: 0,
                    tauxValidation: 0,
                    percentage: 0
                  };

                  return (
                    <div key={docType} className="bg-white rounded-lg shadow-sm border border-gray-200 hover:border-blue-300 transition-colors">
                      <div className="p-4">
                        <div className="flex items-center justify-between mb-3">
                          <h4 className="font-medium text-gray-900">{docType}</h4>
                          <span className="text-xs font-medium px-2 py-1 rounded-full bg-blue-50 text-blue-600">
                            {docStats.percentage.toFixed(1)}% du total
                          </span>
                        </div>

                        <div className="grid grid-cols-2 gap-3 text-sm">
                          <div className="space-y-2">
                            <div>
                              <span className="text-gray-500">Total</span>
                              <p className="font-semibold text-gray-900">{docStats.total}</p>
                            </div>
                            <div>
                              <span className="text-gray-500">En cours</span>
                              <p className="font-semibold text-yellow-600">{docStats.enCours}</p>
                            </div>
                          </div>
                          <div className="space-y-2">
                            <div>
                              <span className="text-gray-500">Validées</span>
                              <p className="font-semibold text-green-600">{docStats.validees}</p>
                            </div>
                            <div>
                              <span className="text-gray-500">Rejetées</span>
                              <p className="font-semibold text-red-600">{docStats.rejetees}</p>
                            </div>
                          </div>
                        </div>

                        {/* Barre de progression du taux de validation */}
                        <div className="mt-4">
                          <div className="flex items-center justify-between text-xs mb-1">
                            <span className="font-medium">Taux de validation</span>
                            <span className={`font-medium ${
                              docStats.tauxValidation > 75 ? 'text-green-600' : 'text-red-600'
                            }`}>
                              {docStats.tauxValidation.toFixed(1)}%
                            </span>
                          </div>
                          <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
                            <div 
                              className={`h-full rounded-full ${
                                docStats.tauxValidation > 75 ? 'bg-green-500' : 'bg-red-500'
                              }`}
                              style={{ width: `${docStats.tauxValidation}%` }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              {/* Tableau récapitulatif */}
              <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Type</th>
                        <th scope="col" className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase">Total</th>
                        <th scope="col" className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase">En Cours</th>
                        <th scope="col" className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase">Validées</th>
                        <th scope="col" className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase">Rejetées</th>
                        <th scope="col" className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase">Taux Valid.</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {['carte d\'identité', 'passeport', 'titre de sejour', 'visa', 'permis de conduire'].map((docType) => {
                        const docStats = stats.documentStats.find(d => d.type === docType) || {
                          total: 0,
                          validees: 0,
                          rejetees: 0,
                          enCours: 0,
                          tauxValidation: 0
                        };

                        return (
                          <tr key={docType} className="hover:bg-gray-50">
                            <td className="px-4 py-2 whitespace-nowrap">
                              <div className="flex items-center">
                                <span className="text-sm font-medium text-gray-900">{docType}</span>
                              </div>
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap text-sm text-center text-gray-500">
                              {docStats.total}
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap text-sm text-center text-yellow-600">
                              {docStats.enCours}
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap text-sm text-center text-green-600 font-medium">
                              {docStats.validees}
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap text-sm text-center text-red-600 font-medium">
                              {docStats.rejetees}
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap text-sm text-center">
                              <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                                docStats.tauxValidation > 75 
                                  ? 'bg-green-100 text-green-800' 
                                  : 'bg-red-100 text-red-800'
                              }`}>
                                {docStats.tauxValidation.toFixed(1)}%
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}

          {/* Statistiques géographiques */}
          {activeTab === 'geography' && (
            <div className="space-y-6">

              {/* Indicateurs clés de fraude */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {/* Zone à risque */}
                <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-4">
                  <h4 className="text-sm font-medium text-gray-500">Zone géographique à risque élevé</h4>
                  {stats.paysStats.length > 0 ? (
                    <div className="mt-2">
                      <p className="text-2xl font-bold text-red-600">
                        {stats.paysStats.sort((a, b) => 
                          (b.rejetees / b.total) - (a.rejetees / a.total)
                        )[0]?.pays}
                      </p>
                      <div className="flex items-center mt-1">
                        <span className="text-sm text-gray-600">
                          {stats.paysStats.sort((a, b) => 
                            (b.rejetees / b.total) - (a.rejetees / a.total)
                          )[0]?.rejetees} fraudes détectées
                        </span>
                        <span className="ml-2 px-2 py-1 text-xs font-medium rounded-full bg-red-100 text-red-800">
                          Taux de fraude {((stats.paysStats.sort((a, b) => 
                            (b.rejetees / b.total) - (a.rejetees / a.total)
                          )[0]?.rejetees / stats.paysStats.sort((a, b) => 
                            (b.rejetees / b.total) - (a.rejetees / a.total)
                          )[0]?.total) * 100).toFixed(1)}%
                        </span>
                      </div>
                    </div>
                  ) : (
                    <p className="text-sm text-gray-500 mt-2">Aucune donnée disponible</p>
                  )}
                </div>

                {/* Volume de tentatives */}
                <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-4">
                  <h4 className="text-sm font-medium text-gray-500">Pays avec le plus de tentatives</h4>
                  {stats.paysStats.length > 0 ? (
                    <div className="mt-2">
                      <p className="text-2xl font-bold text-gray-900">
                        {stats.paysStats.sort((a, b) => b.total - a.total)[0]?.pays}
                      </p>
                      <div className="flex items-center mt-1">
                        <span className="text-sm text-gray-600">
                          {stats.paysStats.sort((a, b) => b.total - a.total)[0]?.total} demandes
                        </span>
                        <span className="ml-2 px-2 py-1 text-xs font-medium rounded-full bg-blue-100 text-blue-800">
                          {stats.paysStats.sort((a, b) => b.total - a.total)[0]?.percentage.toFixed(1)}% du total
                        </span>
                      </div>
                    </div>
                  ) : (
                    <p className="text-sm text-gray-500 mt-2">Aucune donnée disponible</p>
                  )}
                </div>

                {/* Nouvelles zones de fraude */}
                <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-4">
                  <h4 className="text-sm font-medium text-gray-500">Zones émergentes</h4>
                  <div className="mt-2">
                    <p className="text-2xl font-bold text-orange-600">{stats.paysStats.length}</p>
                    <p className="text-sm text-gray-600 mt-1">pays différents détectés</p>
                  </div>
                </div>
              </div>

              {/* Barre de recherche */}
              <div className="bg-white rounded-xl p-4 shadow-sm border border-gray-200">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Rechercher un pays..."
                    value={searchCountry}
                    onChange={(e) => {
                      setSearchCountry(e.target.value);
                      setCurrentPage(1); // Réinitialiser la pagination lors d'une recherche
                    }}
                    className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 
                             focus:ring-blue-500 focus:border-blue-500 transition-colors duration-200"
                  />
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <svg
                      className="h-5 w-5 text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              {/* Liste détaillée des pays */}
              <div className="bg-white rounded-xl shadow-sm border border-gray-200">
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Pays</th>
                        <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">Tentatives</th>
                        <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">Documents Validés</th>
                        <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">Documents Falsifiés</th>
                        <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">En cours d'analyse</th>
                        <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">Taux de fraude</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {filteredPaysStats().map((pays: PaysStats, index) => (
                        <tr key={index} className="hover:bg-gray-50">
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <span className="text-sm font-medium text-gray-900">{pays.pays}</span>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-center text-gray-500">
                            {pays.total}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-center text-green-600 font-medium">
                            {pays.validees}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-center text-red-600 font-medium">
                            {pays.rejetees}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-center text-orange-600">
                            {pays.enCours || 0}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-center">
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                              (pays.rejetees / pays.total) > 0.5
                                ? 'bg-red-100 text-red-800' 
                                : (pays.rejetees / pays.total) > 0.25
                                  ? 'bg-orange-100 text-orange-800'
                                  : 'bg-green-100 text-green-800'
                            }`}>
                              {((pays.rejetees / pays.total) * 100).toFixed(1)}%
                            </span>
                          </td>
                        </tr>
                      ))}
                      {filteredPaysStats().length === 0 && (
                        <tr>
                          <td colSpan={6} className="px-6 py-4 text-center text-gray-500">
                            Aucun pays ne correspond à votre recherche
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <Pagination />
              </div>
            </div>
          )}

          {/* Tendances et évolution */}
          {activeTab === 'trends' && (
            <div className="space-y-6">
              {/* Indicateurs clés de la fraude */}
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-4">
                  <h4 className="text-sm font-medium text-gray-500">Taux de fraude global</h4>
                  <div className="mt-2">
                    <p className="text-2xl font-bold text-red-600">
                      {((stats.clientStats.reduce((acc, client) => 
                        acc + client.demandeurs.filter(d => d.resultatExpertise === 'KO').length, 0
                      ) / totals.totalDemandes) * 100).toFixed(1)}%
                    </p>
                    <p className="text-sm text-gray-600 mt-1">des documents analysés</p>
                  </div>
                </div>

                <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-4">
                  <h4 className="text-sm font-medium text-gray-500">Type de document le plus fraudé</h4>
                  <div className="mt-2">
                    <p className="text-2xl font-bold text-orange-600">
                      {stats.documentPaysStats && stats.documentPaysStats.length > 0
                        ? stats.documentPaysStats[0].type
                        : 'Aucune donnée'}
                    </p>
                    <p className="text-sm text-gray-600 mt-1">
                      {stats.documentPaysStats && stats.documentPaysStats.length > 0
                        ? `${stats.documentPaysStats[0].tauxFraude.toFixed(1)}% de fraude`
                        : '0% de fraude'}
                    </p>
                  </div>
                </div>

                <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-4">
                  <h4 className="text-sm font-medium text-gray-500">Temps moyen de réponse de l'expert</h4>
                  <div className="mt-2">
                    <p className="text-2xl font-bold text-blue-600">
                      {stats.tempsTraitement.moyen.toFixed(1)}h
                    </p>
                    <p className="text-sm text-gray-600 mt-1">pour répondre à une demande</p>
                  </div>
                </div>

                <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-4">
                  <h4 className="text-sm font-medium text-gray-500">Demandes en cours</h4>
                  <div className="mt-2">
                    <p className="text-2xl font-bold text-yellow-600">
                      {stats.clientStats.reduce((acc, client) => 
                        acc + client.demandeurs.filter(d => d.statut === 'en_cours').length, 0
                      )}
                    </p>
                    <p className="text-sm text-gray-600 mt-1">documents en analyse</p>
                  </div>
                </div>
              </div>

              {/* Évolution de la fraude */}
              <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-200">
                <h3 className="text-lg font-semibold mb-6">Évolution de la fraude documentaire</h3>
                <div className="h-[400px]">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={stats.evolutionMensuelle}>
                      <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                      <XAxis 
                        dataKey="mois" 
                        tick={{ fill: '#6B7280', fontSize: 12 }}
                      />
                      <YAxis 
                        tick={{ fill: '#6B7280', fontSize: 12 }}
                        label={{ 
                          value: 'Nombre de fraudes détectées', 
                          angle: -90, 
                          position: 'insideLeft',
                          fill: '#6B7280'
                        }}
                      />
                      <Tooltip />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="rejetees"
                        name="Fraudes détectées"
                        stroke="#EF4444"
                        strokeWidth={2}
                        dot={{ fill: '#EF4444' }}
                      />
                      <Line
                        type="monotone"
                        dataKey="total"
                        name="Total documents"
                        stroke="#6B7280"
                        strokeWidth={2}
                        strokeDasharray="5 5"
                        dot={{ fill: '#6B7280' }}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>

              {/* Analyse des patterns de fraude */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-200">
                  <h3 className="text-lg font-semibold mb-4">Répartition par type de document</h3>
                  <div className="space-y-4">
                    {stats.documentStats
                      .sort((a, b) => (b.rejetees / b.total) - (a.rejetees / a.total))
                      .map((doc, index) => (
                        <div key={index} className="flex items-center justify-between">
                          <div className="flex-1">
                            <p className="text-sm font-medium text-gray-900">{doc.type}</p>
                            <div className="mt-1 relative pt-1">
                              <div className="overflow-hidden h-2 text-xs flex rounded bg-gray-100">
                                <div
                                  className="bg-red-500"
                                  style={{ width: `${(doc.rejetees / doc.total) * 100}%` }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="ml-4 text-right">
                            <span className="text-sm font-semibold text-red-600">
                              {((doc.rejetees / doc.total) * 100).toFixed(1)}%
                            </span>
                            <p className="text-xs text-gray-500">
                              {doc.rejetees} sur {doc.total}
                            </p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>

                <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-200">
                  <h3 className="text-lg font-semibold mb-4">Zones géographiques à risque</h3>
                  <div className="space-y-4">
                    {stats.paysStats
                      .sort((a, b) => (b.rejetees / b.total) - (a.rejetees / a.total))
                      .slice(0, 5)
                      .map((pays, index) => (
                        <div key={index} className="flex items-center justify-between">
                          <div className="flex-1">
                            <p className="text-sm font-medium text-gray-900">{pays.pays}</p>
                            <div className="mt-1 relative pt-1">
                              <div className="overflow-hidden h-2 text-xs flex rounded bg-gray-100">
                                <div
                                  className="bg-orange-500"
                                  style={{ width: `${(pays.rejetees / pays.total) * 100}%` }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="ml-4 text-right">
                            <span className="text-sm font-semibold text-orange-600">
                              {((pays.rejetees / pays.total) * 100).toFixed(1)}%
                            </span>
                            <p className="text-xs text-gray-500">
                              {pays.rejetees} fraudes sur {pays.total}
                            </p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              {/* Alertes et observations */}
              <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-200">
                <h3 className="text-lg font-semibold mb-4">Alertes et observations</h3>
                <div className="space-y-4">
                  {/* Alerte sur l'augmentation de la fraude */}
                  {stats.evolutionMensuelle.length >= 2 && 
                    stats.evolutionMensuelle[stats.evolutionMensuelle.length - 1].rejetees >
                    stats.evolutionMensuelle[stats.evolutionMensuelle.length - 2].rejetees && (
                    <div className="bg-red-50 border-l-4 border-red-500 p-4">
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          <AlertCircle className="h-5 w-5 text-red-400" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-red-700">
                            Augmentation de la fraude détectée ce mois
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Alerte sur les zones à risque émergentes */}
                  {stats.paysStats
                    .filter(pays => (pays.rejetees / pays.total) > 0.5)
                    .length > 0 && (
                    <div className="bg-orange-50 border-l-4 border-orange-500 p-4">
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          <AlertCircle className="h-5 w-5 text-orange-400" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-orange-700">
                            {stats.paysStats
                              .filter(pays => (pays.rejetees / pays.total) > 0.5)
                              .length} zones géographiques présentent un taux de fraude supérieur à 50%
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StatisticsManagement; 