import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import NouvelleDemandeForm from './NouvelleDemandeForm';
import HistoriqueDemandes from './HistoriqueDemandes';
import Messages from './Messages';
import Aide from './Aide';
import { 
  FileText, 
  History, 
  LogOut,
  User,
  ChevronRight,
  Bell,
  Mail,
  Trash2,
  Info,
  X,
  MessageCircle,
  HelpCircle
} from 'lucide-react';
import API_CONFIG from '../../config/api';

interface DashboardProps {
  onLogout: () => void;
}

interface Notification {
  _id: string;
  message: string;
  lu: boolean;
  dateCreation: string;
  type: 'status_change' | 'message' | 'info';
}

interface UnreadCounts {
  messages: number;
  notifications: number;
}

interface UserData {
  email: string;
  nomClient: string;
}

const Dashboard: React.FC<DashboardProps> = ({ onLogout }) => {
  const [activeTab, setActiveTab] = useState<'nouvelle' | 'historique' | 'messages' | 'aide'>('nouvelle');
  const [userEmail, setUserEmail] = useState<string>('');
  const [userName, setUserName] = useState('');
  const [userFirstName, setUserFirstName] = useState('');
  const [slideDirection, setSlideDirection] = useState(0);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showGuide, setShowGuide] = useState(false);
  const [guideStep, setGuideStep] = useState(0);
  const [hasSeenGuide, setHasSeenGuide] = useState(() => {
    return localStorage.getItem('hasSeenGuide') === 'true';
  });
  const [unreadCounts, setUnreadCounts] = useState<UnreadCounts>({
    messages: 0,
    notifications: 0
  });
  const [userData, setUserData] = useState<UserData>({ email: '', nomClient: '' });
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (token) {
      const payload = JSON.parse(atob(token.split('.')[1]));
      setUserEmail(payload.email);
      fetchUserData(payload.id);
    }
  }, []);

  useEffect(() => {
    fetchNotifications();
    fetchUnreadCounts();
  }, []);

  // Rafraîchir les compteurs toutes les 10 secondes
  useEffect(() => {
    fetchUnreadCounts(); // Premier appel

    const interval = setInterval(() => {
      fetchUnreadCounts();
    }, 10000); // 10 secondes

    return () => clearInterval(interval);
  }, []);

  const fetchNotifications = async () => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await fetch(`${API_CONFIG.baseURL}/api/notifications`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      setNotifications(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des notifications:', error);
    }
  };

  const fetchUnreadCounts = async () => {
    try {
      const token = localStorage.getItem('userToken');
      
      // Récupérer le nombre de messages non lus
      const messagesResponse = await fetch(`${API_CONFIG.baseURL}/api/messages/unread-count`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const messagesData = await messagesResponse.json();

      // Mettre à jour le compteur uniquement s'il y a un changement
      setUnreadCounts(prev => ({
        ...prev,
        messages: messagesData.count
      }));
    } catch (error) {
      console.error('Erreur lors de la récupération des compteurs:', error);
    }
  };

  const fetchUserData = async (userId: string) => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await fetch(`${API_CONFIG.baseURL}/api/users/${userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        setUserEmail(data.email);
        setUserName(data.nom);
        setUserFirstName(data.prenom);
      }
    } catch (error) {
      console.error('Erreur:', error);
    }
  };

  const handleMarkAsRead = async (notificationId: string) => {
    try {
      const token = localStorage.getItem('userToken');
      await fetch(`${API_CONFIG.baseURL}/api/notifications/${notificationId}/read`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      setNotifications(notifications.map(notif => 
        notif._id === notificationId ? { ...notif, lu: true } : notif
      ));
    } catch (error) {
      console.error('Erreur lors du marquage de la notification:', error);
    }
  };

  const unreadCount = notifications.filter(n => !n.lu).length;

  const handleLogout = () => {
    onLogout();
    navigate('/login');
  };

  const handleDeleteNotification = async (notificationId: string) => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await fetch(`${API_CONFIG.baseURL}/api/notifications/${notificationId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        setNotifications(notifications.filter(n => n._id !== notificationId));
      }
    } catch (error) {
      console.error('Erreur lors de la suppression de la notification:', error);
    }
  };

  const handleDeleteAllNotifications = async () => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await fetch(`${API_CONFIG.baseURL}/api/notifications/all`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        setNotifications([]);
        setShowNotifications(false);
      }
    } catch (error) {
      console.error('Erreur lors de la suppression des notifications:', error);
    }
  };

  // Ajouter un écouteur d'événement pour les mises à jour du compteur
  useEffect(() => {
    const handleUnreadUpdate = (event: CustomEvent<{ count: number }>) => {
      setUnreadCounts(prev => ({
        ...prev,
        messages: event.detail.count
      }));
    };

    window.addEventListener('unreadMessagesUpdate', handleUnreadUpdate as EventListener);

    return () => {
      window.removeEventListener('unreadMessagesUpdate', handleUnreadUpdate as EventListener);
    };
  }, []);

  return (
    <div className="min-h-screen bg-[#F8FAFC]">
      {/* Header Principal */}
      <header className="bg-white border-b border-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            <div className="flex items-center">
              <div className="flex-shrink-0 flex items-center">
                {userData.nomClient && (
                  <div className="flex items-center space-x-3">
                    <img 
                      src={`/${userData.nomClient.replace(/[^a-zA-Z0-9]/g, '')}.svg`}
                      alt={`Logo ${userData.nomClient}`}
                      className="h-8 w-auto object-contain"
                      onError={(e) => {
                        const target = e.target as HTMLImageElement;
                        target.style.display = 'none';
                      }}
                    />
                    <div className="h-6 w-px bg-gray-200" /> {/* Séparateur vertical */}
                  </div>
                )}
                <div className="ml-3 h-8 w-8 bg-gradient-to-r from-blue-600 to-indigo-600 rounded flex items-center justify-center">
                  <span className="text-white font-semibold">R</span>
                </div>
              </div>
              <div className="hidden md:block ml-10">
                <div className="flex items-baseline space-x-4">
                  <motion.button
                    onClick={() => setActiveTab('nouvelle')}
                    className={`px-3 py-2 rounded-md text-sm font-medium flex items-center space-x-2 ${
                      activeTab === 'nouvelle' 
                        ? 'text-blue-600 bg-blue-50' 
                        : 'text-gray-600 hover:text-gray-900'
                    }`}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    <FileText size={18} />
                    <span>Nouvelle Demande</span>
                  </motion.button>
                  <motion.button
                    onClick={() => setActiveTab('historique')}
                    className={`px-3 py-2 rounded-md text-sm font-medium flex items-center space-x-2 ${
                      activeTab === 'historique' 
                        ? 'text-blue-600 bg-blue-50' 
                        : 'text-gray-600 hover:text-gray-900'
                    }`}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    <History size={18} />
                    <span>Historique</span>
                  </motion.button>
                  <motion.button
                    onClick={() => setActiveTab('messages')}
                    className="relative px-3 py-2 rounded-md text-sm font-medium flex items-center space-x-2"
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    <MessageCircle className="w-5 h-5" />
                    <span>Messages</span>
                    {unreadCounts.messages > 0 && (
                      <span className="absolute -top-2 -right-2 flex h-5 w-5">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-5 w-5 bg-blue-500 text-white text-xs items-center justify-center">
                          {unreadCounts.messages}
                        </span>
                      </span>
                    )}
                  </motion.button>
                  <motion.button
                    onClick={() => setActiveTab('aide')}
                    className={`px-3 py-2 rounded-md text-sm font-medium flex items-center space-x-2 ${
                      activeTab === 'aide' 
                        ? 'text-blue-600 bg-blue-50' 
                        : 'text-gray-600 hover:text-gray-900'
                    }`}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    <HelpCircle size={18} />
                    <span>Aide</span>
                  </motion.button>
                </div>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              {/* Notifications */}
              <div className="relative">
                <motion.button
                  className="p-2 rounded-full text-gray-400 hover:text-gray-500 hover:bg-gray-100"
                  onClick={() => setShowNotifications(!showNotifications)}
                  whileHover={{ scale: 1.05 }}
                >
                  <Bell size={20} />
                  {unreadCount > 0 && (
                    <span className="absolute -top-1 -right-1 h-5 w-5 flex items-center justify-center text-xs text-white bg-red-500 rounded-full">
                      {unreadCount}
                    </span>
                  )}
                </motion.button>

                {/* Dropdown des notifications */}
                {showNotifications && (
                  <div className="absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-lg border border-gray-200 z-50">
                    <div className="p-4 border-b border-gray-200 flex justify-between items-center">
                      <h3 className="text-lg font-semibold">Notifications</h3>
                      {notifications.length > 0 && (
                        <button
                          onClick={handleDeleteAllNotifications}
                          className="text-red-500 hover:text-red-700 text-sm flex items-center space-x-1"
                        >
                          <Trash2 size={14} />
                          <span>Tout supprimer</span>
                        </button>
                      )}
                    </div>
                    <div className="max-h-96 overflow-y-auto">
                      {notifications.length === 0 ? (
                        <div className="p-4 text-center text-gray-500">
                          Aucune notification
                        </div>
                      ) : (
                        notifications.map((notification) => (
                          <motion.div
                            key={notification._id}
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            className={`p-4 border-b border-gray-100 hover:bg-gray-50 ${
                              !notification.lu ? 'bg-blue-50' : ''
                            }`}
                          >
                            <div className="flex justify-between items-start">
                              <div className="flex-1" onClick={() => handleMarkAsRead(notification._id)}>
                                <p className="text-sm text-gray-800">{notification.message}</p>
                                <p className="text-xs text-gray-500 mt-1">
                                  {new Date(notification.dateCreation).toLocaleDateString('fr-FR', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit'
                                  })}
                                </p>
                              </div>
                              <button
                                onClick={() => handleDeleteNotification(notification._id)}
                                className="text-gray-400 hover:text-red-500 transition-colors p-1 rounded-full hover:bg-gray-100"
                              >
                                <Trash2 size={14} />
                              </button>
                            </div>
                          </motion.div>
                        ))
                      )}
                    </div>
                  </div>
                )}
              </div>

              {/* Profil */}
              <div className="flex items-center space-x-3">
                <motion.div
                  className="h-9 w-9 rounded-full bg-gradient-to-r from-blue-600 to-indigo-600 flex items-center justify-center text-white font-medium text-sm"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <User size={18} />
                </motion.div>
                <div className="hidden md:flex flex-col">
                  <span className="text-sm font-medium text-gray-700">{userEmail}</span>
                  <span className="text-xs text-gray-500">Utilisateur</span>
                </div>
                <motion.button
                  onClick={handleLogout}
                  className="p-2 rounded-full text-gray-400 hover:text-red-500 hover:bg-red-50"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <LogOut size={18} />
                </motion.button>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Contenu Principal */}
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="mb-8">
          <nav className="flex" aria-label="Breadcrumb">
            <ol className="flex items-center space-x-2">
              <li>
                <div className="flex items-center">
                  <span className="text-gray-400">Espace Personnel</span>
                  <ChevronRight size={16} className="ml-2 text-gray-400" />
                </div>
              </li>
              <li>
                <span className="text-gray-700 font-medium">
                  {activeTab === 'nouvelle' 
                    ? 'Nouvelle Demande' 
                    : activeTab === 'historique' 
                      ? 'Historique' 
                      : 'Messages'}
                </span>
              </li>
            </ol>
          </nav>
        </div>

        <AnimatePresence mode="wait" custom={slideDirection}>
          <motion.div
            key={activeTab}
            custom={slideDirection}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20
            }}
          >
            {activeTab === 'nouvelle' && <NouvelleDemandeForm userEmail={userEmail} userName={userName} userFirstName={userFirstName} />}
            {activeTab === 'historique' && <HistoriqueDemandes />}
            {activeTab === 'messages' && <Messages />}
            {activeTab === 'aide' && <Aide />}
          </motion.div>
        </AnimatePresence>
      </main>
    </div>
  );
};

export default Dashboard;