import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UserManagement from './UserManagement';
import DemandeManagement from './DemandeManagement';
import StatisticsManagement from './StatisticsManagement';
import {
  Users,
  FileText,
  BarChart2,
  Settings,
  LogOut,
  ChevronRight,
  Activity,
  Clock,
  CheckCircle,
  AlertCircle,
  TrendingUp,
  Calendar,
  PieChart,
  Globe
} from 'lucide-react';
import useMeasure from "react-use-measure";
import { motion, AnimatePresence, useMotionValue, useTransform, useSpring } from "framer-motion";
import { FilterSection } from '../../components/filters/filter-section';
import { Filters } from '../../types/filters';
import API_CONFIG from '../../config/api'; 

interface AdminDashboardProps {
  userName: string;
  onLogout: () => void;
}

interface DashboardStats {
  totalUsers: number;
  demandesEnCours: number;
  demandesTraitees: number;
  totalDemandes: number;
}

interface User {
  _id: string;
  email: string;
  actif: boolean;
  dateCreation: string;
}

interface Demande {
  _id: string;
  statut: 'en_attente' | 'en_cours' | 'terminé' | 'rejeté';
  dateDemande: string;
  dateEnvoi?: string;
  typeDocument: string;
  pays: string;
  resultatExpertise?: 'OK' | 'KO' | null;
  reference: string;
}

interface DetailedStats {
  tempsTraitementMoyen: string;
  tempsTraitementMin: string;
  tempsTraitementMax: string;
  nombreDemandesTraitees: number;
  tauxValidation: number;
  tauxRejet: number;
  typePlusDemandeLabel: string;
  typePlusDemandeCount: number;
  paysPrincipalLabel: string;
  paysPrincipalCount: number;
  demandesEnAttente: number;
}

// Ajouter l'interface pour les statistiques
interface StatsData {
  topDocuments: Array<{
    type: string;
    count: number;
    percentage: number;
  }>;
  topPays: Array<{
    pays: string;
    count: number;
    percentage: number;
  }>;
  totalDemandes: number;
}

// Ajouter une interface pour les variations
interface StatsVariations {
  usersVariation: number;
  demandesEnCoursVariation: number;
  demandesTraiteesVariation: number;
  tauxTraitementVariation: number;
}

// Ajouter l'interface pour les props du YearSelector
interface YearSelectorProps {
  selectedYear: number;
  onYearChange: (year: number) => void;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  setSelectedYear: React.Dispatch<React.SetStateAction<number>>;
}

const YearSelector: React.FC<YearSelectorProps> = ({ 
  selectedYear, 
  onYearChange,
  setFilters,
  setSelectedYear
}) => {
  const [activeTab, setActiveTab] = useState<'year' | 'month'>('year');
  const [direction, setDirection] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [ref, bounds] = useMeasure();
  const [selectedMonth, setSelectedMonth] = useState<number | null>(null);

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 5 }, (_, i) => currentYear - i);
  const months = Array.from({ length: 12 }, (_, i) => ({
    value: i + 1,
    label: new Date(2024, i).toLocaleString('fr-FR', { month: 'long' })
  }));

  const tabs = [
    { id: 'year', label: "Année" },
    { id: 'month', label: "Mois" },
  ];

  const handleTabClick = (newTabId: 'year' | 'month') => {
    if (newTabId !== activeTab && !isAnimating) {
      const newDirection = newTabId === 'month' ? 1 : -1;
      setDirection(newDirection);
      setActiveTab(newTabId);
    }
  };

  const variants = {
    initial: (direction: number) => ({
      x: 300 * direction,
      opacity: 0,
      filter: "blur(4px)",
    }),
    active: {
      x: 0,
      opacity: 1,
      filter: "blur(0px)"
    },
    exit: (direction: number) => ({
      x: -300 * direction,
      opacity: 0,
      filter: "blur(4px)",
    })
  };

  const handleYearClick = (year: number) => {
    setSelectedYear(year);
    setFilters(prev => ({
      ...prev,
      year: year,
      month: null
    }));
    onYearChange(year);
  };

  const handleMonthChange = (month: number) => {
    setSelectedMonth(month);
    setFilters((prev: Filters) => ({ ...prev, month }));
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-4">
      <div className="flex flex-col items-center">
        <div className="flex space-x-1 border border-none rounded-[8px] cursor-pointer bg-gray-100 shadow-md px-[3px] py-[3.2px] mb-4">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              onClick={() => handleTabClick(tab.id as 'year' | 'month')}
              className={`${
                activeTab === tab.id 
                  ? "text-blue-600"
                  : "text-gray-600 hover:text-gray-800"
              } relative rounded-[5px] px-3 py-1.5 text-sm font-medium transition z-20`}
            >
              {activeTab === tab.id && (
                <motion.span
                  layoutId="period-selector"
                  className="absolute inset-0 z-10 bg-white"
                  style={{ borderRadius: 5 }}
                  transition={{ type: "spring", bounce: 0.19, duration: 0.4 }}
                />
              )}
              <span className="relative z-20">{tab.label}</span>
            </button>
          ))}
        </div>

        <motion.div
          className="relative mx-auto w-[200px] overflow-hidden"
          initial={false}
          animate={{ height: bounds.height }}
        >
          <div className="p-2" ref={ref}>
            <AnimatePresence
              custom={direction}
              mode="popLayout"
              onExitComplete={() => setIsAnimating(false)}
            >
              <motion.div
                key={activeTab}
                variants={variants}
                initial="initial"
                animate="active"
                exit="exit"
                custom={direction}
                onAnimationStart={() => setIsAnimating(true)}
                onAnimationComplete={() => setIsAnimating(false)}
              >
                {activeTab === 'year' ? (
                  <div className="grid grid-cols-2 gap-2">
                    {years.map((year) => (
                      <motion.button
                        key={year}
                        onClick={() => handleYearClick(year)}
                        className={`px-4 py-2 rounded-lg text-sm font-medium transition-all duration-200 ${
                          selectedYear === year
                            ? "bg-blue-500 text-white shadow-lg scale-105"
                            : "text-gray-600 hover:bg-gray-100"
                        }`}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        animate={{
                          scale: selectedYear === year ? 1.05 : 1,
                          backgroundColor: selectedYear === year ? "#3B82F6" : "#FFFFFF",
                        }}
                        transition={{ duration: 0.2 }}
                      >
                        {year}
                      </motion.button>
                    ))}
                  </div>
                ) : (
                  <div className="w-full px-2">
                    <div className="max-h-[160px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-transparent pb-8">
                      {months.map((month) => (
                        <motion.button
                          key={month.value}
                          onClick={() => handleMonthChange(month.value)}
                          className={`
                            w-full px-3 py-2.5 mb-1 rounded-lg text-sm font-medium 
                            flex items-center justify-between
                            relative overflow-hidden
                            ${selectedMonth === month.value 
                              ? "bg-blue-50" 
                              : "hover:bg-gray-50"
                            }
                          `}
                          whileHover={{ scale: 1.02 }}
                          whileTap={{ scale: 0.98 }}
                        >
                          <motion.div
                            className="absolute inset-0 bg-blue-500 opacity-0"
                            initial={false}
                            animate={{
                              opacity: selectedMonth === month.value ? 0.1 : 0
                            }}
                            transition={{ duration: 0.2 }}
                          />
                          <motion.div
                            className="relative z-10 flex-grow text-center"
                            animate={{
                              color: selectedMonth === month.value ? "#3B82F6" : "#4B5563"
                            }}
                          >
                            {month.label}
                          </motion.div>
                          {selectedMonth === month.value && (
                            <motion.div
                              className="absolute right-3 text-blue-500"
                              initial={{ scale: 0, opacity: 0 }}
                              animate={{ scale: 1, opacity: 1 }}
                              exit={{ scale: 0, opacity: 0 }}
                            >
                              <CheckCircle className="w-4 h-4" />
                            </motion.div>
                          )}
                        </motion.button>
                      ))}
                    </div>
                  </div>
                )}
              </motion.div>
            </AnimatePresence>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

const MonthWheel: React.FC<{
  onSelectMonth: (month: number) => void;
}> = ({ onSelectMonth }) => {
  const [selectedMonth, setSelectedMonth] = useState<number | null>(null);
  const months = Array.from({ length: 12 }, (_, i) => ({
    value: i + 1,
    label: new Date(2024, i).toLocaleString('fr-FR', { month: 'long' })
  }));

  return (
    <div className="flex flex-col space-y-2 max-h-[200px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-transparent">
      {months.map((month) => (
        <button
          key={month.value}
          onClick={() => {
            setSelectedMonth(month.value);
            onSelectMonth(month.value);
          }}
          className={`
            px-4 py-2 rounded-lg text-sm font-medium transition-all
            ${selectedMonth === month.value 
              ? "bg-blue-500 text-white transform scale-105" 
              : "text-gray-600 hover:bg-gray-100"
            }
          `}
        >
          {month.label}
        </button>
      ))}
    </div>
  );
};

const AdminDashboard: React.FC<AdminDashboardProps> = ({ userName, onLogout }) => {
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState<'dashboard' | 'users' | 'demandes' | 'statistics'>('dashboard');
  const [stats, setStats] = useState<DashboardStats>({
    totalUsers: 0,
    demandesEnCours: 0,
    demandesTraitees: 0,
    totalDemandes: 0
  });
  const [activityData, setActivityData] = useState<any[]>([]);
  const [distributionData, setDistributionData] = useState<any[]>([]);
  const [selectedDistribution, setSelectedDistribution] = useState<'type' | 'pays' | 'statut'>('type');
  const [detailedStats, setDetailedStats] = useState({
    tempsTraitementMoyen: '',
    tempsTraitementMin: '',
    tempsTraitementMax: '',
    nombreDemandesTraitees: 0,
    tauxValidation: 0,
    tauxRejet: 0,
    typePlusDemandeLabel: '',
    typePlusDemandeCount: 0,
    paysPrincipalLabel: '',
    paysPrincipalCount: 0,
    demandesEnAttente: 0
  });
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedPeriod, setSelectedPeriod] = useState<'month' | 'year' | 'all'>('month');
  const [statsData, setStatsData] = useState<StatsData>({
    topDocuments: [],
    topPays: [],
    totalDemandes: 0
  });
  const [filters, setFilters] = useState<Filters>({
    year: new Date().getFullYear(),
    month: null,
    type: '',
    pays: '',
    statut: ''
  });
  const [statsVariations, setStatsVariations] = useState<StatsVariations>({
    usersVariation: 0,
    demandesEnCoursVariation: 0,
    demandesTraiteesVariation: 0,
    tauxTraitementVariation: 0
  });

  useEffect(() => {
    if (activeMenu === 'dashboard') {
      fetchDashboardStats();
    }
  }, [activeMenu]);

  useEffect(() => {
    fetchDashboardStats();
  }, [selectedYear]);

  const calculateDetailedStats = (demandes: Demande[]) => {
    // Temps de traitement
    const demandesTerminees = demandes.filter(d => 
      d.statut === 'terminé' && 
      d.dateEnvoi && 
      d.dateDemande
    );

    const tempsTraitement = demandesTerminees.reduce((acc, d) => {
      const debut = new Date(d.dateDemande).getTime();
      const fin = new Date(d.dateEnvoi!).getTime();
      const differenceEnHeures = (fin - debut) / (1000 * 60 * 60); // Conversion en heures
      return acc + differenceEnHeures;
    }, 0);

    // Calculer la moyenne en heures
    const tempsTraitementMoyenHeures = demandesTerminees.length > 0 
      ? Math.round((tempsTraitement / demandesTerminees.length) * 10) / 10
      : 0;

    // Calculer les temps min et max en heures
    let tempsMinHeures = Infinity;
    let tempsMaxHeures = 0;

    demandesTerminees.forEach(d => {
      const debut = new Date(d.dateDemande).getTime();
      const fin = new Date(d.dateEnvoi!).getTime();
      const tempsEnHeures = (fin - debut) / (1000 * 60 * 60);
      
      tempsMinHeures = Math.min(tempsMinHeures, tempsEnHeures);
      tempsMaxHeures = Math.max(tempsMaxHeures, tempsEnHeures);
    });

    // Si aucune demande, mettre à 0
    if (demandesTerminees.length === 0) {
      tempsMinHeures = 0;
      tempsMaxHeures = 0;
    }

    // Distribution par type de document
    const typeCount = demandes.reduce((acc: { [key: string]: number }, d) => {
      acc[d.typeDocument] = (acc[d.typeDocument] || 0) + 1;
      return acc;
    }, {});

    const typePlusDemandeEntry = Object.entries(typeCount)
      .sort(([,a], [,b]) => b - a)[0] || ['', 0];

    // Distribution par pays
    const paysCount = demandes.reduce((acc: { [key: string]: number }, d) => {
      acc[d.pays] = (acc[d.pays] || 0) + 1;
      return acc;
    }, {});

    const paysPrincipalEntry = Object.entries(paysCount)
      .sort(([,a], [,b]) => b - a)[0] || ['', 0];

    // Demandes en attente
    const demandesEnAttente = demandes.filter(d => d.statut === 'en_attente').length;

    // Fonction pour formater le temps
    const formatTemps = (heures: number) => {
      if (heures >= 24) {
        const jours = Math.floor(heures / 24);
        const heuresRestantes = Math.round((heures % 24) * 10) / 10;
        return heuresRestantes > 0 
          ? `${jours}j ${heuresRestantes}h`
          : `${jours}j`;
      }
      return `${Math.round(heures * 10) / 10}h`;
    };

    setDetailedStats({
      tempsTraitementMoyen: formatTemps(tempsTraitementMoyenHeures),
      tempsTraitementMin: formatTemps(tempsMinHeures),
      tempsTraitementMax: formatTemps(tempsMaxHeures),
      nombreDemandesTraitees: demandesTerminees.length,
      tauxValidation: demandesTerminees.length > 0 
        ? (demandesTerminees.filter(d => d.resultatExpertise === 'OK').length / demandesTerminees.length) * 100 
        : 0,
      tauxRejet: demandesTerminees.length > 0 
        ? (demandesTerminees.filter(d => d.resultatExpertise === 'KO').length / demandesTerminees.length) * 100 
        : 0,
      typePlusDemandeLabel: typePlusDemandeEntry[0],
      typePlusDemandeCount: typePlusDemandeEntry[1],
      paysPrincipalLabel: paysPrincipalEntry[0],
      paysPrincipalCount: paysPrincipalEntry[1],
      demandesEnAttente
    });
  };

  const fetchDashboardStats = async () => {
    try {
      const token = localStorage.getItem('adminToken');
      const headers = {
        'Authorization': `Bearer ${token}`
      };

      // Construire l'URL avec les filtres
      const queryParams = new URLSearchParams();
      
      // Utiliser l'année des filtres plutôt que selectedYear
      if (filters.year) {
        queryParams.append('year', filters.year.toString());
      }
      
      if (filters.month) {
        queryParams.append('month', filters.month.toString());
      }

      // Autres filtres
      if (filters.type) queryParams.append('type', filters.type);
      if (filters.pays) queryParams.append('pays', filters.pays);
      if (filters.statut) queryParams.append('statut', filters.statut);

      const url = `${API_CONFIG.baseURL}/api/demandes?${queryParams.toString()}`;

      const demandesResponse = await fetch(url, { headers });

      if (!demandesResponse.ok) {
        throw new Error('Erreur lors de la récupération des données');
      }

      const data = await demandesResponse.json();

      const demandes: Demande[] = data.demandes;
      
      // Calculer les statistiques de base
      const demandesEnCours = demandes.filter(d => d.statut === 'en_cours').length;
      const demandesTraitees = demandes.filter(d => d.statut === 'terminé').length;
      const totalDemandes = demandes.length;

      setStats({
        totalUsers: data.totalUsers,
        demandesEnCours,
        demandesTraitees,
        totalDemandes
      });

      calculateDetailedStats(demandes);

    } catch (error) {
      console.error('Erreur détaillée:', error);
      if (error instanceof Error && error.message.includes('401')) {
        onLogout();
      }
    }
  };

  // Ajouter un useEffect pour réagir aux changements de filtres
  useEffect(() => {
    if (activeMenu === 'dashboard') {
      fetchDashboardStats();
    }
  }, [filters]);

  // Fonction pour réinitialiser les filtres
  const resetFilters = () => {
    setFilters({
      year: new Date().getFullYear(),
      month: null,
      type: '',
      pays: '',
      statut: ''
    });
  };

  const handleLogout = () => {
    onLogout();
  };

  // Calculer les variations (pour l'exemple, à adapter selon vos besoins)
  const calculateChange = (current: number, total: number): string => {
    if (total === 0) return '0%';
    const percentage = (current / total) * 100;
    return `${percentage.toFixed(1)}%`;
  };

  const calculateActivityData = (demandes: Demande[]) => {
    // Grouper les demandes par date
    const groupedByDate = demandes.reduce((acc: any, demande) => {
      const date = new Date(demande.dateDemande).toLocaleDateString();
      if (!acc[date]) {
        acc[date] = {
          date,
          nouvelles: 0,
          traitees: 0,
          tempsMoyen: 0
        };
      }
      acc[date].nouvelles++;
      if (demande.statut === 'terminé') {
        acc[date].traitees++;
        // Calculer le temps de traitement en heures
        const tempsTraitement = new Date(demande.dateEnvoi || '').getTime() - new Date(demande.dateDemande).getTime();
        acc[date].tempsMoyen = (acc[date].tempsMoyen + tempsTraitement / (1000 * 60 * 60)) / 2;
      }
      return acc;
    }, {});

    return Object.values(groupedByDate).slice(-7); // Derniers 7 jours
  };

  const calculateDistributionData = (demandes: Demande[], type: 'type' | 'pays' | 'statut') => {
    const grouped = demandes.reduce((acc: any, demande) => {
      const key = type === 'type' ? demande.typeDocument : 
                  type === 'pays' ? demande.pays : 
                  demande.statut;
      acc[key] = (acc[key] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(grouped).map(([name, value]) => ({
      name,
      value
    }));
  };

  // Couleurs pour le graphique en anneau
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

  // Ajouter la fonction pour récuprer les statistiques
  const fetchGlobalStats = async () => {
    try {
      const token = localStorage.getItem('adminToken');
      const response = await fetch(`${API_CONFIG.baseURL}/api/demandes/stats?period=${selectedPeriod}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      setStatsData(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des statistiques:', error);
    }
  };

  // Ajouter useEffect pour charger les statistiques
  useEffect(() => {
    if (activeMenu === 'dashboard') {
      fetchGlobalStats();
    }
  }, [activeMenu, selectedPeriod]);

  // Fonction pour calculer les variations
  const calculateVariations = async () => {
    try {
      const token = localStorage.getItem('adminToken');
      const headers = { 'Authorization': `Bearer ${token}` };

      // Dates pour les différentes périodes
      const now = new Date();
      const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      const lastWeek = new Date(now.setDate(now.getDate() - 7));
      
      // Récupérer les statistiques des périodes précédentes
      const [lastMonthStats, lastWeekStats] = await Promise.all([
        fetch(`${API_CONFIG.baseURL}/api/demandes?startDate=${lastMonth.toISOString()}`, { headers }),
        fetch(`${API_CONFIG.baseURL}/api/demandes?startDate=${lastWeek.toISOString()}`, { headers })
      ]);

      const lastMonthData = await lastMonthStats.json();
      const lastWeekData = await lastWeekStats.json();

      // Calculer les variations
      const usersVariation = calculatePercentageChange(
        stats.totalUsers,
        lastMonthData.totalUsers
      );

      const demandesEnCoursVariation = calculatePercentageChange(
        stats.demandesEnCours,
        lastWeekData.demandesEnCours
      );

      const demandesTraiteesVariation = calculatePercentageChange(
        stats.demandesTraitees,
        lastMonthData.demandesTraitees
      );

      const previousTauxTraitement = lastMonthData.demandesTraitees / lastMonthData.totalDemandes;
      const currentTauxTraitement = stats.demandesTraitees / stats.totalDemandes;
      const tauxTraitementVariation = calculatePercentageChange(
        currentTauxTraitement * 100,
        previousTauxTraitement * 100
      );

      setStatsVariations({
        usersVariation,
        demandesEnCoursVariation,
        demandesTraiteesVariation,
        tauxTraitementVariation
      });

    } catch (error) {
      console.error('Erreur lors du calcul des variations:', error);
    }
  };

  // Fonction utilitaire pour calculer le pourcentage de variation
  const calculatePercentageChange = (current: number, previous: number): number => {
    if (previous === 0) return 0;
    return Number(((current - previous) / previous * 100).toFixed(1));
  };

  // Mettre à jour useEffect pour calculer les variations
  useEffect(() => {
    if (activeMenu === 'dashboard') {
      fetchDashboardStats();
      calculateVariations();
    }
  }, [activeMenu, filters]);

  return (
    <div className="min-h-screen bg-gray-50 flex">
      {/* Sidebar moderne */}
      <div className="w-64 bg-white border-r border-gray-200 fixed h-full">
        <div className="flex items-center justify-between px-6 h-16 border-b border-gray-200">
          <div className="flex items-center">
            <div className="h-8 w-8 bg-gradient-to-r from-blue-600 to-indigo-600 rounded-lg flex items-center justify-center">
              <span className="text-white font-bold">R</span>
            </div>
            <span className="ml-3 text-lg font-semibold text-gray-900">
              RESOEXPERT
            </span>
          </div>
        </div>

        <nav className="mt-4 px-3">
          <div className="space-y-2">
            <SidebarItem
              icon={<BarChart2 size={20} />}
              text="Dashboard"
              active={activeMenu === 'dashboard'}
              onClick={() => setActiveMenu('dashboard')}
            />
            <SidebarItem
              icon={<Users size={20} />}
              text="Utilisateurs"
              active={activeMenu === 'users'}
              onClick={() => setActiveMenu('users')}
            />
            <SidebarItem
              icon={<FileText size={20} />}
              text="Demandes"
              active={activeMenu === 'demandes'}
              onClick={() => setActiveMenu('demandes')}
            />
            <SidebarItem
              icon={<PieChart size={20} />}
              text="Statistiques"
              active={activeMenu === 'statistics'}
              onClick={() => setActiveMenu('statistics')}
            />
          </div>

          <div className="mt-auto pt-4 border-t border-gray-200 absolute bottom-0 w-full left-0 px-3 pb-4">
            <button
              onClick={handleLogout}
              className="flex items-center px-4 py-2 text-sm text-red-600 hover:bg-red-50 rounded-lg w-full transition-colors"
            >
              <LogOut size={18} className="mr-3" />
              Déconnexion
            </button>
          </div>
        </nav>
      </div>

      {/* Contenu principal */}
      <div className="flex-1 ml-64">
        {/* Header */}
        <header className="h-16 bg-white border-b border-gray-200 flex items-center justify-between px-6">
          <div className="flex items-center">
            <h1 className="text-lg font-semibold text-gray-900">
              {activeMenu.charAt(0).toUpperCase() + activeMenu.slice(1)}
            </h1>
            <ChevronRight className="w-4 h-4 text-gray-400 mx-2" />
            <span className="text-sm text-gray-500">Vue d'ensemble</span>
          </div>
          
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-2">
              <div className="w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center">
                <span className="text-sm font-medium text-blue-600">
                  {userName.charAt(0).toUpperCase()}
                </span>
              </div>
              <span className="text-sm font-medium text-gray-700">{userName}</span>
            </div>
          </div>
        </header>

        {/* Contenu principal */}
        <main className="p-6">
          {activeMenu === 'dashboard' && (
            <div className="space-y-6">
              <FilterSection 
                filters={filters} 
                setFilters={setFilters}
                onReset={resetFilters}
              />
              {/* Cartes de statistiques principales */}
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                <StatCard
                  title="Utilisateurs Total"
                  value={stats.totalUsers}
                  icon={<Users className="w-5 h-5 text-blue-600" />}
                  trend={statsVariations.usersVariation}
                  trendLabel="vs mois dernier"
                />
                <StatCard
                  title="Demandes en cours"
                  value={stats.demandesEnCours}
                  icon={<Clock className="w-5 h-5 text-yellow-600" />}
                  trend={statsVariations.demandesEnCoursVariation}
                  trendLabel="cette semaine"
                />
                <StatCard
                  title="Demandes traitées"
                  value={stats.demandesTraitees}
                  icon={<CheckCircle className="w-5 h-5 text-green-600" />}
                  trend={statsVariations.demandesTraiteesVariation}
                  trendLabel="ce mois"
                />
                <StatCard
                  title="Taux de traitement"
                  value={`${stats.totalDemandes > 0 
                    ? ((stats.demandesTraitees / stats.totalDemandes) * 100).toFixed(1) 
                    : '0'}%`}
                  icon={<Activity className="w-5 h-5 text-indigo-600" />}
                  trend={statsVariations.tauxTraitementVariation}
                  trendLabel="vs dernière période"
                />
              </div>

              {/* Container pour les cartes expandables et la table */}
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                {/* Cartes expandables */}
                <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6">
                  <div className="flex items-center justify-between mb-6">
                    <h3 className="text-lg font-semibold text-gray-900">
                      Temps moyen de traitement
                    </h3>
                    <div className="flex items-center space-x-2">
                      <span className="text-sm text-gray-500">
                        {detailedStats.nombreDemandesTraitees === 1 ? '1 demande traitée' : `${detailedStats.nombreDemandesTraitees} demandes traitées`}
                      </span>
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4 mb-6">
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <p className="text-sm text-gray-600">Temps moyen</p>
                      <p className="text-xl font-semibold text-gray-900">
                        {detailedStats.tempsTraitementMoyen}
                      </p>
                    </div>
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <p className="text-sm text-gray-600">Temps maximum</p>
                      <p className="text-xl font-semibold text-gray-900">
                        {detailedStats.tempsTraitementMax}
                      </p>
                    </div>
                  </div>

                  <div className="space-y-4">
                    <div className="bg-blue-50 p-4 rounded-lg">
                      <div className="flex justify-between items-center">
                        <span className="text-sm text-blue-600">Efficacité de traitement</span>
                        <span className="text-sm font-medium text-blue-600">
                          {((detailedStats.nombreDemandesTraitees / stats.totalDemandes) * 100).toFixed(1)}%
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6">
                  <div className="flex items-center justify-between mb-6">
                    <h3 className="text-lg font-semibold text-gray-900">
                      Distribution des demandes
                    </h3>
                    <select
                      value={selectedPeriod}
                      onChange={(e) => setSelectedPeriod(e.target.value as 'month' | 'year' | 'all')}
                      className="px-3 py-1.5 border border-gray-300 rounded-lg text-sm"
                    >
                      <option value="month">Ce mois</option>
                      <option value="year">Cette année</option>
                      <option value="all">Tout</option>
                    </select>
                  </div>

                  <div className="grid grid-cols-2 gap-4 mb-6">
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <div className="flex justify-between items-center">
                        <span className="text-sm text-gray-600">Type le plus demandé</span>
                        <span className="text-sm font-medium text-gray-900">
                          {detailedStats.typePlusDemandeLabel}
                        </span>
                      </div>
                      <div className="mt-2 text-xs text-gray-500">
                        {detailedStats.typePlusDemandeCount} demandes
                      </div>
                    </div>
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <div className="flex justify-between items-center">
                        <span className="text-sm text-gray-600">Pays principal</span>
                        <span className="text-sm font-medium text-gray-900">
                          {detailedStats.paysPrincipalLabel}
                        </span>
                      </div>
                      <div className="mt-2 text-xs text-gray-500">
                        {detailedStats.paysPrincipalCount} demandes
                      </div>
                    </div>
                  </div>

                  <div className="bg-yellow-50 p-4 rounded-lg">
                    <div className="flex justify-between items-center">
                      <span className="text-sm text-yellow-600">En attente de traitement</span>
                      <span className="text-sm font-medium text-yellow-600">
                        {detailedStats.demandesEnAttente} demandes
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Graphiques et statistiques détaillées */}
              <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  {/* Top Documents */}
                  <div className="space-y-4">
                    <h4 className="text-sm font-medium text-gray-700 flex items-center gap-2 mb-4">
                      <PieChart className="w-4 h-4" />
                      Top Documents
                    </h4>
                    <div className="space-y-3">
                      {statsData.topDocuments.map((item, index) => (
                        <div key={index} className="bg-gray-50 p-4 rounded-lg">
                          <div className="flex justify-between items-center mb-2">
                            <span className="font-medium text-gray-900">{item.type}</span>
                            <div className="flex items-center space-x-2">
                              <span className="text-sm font-semibold text-blue-600">{item.count}</span>
                              <span className="text-xs text-gray-500">demandes</span>
                            </div>
                          </div>
                          <div className="flex items-center justify-between text-xs text-gray-500">
                            <span>Pourcentage total</span>
                            <span className="font-medium text-blue-600">{item.percentage.toFixed(1)}%</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Top Pays */}
                  <div className="space-y-4">
                    <h4 className="text-sm font-medium text-gray-700 flex items-center gap-2 mb-4">
                      <Globe className="w-4 h-4" />
                      Top Pays
                    </h4>
                    <div className="space-y-3">
                      {statsData.topPays.map((item, index) => (
                        <div key={index} className="bg-gray-50 p-4 rounded-lg">
                          <div className="flex justify-between items-center mb-2">
                            <span className="font-medium text-gray-900">{item.pays}</span>
                            <div className="flex items-center space-x-2">
                              <span className="text-sm font-semibold text-blue-600">{item.count}</span>
                              <span className="text-xs text-gray-500">demandes</span>
                            </div>
                          </div>
                          <div className="flex items-center justify-between text-xs text-gray-500">
                            <span>Pourcentage total</span>
                            <span className="font-medium text-blue-600">{item.percentage.toFixed(1)}%</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeMenu === 'users' && <UserManagement />}
          {activeMenu === 'demandes' && <DemandeManagement />}
          {activeMenu === 'statistics' && <StatisticsManagement />}
        </main>
      </div>
    </div>
  );
};

// Composant pour les éléments de la sidebar
interface SidebarItemProps {
  icon: React.ReactNode;
  text: string;
  active: boolean;
  onClick: () => void;
}

const SidebarItem: React.FC<SidebarItemProps> = ({ icon, text, active, onClick }) => (
  <button
    onClick={onClick}
    className={`flex items-center w-full px-4 py-2.5 text-sm font-medium rounded-lg transition-colors ${
      active
        ? 'bg-blue-50 text-blue-600'
        : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
    }`}
  >
    <span className="mr-3">{icon}</span>
    {text}
  </button>
);

// Composant pour les cartes de statistiques
interface StatCardProps {
  title: string;
  value: number | string;
  icon: React.ReactNode;
  trend: number;
  trendLabel: string;
}

const StatCard: React.FC<StatCardProps> = ({ title, value, icon, trend, trendLabel }) => {
  // Fonction pour formater le trend
  const formatTrend = (trend: number) => {
    if (isNaN(trend) || trend === undefined) return '0';
    return `${trend > 0 ? '+' : ''}${trend}`;
  };

  // Fonction pour formater la valeur
  const formatValue = (value: number | string) => {
    if (typeof value === 'number') {
      return isNaN(value) ? '0' : value.toString();
    }
    return value;
  };

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6">
      <div className="flex items-center justify-between">
        <div className="bg-blue-50 rounded-lg p-2">
          {icon}
        </div>
        <span className={`text-sm font-medium ${trend >= 0 ? 'text-green-600' : 'text-red-600'}`}>
          {formatTrend(trend)}% {trendLabel}
        </span>
      </div>
      <div className="mt-4">
        <h3 className="text-sm font-medium text-gray-500">{title}</h3>
        <p className="text-2xl font-semibold text-gray-900 mt-1">{formatValue(value)}</p>
      </div>
    </div>
  );
};

export default AdminDashboard;