import React, { useState, useEffect, useRef } from 'react';
import Notification from '../../components/Notification';
import ImageViewer from '../../components/ui/ImageViewer';
import { Mail, MessageCircle, X, Paperclip, ChevronDown, Search, Filter, ChevronLeft, ChevronRight } from 'lucide-react';
import { FileUpload } from '../../components/ui/file-upload';
import { AnimatePresence, motion } from 'framer-motion';
import API_CONFIG from '../../config/api';

interface Message {
  _id: string;
  content: string;
  dateSend: string;
  fromAdmin: boolean;
  attachments: string[];
  lu: boolean;
  responses: {
    _id: string;
    content: string;
    dateSend: string;
    fromAdmin: boolean;
    attachments: string[];
    lu: boolean;
  }[];
}

interface Demande {
  _id: string;
  userId: string;
  nom: string;
  prenom: string;
  email: string;
  dateDemande: string;
  typeDocument: string;
  pays: string;
  statut: 'en_attente' | 'en_cours' | 'terminé' | 'rejeté';
  images: string[];
  commentaires: string;
  messages?: Message[];
  reference: string;
  nomClient?: string;
  unreadMessages?: number;
}

interface ImageViewerState {
  isOpen: boolean;
  imageUrl: string;
  fileName: string;
}

interface MessageFormData {
  userId: string;
  demandeId: string;
  sujet: string;
  content: string;
}

interface CertificateModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (files: File[], resultat: 'OK' | 'KO') => Promise<void>;
}

interface Filters {
  search: string;
  typeDocument: string;
  pays: string;
  statut: string;
  dateDebut: string;
  dateFin: string;
}

// Ajouter cette interface pour le commentaire sélectionné
interface SelectedComment {
  isOpen: boolean;
  content: string;
  reference: string;
  date: string;
}

const CertificateModal: React.FC<CertificateModalProps> = ({ isOpen, onClose, onSubmit }) => {
  const [files, setFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const [resultat, setResultat] = useState<'OK' | 'KO'>('OK');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (files.length === 0) {
      alert('Veuillez ajouter au moins un certificat');
      return;
    }
    setLoading(true);
    try {
      await onSubmit(files, resultat);
      onClose();
    } catch (error) {
      console.error('Erreur lors de l\'envoi des certificats:', error);
      alert('Erreur lors de l\'envoi des certificats');
    } finally {
      setLoading(false);
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            className="bg-white rounded-lg p-6 max-w-2xl w-full mx-4"
            onClick={e => e.stopPropagation()}
          >
            <div className="flex justify-between items-start mb-4">
              <h3 className="text-lg font-semibold text-gray-900">
                Finaliser la demande
              </h3>
              <button
                onClick={onClose}
                className="text-gray-400 hover:text-gray-600"
              >
                <X className="h-5 w-5" />
              </button>
            </div>

            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Résultat de l'expertise
                </label>
                <div className="relative">
                  <select
                    value={resultat}
                    onChange={(e) => setResultat(e.target.value as 'OK' | 'KO')}
                    className="w-full pl-4 pr-10 py-2.5 text-sm bg-white border border-gray-200 rounded-lg 
                    focus:ring-2 focus:ring-blue-100 focus:border-blue-400 
                    hover:border-gray-300 appearance-none cursor-pointer transition-all duration-200
                    shadow-sm"
                  >
                    <option value="OK" className="text-green-600">Document valide</option>
                    <option value="KO" className="text-red-600">Document non valide</option>
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none text-gray-400">
                    <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
                      <path d="M6 9L12 15L18 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </div>
                </div>
              </div>

              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Certificats (PDF ou Word)
                </label>
                <FileUpload
                  onChange={setFiles}
                  maxFiles={2}
                  currentFiles={files}
                  accept=".pdf,.doc,.docx"
                />
              </div>

              <div className="flex justify-end space-x-3 mt-6">
                <button
                  type="button"
                  onClick={onClose}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  disabled={loading || files.length === 0}
                  className={`px-4 py-2 text-sm font-medium text-white rounded-md disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2 ${
                    resultat === 'OK' ? 'bg-green-600 hover:bg-green-700' : 'bg-red-600 hover:bg-red-700'
                  }`}
                >
                  {loading ? (
                    <>
                      <span className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></span>
                      <span>Envoi en cours...</span>
                    </>
                  ) : (
                    <span>Valider</span>
                  )}
                </button>
              </div>
            </form>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

// Ajouter un composant pour le badge de référence
const ReferenceBadge: React.FC<{ reference: string }> = ({ reference }) => (
  <span className="px-2 py-1 text-xs font-medium bg-gray-100 text-gray-800 rounded-full">
    {reference}
  </span>
);

// Ajouter un composant pour les informations client
const ClientInfo: React.FC<{ demande: Demande }> = ({ demande }) => (
  <div className="flex flex-col">
    <div className="flex items-center space-x-2">
      <span className="text-sm font-medium text-gray-900">
        {demande.nom} {demande.prenom}
      </span>
      <ReferenceBadge reference={demande.reference} />
    </div>
    <span className="text-xs text-gray-500">{demande.email}</span>
  </div>
);

const DemandeManagement: React.FC = () => {
  const [demandes, setDemandes] = useState<Demande[]>([]);
  const [imageViewer, setImageViewer] = useState<ImageViewerState>({
    isOpen: false,
    imageUrl: '',
    fileName: ''
  });
  const [notification, setNotification] = useState({
    show: false,
    message: '',
    type: 'success' as 'success' | 'error'
  });
  const [showMessageForm, setShowMessageForm] = useState(false);
  const [messageForm, setMessageForm] = useState<MessageFormData>({
    userId: '',
    demandeId: '',
    sujet: '',
    content: ''
  });
  const [selectedDemandeMessages, setSelectedDemandeMessages] = useState<{
    demande: Demande | null;
    showMessages: boolean;
  }>({
    demande: null,
    showMessages: false
  });
  const [messages, setMessages] = useState<Message[]>([]);
  const [attachments, setAttachments] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showCertificateModal, setShowCertificateModal] = useState(false);
  const [selectedDemandeId, setSelectedDemandeId] = useState<string | null>(null);
  const [filters, setFilters] = useState<Filters>({
    search: '',
    typeDocument: '',
    pays: '',
    statut: '',
    dateDebut: '',
    dateFin: ''
  });
  const [showFilters, setShowFilters] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;
  
  const typeDocuments = ['Carte d\'identité', 'Passeport', 'Permis de conduire', 'Acte de naissance'];
  const statuts = ['En attente', 'En cours', 'Terminé', 'Rejeté'];

  // Ajouter cet état avec les autres états
  const [selectedComment, setSelectedComment] = useState<SelectedComment>({
    isOpen: false,
    content: '',
    reference: '',
    date: ''
  });

  useEffect(() => {
    fetchDemandes();
  }, []);

  useEffect(() => {
  }, [demandes]);

  const fetchDemandes = async () => {
    try {
      const token = localStorage.getItem('adminToken');
      const queryParams = new URLSearchParams();

      // Ajouter les paramètres de pagination
      queryParams.append('page', currentPage.toString());
      queryParams.append('limit', itemsPerPage.toString());

      // Autres filtres
      if (filters.search) queryParams.append('search', filters.search);
      if (filters.typeDocument) queryParams.append('type', filters.typeDocument);
      if (filters.pays) queryParams.append('pays', filters.pays);
      if (filters.statut) queryParams.append('statut', filters.statut);
      if (filters.dateDebut) queryParams.append('dateDebut', filters.dateDebut);
      if (filters.dateFin) queryParams.append('dateFin', filters.dateFin);

      const response = await fetch(`${API_CONFIG.baseURL}/api/demandes?${queryParams.toString()}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des demandes');
      }
      
      const data = await response.json();
      
      setDemandes(Array.isArray(data.demandes) ? data.demandes : []);
      
      setTotalPages(Math.ceil(data.total / itemsPerPage));
    } catch (error) {
      console.error('Erreur lors de la récupération des demandes:', error);
      setNotification({
        show: true,
        message: 'Erreur lors de la récupération des demandes',
        type: 'error'
      });
      setDemandes([]);
    }
  };

  useEffect(() => {
    fetchDemandes();
  }, [currentPage, filters]);

  const handleResetFilters = () => {
    setFilters({
      search: '',
      typeDocument: '',
      pays: '',
      statut: '',
      dateDebut: '',
      dateFin: ''
    });
  };

  const handleStatusChange = async (demandeId: string, newStatus: string) => {
    if (newStatus === 'terminé') {
      setSelectedDemandeId(demandeId);
      setShowCertificateModal(true);
      return;
    }

    try {
      const token = localStorage.getItem('adminToken');
      const response = await fetch(`${API_CONFIG.baseURL}/api/demandes/${demandeId}/status`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ statut: newStatus })
      });

      if (response.ok) {
        fetchDemandes();
        setNotification({
          show: true,
          message: 'Statut mis à jour avec succès',
          type: 'success'
        });
      }
    } catch (error) {
      setNotification({
        show: true,
        message: 'Erreur lors de la mise à jour du statut',
        type: 'error'
      });
    }
  };

  const handleCertificateSubmit = async (files: File[], resultat: 'OK' | 'KO') => {
    if (!selectedDemandeId) return;

    const formData = new FormData();
    formData.append('statut', 'terminé');
    formData.append('resultatExpertise', resultat);
    files.forEach(file => {
      formData.append('certificates', file);
    });

    try {
      const token = localStorage.getItem('adminToken');
      const response = await fetch(`${API_CONFIG.baseURL}/api/demandes/${selectedDemandeId}/status`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData
      });

      if (response.ok) {
        fetchDemandes();
        setNotification({
          show: true,
          message: 'Demande terminée et certificats ajoutés avec succès',
          type: 'success'
        });
      }
    } catch (error) {
      throw error;
    }
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'en_attente':
        return 'bg-yellow-100 text-yellow-800';
      case 'en_cours':
        return 'bg-blue-100 text-blue-800';
      case 'terminé':
        return 'bg-green-100 text-green-800';
      case 'rejeté':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getImageUrl = (filename: string) => {
    return `${API_CONFIG.baseURL}/uploads/images/${filename}`;
  };

  const handleImageClick = (filename: string) => {
    setImageViewer({
      isOpen: true,
      imageUrl: getImageUrl(filename),
      fileName: filename
    });
  };

  const handleDownloadImage = async (filename: string) => {
    try {
      const response = await fetch(getImageUrl(filename));
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      setNotification({
        show: true,
        message: 'Erreur lors du téléchargement de l\'image',
        type: 'error'
      });
    }
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    if (files.length + attachments.length > 3) {
      alert('Maximum 3 pièces jointes autorisées');
      return;
    }
    setAttachments(prev => [...prev, ...files]);
  };

  const removeAttachment = (index: number) => {
    setAttachments(prev => prev.filter((_, i) => i !== index));
  };

  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('adminToken');
      
      if (!selectedDemandeMessages.demande) {
        setNotification({
          show: true,
          message: 'Erreur: Demande non sélectionnée',
          type: 'error'
        });
        return;
      }

      const parentMessage = messages[0];
      if (!parentMessage) {
        const formData = new FormData();
        formData.append('content', messageForm.content);
        formData.append('userId', selectedDemandeMessages.demande.userId);
        formData.append('demandeId', selectedDemandeMessages.demande._id);
        formData.append('sujet', `RE: Demande de ${selectedDemandeMessages.demande.typeDocument}`);

        attachments.forEach(file => {
          formData.append('attachments', file);
        });

        const response = await fetch(`${API_CONFIG.baseURL}/api/messages`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`
          },
          body: formData
        });

        if (!response.ok) {
          throw new Error('Erreur lors de l\'envoi du message');
        }
      } else {
        const formData = new FormData();
        formData.append('content', messageForm.content);
        
        attachments.forEach(file => {
          formData.append('attachments', file);
        });

        const response = await fetch(`${API_CONFIG.baseURL}/api/messages/${parentMessage._id}/reply`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`
          },
          body: formData
        });

        if (!response.ok) {
          throw new Error('Erreur lors de l\'envoi de la réponse');
        }
      }

      setMessageForm({
        userId: '',
        demandeId: '',
        sujet: '',
        content: ''
      });
      setAttachments([]);

      if (selectedDemandeMessages.demande) {
        await fetchMessages(selectedDemandeMessages.demande._id);
      }

      setNotification({
        show: true,
        message: 'Message envoyé avec succès',
        type: 'success'
      });
    } catch (error) {
      console.error('Erreur détaillée:', error);
      setNotification({
        show: true,
        message: error instanceof Error ? error.message : 'Erreur lors de l\'envoi du message',
        type: 'error'
      });
    }
  };

  const fetchMessages = async (demandeId: string) => {
    try {
      const token = localStorage.getItem('adminToken');
      const response = await fetch(`${API_CONFIG.baseURL}/api/messages/demande/${demandeId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      setMessages(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des messages:', error);
    }
  };

  const handleMessageClick = async (demande: Demande) => {
    try {
      setSelectedDemandeMessages({
        demande,
        showMessages: true
      });
      
      if (demande.messages) {
        const token = localStorage.getItem('adminToken');
        
        // Marquer tous les messages de la conversation comme lus
        for (const message of demande.messages) {
          await fetch(`${API_CONFIG.baseURL}/api/messages/${message._id}/read`, {
            method: 'PATCH',
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
        }
        
        await fetchMessages(demande._id);
        await fetchDemandes(); // Rafraîchir la liste des demandes pour mettre à jour les compteurs
      }
    } catch (error) {
      console.error('Erreur lors de l\'ouverture des messages:', error);
    }
  };

  const handleDownloadAttachment = async (filename: string) => {
    try {
      const token = localStorage.getItem('adminToken');
      const response = await fetch(`${API_CONFIG.baseURL}/api/messages/attachments/${filename}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (!response.ok) throw new Error('Erreur lors du téléchargement');

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename.split('-').pop() || filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Erreur lors du téléchargement:', error);
      alert('Erreur lors du téléchargement du fichier');
    }
  };

  const Pagination = () => (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className={`relative inline-flex items-center rounded-md px-4 py-2 text-sm font-medium
            ${currentPage === 1
              ? 'bg-gray-100 text-gray-400'
              : 'bg-white text-gray-700 hover:bg-gray-50'}`}
        >
          Précédent
        </button>
        <button
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
          className={`relative ml-3 inline-flex items-center rounded-md px-4 py-2 text-sm font-medium
            ${currentPage === totalPages
              ? 'bg-gray-100 text-gray-400'
              : 'bg-white text-gray-700 hover:bg-gray-50'}`}
        >
          Suivant
        </button>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Affichage de{' '}
            <span className="font-medium">
              {Math.min((currentPage - 1) * itemsPerPage + 1, demandes.length)}
            </span>
            {' '}à{' '}
            <span className="font-medium">
              {Math.min(currentPage * itemsPerPage, demandes.length)}
            </span>
            {' '}sur{' '}
            <span className="font-medium">{demandes.length}</span> demandes
          </p>
        </div>
        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <button
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className={`relative inline-flex items-center rounded-l-md px-2 py-2
                ${currentPage === 1
                  ? 'bg-gray-100 text-gray-400'
                  : 'bg-white text-gray-500 hover:bg-gray-50'}`}
            >
              <span className="sr-only">Précédent</span>
              <ChevronLeft className="h-5 w-5" />
            </button>
            {/* Pages numbers */}
            {Array.from({ length: totalPages }, (_, i) => i + 1)
              .filter(page => 
                page === 1 || 
                page === totalPages || 
                (page >= currentPage - 1 && page <= currentPage + 1)
              )
              .map((page, index, array) => {
                if (index > 0 && array[index - 1] !== page - 1) {
                  return [
                    <span key={`ellipsis-${page}`} className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700">
                      ...
                    </span>,
                    <button
                      key={page}
                      onClick={() => setCurrentPage(page)}
                      className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold
                        ${currentPage === page
                          ? 'z-10 bg-blue-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                          : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'}`}
                    >
                      {page}
                    </button>
                  ];
                }
                return (
                  <button
                    key={page}
                    onClick={() => setCurrentPage(page)}
                    className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold
                      ${currentPage === page
                        ? 'z-10 bg-blue-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                        : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'}`}
                  >
                    {page}
                  </button>
                );
              })}
            <button
              onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
              className={`relative inline-flex items-center rounded-r-md px-2 py-2
                ${currentPage === totalPages
                  ? 'bg-gray-100 text-gray-400'
                  : 'bg-white text-gray-500 hover:bg-gray-50'}`}
            >
              <span className="sr-only">Suivant</span>
              <ChevronRight className="h-5 w-5" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );

  // Fonction pour compter les messages non lus de l'utilisateur
  const countUnreadUserMessages = (messages?: Message[]) => {
    if (!messages) {
      return 0;
    }
    
    let count = 0;
    messages.forEach(message => {
      // Compter le message principal s'il vient de l'utilisateur et n'est pas lu
      if (!message.fromAdmin && !message.lu) {
        count++;
      }
      
      // Compter les réponses non lues de l'utilisateur
      message.responses?.forEach(response => {
        if (!response.fromAdmin && !response.lu) {
          count++;
        }
      });
    });
    
    return count;
  };

  return (
    <div className="p-6">
      <ImageViewer
        isOpen={imageViewer.isOpen}
        onClose={() => setImageViewer(prev => ({ ...prev, isOpen: false }))}
        imageUrl={imageViewer.imageUrl}
        fileName={imageViewer.fileName}
      />

      {notification.show && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification({ ...notification, show: false })}
        />
      )}

      <div className="mb-6">
        <div className="flex flex-col md:flex-row justify-between items-center gap-4 mb-4">
          <div className="relative flex-1">
            <input
              type="text"
              placeholder="Rechercher..."
              value={filters.search}
              onChange={(e) => setFilters(prev => ({ ...prev, search: e.target.value }))}
              className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>
          <button
            onClick={() => setShowFilters(!showFilters)}
            className="flex items-center gap-2 px-4 py-2 bg-gray-100 rounded-lg hover:bg-gray-200"
          >
            <Filter className="h-5 w-5" />
            Filtres
            <ChevronDown className={`h-4 w-4 transform transition-transform ${showFilters ? 'rotate-180' : ''}`} />
          </button>
        </div>

        {showFilters && (
          <div className="bg-white p-4 rounded-lg shadow-sm mb-4 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Type de document
              </label>
              <select
                value={filters.typeDocument}
                onChange={(e) => setFilters(prev => ({ ...prev, typeDocument: e.target.value }))}
                className="w-full border rounded-lg p-2"
              >
                <option value="">Tous</option>
                {typeDocuments.map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Pays
              </label>
              <input
                type="text"
                value={filters.pays}
                onChange={(e) => setFilters(prev => ({ ...prev, pays: e.target.value }))}
                className="w-full border rounded-lg p-2"
                placeholder="Filtrer par pays"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Statut
              </label>
              <select
                value={filters.statut}
                onChange={(e) => setFilters(prev => ({ ...prev, statut: e.target.value }))}
                className="w-full border rounded-lg p-2"
              >
                <option value="">Tous</option>
                {statuts.map(statut => (
                  <option key={statut} value={statut.toLowerCase().replace(' ', '_')}>
                    {statut}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Date début
              </label>
              <input
                type="date"
                value={filters.dateDebut}
                onChange={(e) => setFilters(prev => ({ ...prev, dateDebut: e.target.value }))}
                className="w-full border rounded-lg p-2"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Date fin
              </label>
              <input
                type="date"
                value={filters.dateFin}
                onChange={(e) => setFilters(prev => ({ ...prev, dateFin: e.target.value }))}
                className="w-full border rounded-lg p-2"
              />
            </div>

            <div className="md:col-span-3 lg:col-span-5 flex justify-end">
              <button
                onClick={handleResetFilters}
                className="px-4 py-2 text-sm text-gray-600 hover:text-gray-800"
              >
                Réinitialiser les filtres
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold text-gray-800">Suivi des Demandes</h2>
      </div>

      {showMessageForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-2xl w-full mx-4">
            <div className="flex justify-between items-start mb-4">
              <h3 className="text-lg font-semibold">Envoyer un message</h3>
              <button
                onClick={() => setShowMessageForm(false)}
                className="text-gray-400 hover:text-gray-600"
              >
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            <form onSubmit={handleSendMessage} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Sujet
                </label>
                <input
                  type="text"
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                  value={messageForm.sujet}
                  onChange={(e) => setMessageForm({ ...messageForm, sujet: e.target.value })}
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Message
                </label>
                <textarea
                  required
                  rows={4}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                  value={messageForm.content}
                  onChange={(e) => setMessageForm({ ...messageForm, content: e.target.value })}
                />
              </div>

              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setShowMessageForm(false)}
                  className="px-4 py-2 text-gray-700 border border-gray-300 rounded-md hover:bg-gray-50"
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                >
                  Envoyer
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Référence
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Client
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Demandeur
              </th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date
              </th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Document
              </th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Pays
              </th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Statut
              </th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Documents
              </th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {demandes.map((demande) => (
              <tr 
                key={demande._id} 
                className="hover:bg-gray-50 transition-colors"
              >
                <td className="px-6 py-4">
                  <span className="inline-flex items-center px-2.5 py-1.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                    {demande.reference}
                  </span>
                </td>
                <td className="px-6 py-4">
                  <span className="text-sm font-medium text-gray-900">
                    {demande.nomClient || 'N/A'}
                  </span>
                </td>
                <td className="px-6 py-4">
                  <div className="flex flex-col">
                    <span className="text-sm font-medium text-gray-900">
                      {demande.nom} {demande.prenom}
                    </span>
                    <span className="text-xs text-gray-500">
                      {demande.email}
                    </span>
                  </div>
                </td>
                <td className="px-6 py-4 text-center">
                  <div className="flex flex-col items-center">
                    <span className="text-sm text-gray-900">
                      {formatDate(demande.dateDemande).split(' ')[0]}
                    </span>
                    <span className="text-xs text-gray-500">
                      {formatDate(demande.dateDemande).split(' ')[1]}
                    </span>
                  </div>
                </td>
                <td className="px-6 py-4 text-center">
                  <div className="flex flex-col items-center">
                    <span className="text-sm font-medium text-gray-900">
                      {demande.typeDocument}
                    </span>
                    {demande.commentaires && demande.commentaires.trim() !== '' && (
                      <button
                        onClick={() => setSelectedComment({
                          isOpen: true,
                          content: demande.commentaires,
                          reference: demande.reference,
                          date: demande.dateDemande
                        })}
                        className="mt-1 inline-flex items-center text-xs px-2 py-1 rounded-full 
                                 bg-blue-50 text-blue-600 hover:bg-blue-100 transition-colors"
                      >
                        <span className="mr-1">💬</span>
                        Voir instruction
                      </button>
                    )}
                  </div>
                </td>
                <td className="px-6 py-4 text-center">
                  <span className="text-sm text-gray-900 capitalize">
                    {demande.pays}
                  </span>
                </td>
                <td className="px-6 py-4 text-center">
                  <select
                    value={demande.statut}
                    onChange={(e) => handleStatusChange(demande._id, e.target.value)}
                    className={`text-sm font-medium rounded-full px-3 py-1 border-0 mx-auto ${getStatusColor(demande.statut)}`}
                  >
                    <option value="en_attente">En attente</option>
                    <option value="en_cours">En cours</option>
                    <option value="terminé">Terminé</option>
                    <option value="rejeté">Rejeté</option>
                  </select>
                </td>
                <td className="px-6 py-4">
                  <div className="flex justify-center -space-x-2">
                    {demande.images.map((image, index) => (
                      <div
                        key={index}
                        className="relative group"
                      >
                        <img
                          src={getImageUrl(image)}
                          alt={`Document ${index + 1}`}
                          className="h-8 w-8 rounded-full border-2 border-white object-cover cursor-pointer hover:z-10 transition-transform hover:scale-105"
                          onClick={() => handleImageClick(image)}
                        />
                      </div>
                    ))}
                  </div>
                </td>
                <td className="px-6 py-4">
                  <div className="flex items-center justify-center space-x-2">
                    {demande.images.map((image, index) => (
                      <button
                        key={index}
                        onClick={() => handleDownloadImage(image)}
                        className="text-blue-500 hover:text-blue-700 transition-colors"
                        title={`Télécharger le document ${index + 1}`}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                        </svg>
                      </button>
                    ))}
                    <button
                      onClick={() => handleMessageClick(demande)}
                      className="relative text-blue-500 hover:text-blue-700 transition-colors"
                      title="Messages"
                    >
                      <MessageCircle className="h-5 w-5" />
                      {demande.messages && countUnreadUserMessages(demande.messages) > 0 && (
                        <span className="absolute -top-1 -right-1 h-4 w-4 flex items-center justify-center text-xs text-white bg-red-500 rounded-full animate-pulse">
                          {countUnreadUserMessages(demande.messages)}
                        </span>
                      )}
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination />
      </div>

      {/* Modal des messages */}
      {selectedDemandeMessages.showMessages && selectedDemandeMessages.demande && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-2xl w-full mx-4">
            <div className="flex justify-between items-start mb-4">
              <div>
                <h3 className="text-lg font-semibold">
                  Conversation - Demande de {selectedDemandeMessages.demande.typeDocument}
                </h3>
                <p className="text-sm text-gray-500">
                  {selectedDemandeMessages.demande.email}
                </p>
              </div>
              <button
                onClick={() => setSelectedDemandeMessages({ demande: null, showMessages: false })}
                className="text-gray-400 hover:text-gray-600"
              >
                <X className="h-5 w-5" />
              </button>
            </div>

            <div className="h-96 overflow-y-auto border rounded-lg p-4 mb-4">
              {messages.map((message) => (
                <div key={message._id}>
                  <div className={`flex ${message.fromAdmin ? 'justify-end' : 'justify-start'} mb-4`}>
                    <div className={`max-w-[70%] rounded-lg p-3 ${
                      message.fromAdmin 
                        ? 'bg-blue-500 text-white' 
                        : 'bg-gray-100 text-gray-800'
                    }`}>
                      <p className="text-sm">{message.content}</p>
                      <span className="text-xs opacity-75 mt-1 block">
                        {new Date(message.dateSend).toLocaleString()}
                      </span>
                      
                      {message.attachments && message.attachments.length > 0 && (
                        <div className="mt-2 space-y-1">
                          <p className="text-xs opacity-75">Pièces jointes:</p>
                          {message.attachments.map((attachment, index) => (
                            <div key={index} className="flex items-center justify-between">
                              <span className="text-xs">{attachment.split('-').pop()}</span>
                              <button
                                onClick={() => handleDownloadAttachment(attachment)}
                                className="text-xs underline hover:no-underline"
                              >
                                Télécharger
                              </button>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  {message.responses?.map((response) => (
                    <div
                      key={response._id}
                      className={`flex ${response.fromAdmin ? 'justify-end' : 'justify-start'} mb-4 ml-8`}
                    >
                      <div className={`max-w-[70%] rounded-lg p-3 ${
                        response.fromAdmin 
                          ? 'bg-blue-500 text-white' 
                          : 'bg-gray-100 text-gray-800'
                      }`}>
                        <p className="text-sm">{response.content}</p>
                        <span className="text-xs opacity-75 mt-1 block">
                          {new Date(response.dateSend).toLocaleString()}
                        </span>

                        {response.attachments && response.attachments.length > 0 && (
                          <div className="mt-2 space-y-1">
                            <p className="text-xs opacity-75">Pièces jointes:</p>
                            {response.attachments.map((attachment, index) => (
                              <div key={index} className="flex items-center justify-between">
                                <span className="text-xs">{attachment.split('-').pop()}</span>
                                <button
                                  onClick={() => handleDownloadAttachment(attachment)}
                                  className="text-xs underline hover:no-underline"
                                >
                                  Télécharger
                                </button>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>

            <form onSubmit={handleSendMessage} className="space-y-3">
              <div className="flex space-x-3">
                <input
                  type="text"
                  value={messageForm.content}
                  onChange={(e) => setMessageForm({ ...messageForm, content: e.target.value })}
                  className="flex-1 px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                  placeholder="Votre message..."
                />
                <button
                  type="button"
                  onClick={() => fileInputRef.current?.click()}
                  className="px-3 py-2 text-gray-600 hover:text-blue-600 transition-colors"
                  title="Ajouter une pièce jointe"
                >
                  <Paperclip className="w-5 h-5" />
                </button>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileSelect}
                  className="hidden"
                  multiple
                  accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                />
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                >
                  Envoyer
                </button>
              </div>

              {attachments.length > 0 && (
                <div className="flex flex-wrap gap-2 mt-2">
                  {attachments.map((file, index) => (
                    <div
                      key={index}
                      className="flex items-center gap-2 bg-gray-100 px-3 py-1 rounded-full text-sm"
                    >
                      <Paperclip className="w-4 h-4 text-gray-500" />
                      <span className="truncate max-w-[150px]">{file.name}</span>
                      <button
                        onClick={() => removeAttachment(index)}
                        className="text-gray-500 hover:text-red-500"
                      >
                        <X className="w-4 h-4" />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </form>
          </div>
        </div>
      )}

      <CertificateModal
        isOpen={showCertificateModal}
        onClose={() => {
          setShowCertificateModal(false);
          setSelectedDemandeId(null);
        }}
        onSubmit={handleCertificateSubmit}
      />

      {/* Ajouter la modal des commentaires */}
      {selectedComment.isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-2xl w-full mx-4">
            <div className="flex justify-between items-start mb-4">
              <div>
                <h3 className="text-lg font-semibold">
                  Instructions - {selectedComment.reference}
                </h3>
                <p className="text-sm text-gray-500">
                  {new Date(selectedComment.date).toLocaleDateString('fr-FR', {
                    day: '2-digit',
                    month: 'long',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit'
                  })}
                </p>
              </div>
              <button
                onClick={() => setSelectedComment(prev => ({ ...prev, isOpen: false }))}
                className="text-gray-400 hover:text-gray-600"
              >
                <X className="h-5 w-5" />
              </button>
            </div>

            <div className="mt-4 p-4 bg-gray-50 rounded-lg">
              <p className="text-gray-700 whitespace-pre-wrap">
                {selectedComment.content}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DemandeManagement; 