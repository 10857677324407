import { cn } from "../../lib/utils";
import React, { useRef, useState, useEffect } from "react";
import { motion } from "framer-motion";
import { IconUpload } from "@tabler/icons-react";
import { useDropzone } from "react-dropzone";
 
const mainVariant = {
  initial: {
    x: 0,
    y: 0,
  },
  animate: {
    x: 20,
    y: -20,
    opacity: 0.9,
  },
};
 
const secondaryVariant = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
};
 
interface FileUploadProps {
  onChange?: (files: File[]) => void;
  maxFiles?: number;
  currentFiles?: File[];
  accept?: string;
}

export const FileUpload: React.FC<FileUploadProps> = ({
  onChange,
  maxFiles = 2,
  currentFiles = [],
  accept
}) => {
  const [files, setFiles] = useState<File[]>(currentFiles);
  const fileInputRef = useRef<HTMLInputElement>(null);
 
  const handleFileChange = (newFiles: File[]) => {
    if (files.length + newFiles.length > maxFiles) {
      alert(`Vous ne pouvez télécharger que ${maxFiles} fichiers maximum`);
      return;
    }
    
    const imageFiles = newFiles.filter(file => file.type.startsWith('image/'));
    if (imageFiles.length !== newFiles.length) {
      alert('Seules les images sont acceptées');
      return;
    }

    const updatedFiles = [...files, ...imageFiles];
    setFiles(updatedFiles);
    onChange && onChange(updatedFiles);
  };

  const handleRemoveFile = (indexToRemove: number) => {
    const updatedFiles = files.filter((_, index) => index !== indexToRemove);
    setFiles(updatedFiles);
    onChange && onChange(updatedFiles);
  };
 
  const handleClick = () => {
    fileInputRef.current?.click();
  };
 
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: true,
    maxFiles,
    noClick: false,
    onDrop: handleFileChange,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg', '.gif']
    },
    onDropRejected: (fileRejections) => {
      fileRejections.forEach(rejection => {
        if (rejection.errors[0]?.code === 'file-invalid-type') {
          alert('Seules les images sont acceptées');
        }
      });
    },
  });
 
  useEffect(() => {
    setFiles(currentFiles);
  }, [currentFiles]);
 
  return (
    <div className="w-full" {...getRootProps()}>
      <motion.div
        whileHover="animate"
        className={cn(
          "p-6 group/file block rounded-lg cursor-pointer w-full relative overflow-hidden border-2 border-dashed",
          isDragActive ? "border-blue-500 bg-blue-50" : "border-gray-300 hover:border-blue-500",
          "transition-colors"
        )}
      >
        <input {...getInputProps()} />
        
        <div className="flex flex-col items-center justify-center">
          <p className="relative z-20 font-sans font-medium text-gray-700 dark:text-neutral-300 text-sm">
            {isDragActive ? "Déposez les fichiers ici..." : "Télécharger des images"}
          </p>
          <p className="relative z-20 font-sans text-sm text-gray-500 dark:text-neutral-400 mt-1">
            Glissez-déposez vos images ici ou cliquez pour sélectionner ({files.length}/{maxFiles})
          </p>
          <div className="relative w-full mt-6 max-w-xl mx-auto">
            {files.length > 0 &&
              files.map((file, idx) => (
                <motion.div
                  key={"file" + idx}
                  layoutId={idx === 0 ? "file-upload" : "file-upload-" + idx}
                  className="relative overflow-hidden z-40 bg-white border border-gray-200 flex flex-col items-start justify-start p-3 mt-2 w-full mx-auto rounded-md group"
                >
                  <div className="flex justify-between w-full items-center gap-4">
                    <motion.p
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      layout
                      className="text-sm text-gray-700 dark:text-neutral-300 truncate max-w-xs"
                    >
                      {file.name}
                    </motion.p>
                    <div className="flex items-center gap-2">
                      <motion.p
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        layout
                        className="text-xs text-gray-500"
                      >
                        {(file.size / (1024 * 1024)).toFixed(2)} MB
                      </motion.p>
                      <motion.button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveFile(idx);
                        }}
                        className="text-red-500 hover:text-red-700 p-1 rounded-full hover:bg-red-50 transition-colors opacity-0 group-hover:opacity-100"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                      >
                        <svg 
                          xmlns="http://www.w3.org/2000/svg" 
                          className="h-4 w-4" 
                          fill="none" 
                          viewBox="0 0 24 24" 
                          stroke="currentColor"
                        >
                          <path 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            strokeWidth={2} 
                            d="M6 18L18 6M6 6l12 12" 
                          />
                        </svg>
                      </motion.button>
                    </div>
                  </div>
                </motion.div>
              ))}
            {!files.length && (
              <motion.div
                layoutId="file-upload"
                variants={mainVariant}
                transition={{
                  type: "spring",
                  stiffness: 300,
                  damping: 20,
                }}
                className="relative flex items-center justify-center h-16 mt-2"
              >
                {isDragActive ? (
                  <motion.p
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="text-sm text-blue-600 flex flex-col items-center"
                  >
                    Déposez ici
                    <IconUpload className="h-4 w-4 mt-1" />
                  </motion.p>
                ) : (
                  <IconUpload className="h-4 w-4 text-gray-400" />
                )}
              </motion.div>
            )}
          </div>
        </div>
      </motion.div>
    </div>
  );
};
 
export function GridPattern() {
  const columns = 41;
  const rows = 11;
  return (
    <div className="flex bg-gray-100 dark:bg-neutral-900 flex-shrink-0 flex-wrap justify-center items-center gap-x-px gap-y-px  scale-105">
      {Array.from({ length: rows }).map((_, row) =>
        Array.from({ length: columns }).map((_, col) => {
          const index = row * columns + col;
          return (
            <div
              key={`${col}-${row}`}
              className={`w-10 h-10 flex flex-shrink-0 rounded-[2px] ${
                index % 2 === 0
                  ? "bg-gray-50 dark:bg-neutral-950"
                  : "bg-gray-50 dark:bg-neutral-950 shadow-[0px_0px_1px_3px_rgba(255,255,255,1)_inset] dark:shadow-[0px_0px_1px_3px_rgba(0,0,0,1)_inset]"
              }`}
            />
          );
        })
      )}
    </div>
  );
} 