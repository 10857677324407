import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, ChevronUp, Filter } from 'lucide-react';
import { Dropdown } from '../ui/dropdown';
import { Filters } from '../../types/filters';

interface FilterSectionProps {
  filters: Filters;
  setFilters: (filters: Filters) => void;
  onReset: () => void;
}

export const FilterSection: React.FC<FilterSectionProps> = ({ 
  filters, 
  setFilters,
  onReset 
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 5 }, (_, i) => ({
    value: currentYear - i,
    label: `${currentYear - i}`
  }));

  const months = Array.from({ length: 12 }, (_, i) => ({
    value: i + 1,
    label: new Date(2024, i).toLocaleString('fr-FR', { month: 'long' })
  }));

  const documentTypes = [
    { value: 'Carte d\'identité', label: "Carte d'identité" },
    { value: 'Passeport', label: "Passeport" },
    { value: 'Permis de conduire', label: "Permis de conduire" }
  ];

  const statuts = [
    { value: 'en_attente', label: "En attente" },
    { value: 'en_cours', label: "En cours" },
    { value: 'terminé', label: "Terminé" },
    { value: 'rejeté', label: "Rejeté" }
  ];

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-200">
      <div 
        className="p-4 flex items-center justify-between cursor-pointer"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center space-x-2">
          <Filter className="w-4 h-4 text-gray-500" />
          <h3 className="text-sm font-medium text-gray-700">Filtres</h3>
        </div>
        <div className="flex items-center space-x-4">
          <button 
            onClick={(e) => {
              e.stopPropagation();
              onReset();
            }}
            className="text-sm text-blue-600 hover:text-blue-700"
          >
            Réinitialiser
          </button>
          {isExpanded ? (
            <ChevronUp className="w-4 h-4 text-gray-500" />
          ) : (
            <ChevronDown className="w-4 h-4 text-gray-500" />
          )}
        </div>
      </div>

      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="overflow-hidden"
          >
            <div className="p-4 pt-0 border-t border-gray-100">
              <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4">
                <Dropdown
                  label="Année"
                  value={filters.year}
                  onChange={(value) => setFilters({ ...filters, year: parseInt(value) })}
                  options={years}
                />

                <Dropdown
                  label="Mois"
                  value={filters.month || ''}
                  onChange={(value) => setFilters({ ...filters, month: value ? parseInt(value) : null })}
                  options={months}
                  placeholder="Tous les mois"
                />

                <Dropdown
                  label="Type de document"
                  value={filters.type}
                  onChange={(value) => setFilters({ ...filters, type: value })}
                  options={documentTypes}
                  placeholder="Tous les types"
                />

                <div className="space-y-2">
                  <label className="text-sm font-medium text-gray-700">Pays</label>
                  <input
                    type="text"
                    value={filters.pays}
                    onChange={(e) => setFilters({ ...filters, pays: e.target.value })}
                    placeholder="Filtrer par pays"
                    className="w-full rounded-lg border-gray-200 text-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-200 focus:outline-none"
                  />
                </div>

                <Dropdown
                  label="Statut"
                  value={filters.statut}
                  onChange={(value) => setFilters({ ...filters, statut: value })}
                  options={statuts}
                  placeholder="Tous les statuts"
                />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}; 