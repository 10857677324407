import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  ChevronDown, 
  HelpCircle, 
  FileText, 
  Clock, 
  MessageCircle, 
  CheckCircle,
  AlertCircle,
  ArrowRight,
  Shield
} from 'lucide-react';

interface FAQItem {
  question: string;
  answer: string;
}

interface ProcessStep {
  title: string;
  description: string;
  icon: React.ReactNode;
  details?: string[];
}

const Aide: React.FC = () => {
  const [activeTab, setActiveTab] = useState<'guide' | 'faq'>('guide');
  const [expandedFAQ, setExpandedFAQ] = useState<number | null>(null);
  const [selectedStep, setSelectedStep] = useState<number | null>(null);

  const faqItems: FAQItem[] = [
    {
      question: "Quels sont les critères de qualité pour les documents ?",
      answer: "Pour garantir une analyse optimale, vos documents doivent être : en couleur, parfaitement lisibles, non flous, sans reflets, et non recadrés. Les documents doivent montrer l'intégralité du document officiel."
    },
    {
      question: "Quels types de documents sont acceptés ?",
      answer: "Nous traitons les documents d'identité officiels : cartes d'identité, passeports, titres de séjour, visas et permis de conduire."
    },
    {
      question: "Comment s'assurer que ma demande sera traitée rapidement ?",
      answer: "Pour un traitement optimal : 1) Fournissez des images de haute qualité, 2) Remplissez tous les champs du formulaire avec précision, 3) Ajoutez des commentaires pertinents si nécessaire, 4) Vérifiez que le type de document sélectionné correspond bien à votre document."
    },
    {
      question: "Comment suivre l'avancement de ma demande ?",
      answer: "Vous pouvez suivre votre demande dans l'onglet 'Historique'. Vous recevrez des notifications à chaque étape importante du processus. En cas de besoin, notre équipe vous contactera via la messagerie intégrée."
    }
  ];

  const processSteps: ProcessStep[] = [
    {
      title: "Préparation des documents",
      description: "Assurez-vous d'avoir des documents conformes aux exigences de qualité",
      icon: <FileText className="w-6 h-6 text-blue-500" />,
      details: [
        "Document original non endommagé",
        "Photo en couleur ou en noir et blanc",
        "Tous les coins du document visibles",
        "Absence de reflets ou d'ombres si possible",
        "Format accepté (PNG, JPEG)"
      ]
    },
    {
      title: "Soumission sécurisée",
      description: "Vos documents sont transmis via un canal sécurisé et chiffré",
      icon: <Shield className="w-6 h-6 text-green-500" />,
      details: [
        "Transmission chiffrée de bout en bout",
        "Stockage temporaire sécurisé",
        "Accès restreint et contrôlé",
        "Conformité RGPD"
      ]
    },
    {
      title: "Analyse experte",
      description: "Notre équipe d'experts analyse minutieusement vos documents",
      icon: <Clock className="w-6 h-6 text-yellow-500" />,
      details: [
        "Vérification des éléments de sécurité",
        "Contrôle de cohérence",
        "Validation des informations",
        "Double vérification"
      ]
    },
    {
      title: "Suivi et communication",
      description: "Restez informé à chaque étape du processus",
      icon: <MessageCircle className="w-6 h-6 text-purple-500" />,
      details: [
        "Notifications en temps réel",
        "Messagerie directe avec les experts",
        "Historique détaillé",
        "Rapport final complet"
      ]
    }
  ];

  return (
    <div className="max-w-5xl mx-auto">
      <div className="bg-white rounded-xl shadow-lg overflow-hidden">
        <div className="p-8">
          <div className="flex items-center space-x-3 mb-8">
            <HelpCircle className="w-7 h-7 text-blue-600" />
            <h2 className="text-2xl font-bold text-gray-800">Centre d'aide</h2>
          </div>

          <div className="flex space-x-4 mb-8">
            <button
              onClick={() => setActiveTab('guide')}
              className={`px-4 py-2 rounded-lg font-medium transition-all duration-200 ${
                activeTab === 'guide'
                  ? 'bg-blue-600 text-white shadow-md'
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
              }`}
            >
              Guide du processus
            </button>
            <button
              onClick={() => setActiveTab('faq')}
              className={`px-4 py-2 rounded-lg font-medium transition-all duration-200 ${
                activeTab === 'faq'
                  ? 'bg-blue-600 text-white shadow-md'
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
              }`}
            >
              Questions fréquentes
            </button>
          </div>

          <AnimatePresence mode="wait">
            {activeTab === 'guide' && (
              <motion.div
                key="guide"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="space-y-6"
              >
                {processSteps.map((step, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className={`p-6 rounded-xl transition-all duration-300 ${
                      selectedStep === index
                        ? 'bg-blue-50 border-2 border-blue-200 shadow-md'
                        : 'bg-white border border-gray-200 hover:border-blue-300 hover:shadow-md'
                    }`}
                    onClick={() => setSelectedStep(selectedStep === index ? null : index)}
                  >
                    <div className="flex items-center space-x-4">
                      <div className={`p-3 rounded-full ${
                        selectedStep === index ? 'bg-blue-100' : 'bg-gray-50'
                      }`}>
                        {step.icon}
                      </div>
                      <div className="flex-1">
                        <div className="flex items-center space-x-2">
                          <span className="text-sm font-medium text-blue-600">Étape {index + 1}</span>
                          <ArrowRight className="w-4 h-4 text-blue-400" />
                        </div>
                        <h3 className="font-semibold text-lg text-gray-800">
                          {step.title}
                        </h3>
                        <p className="text-gray-600 mt-1">
                          {step.description}
                        </p>
                      </div>
                    </div>

                    <AnimatePresence>
                      {selectedStep === index && step.details && (
                        <motion.div
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: 'auto' }}
                          exit={{ opacity: 0, height: 0 }}
                          className="mt-4 pt-4 border-t border-gray-200"
                        >
                          <ul className="space-y-2">
                            {step.details.map((detail, i) => (
                              <li key={i} className="flex items-center space-x-2 text-gray-600">
                                <CheckCircle className="w-4 h-4 text-green-500" />
                                <span>{detail}</span>
                              </li>
                            ))}
                          </ul>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </motion.div>
                ))}
              </motion.div>
            )}

            {activeTab === 'faq' && (
              <motion.div
                key="faq"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="space-y-4"
              >
                {faqItems.map((item, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className="rounded-xl border border-gray-200 overflow-hidden"
                  >
                    <button
                      onClick={() => setExpandedFAQ(expandedFAQ === index ? null : index)}
                      className="w-full px-6 py-4 text-left flex items-center justify-between hover:bg-gray-50"
                    >
                      <div className="flex items-center space-x-4">
                        <div className={`p-2 rounded-full ${
                          expandedFAQ === index ? 'bg-blue-100' : 'bg-gray-100'
                        }`}>
                          <AlertCircle className={`w-5 h-5 ${
                            expandedFAQ === index ? 'text-blue-600' : 'text-gray-500'
                          }`} />
                        </div>
                        <span className="font-medium text-gray-800">{item.question}</span>
                      </div>
                      <ChevronDown
                        className={`w-5 h-5 text-gray-500 transition-transform duration-200 ${
                          expandedFAQ === index ? 'transform rotate-180' : ''
                        }`}
                      />
                    </button>
                    <AnimatePresence>
                      {expandedFAQ === index && (
                        <motion.div
                          initial={{ height: 0 }}
                          animate={{ height: 'auto' }}
                          exit={{ height: 0 }}
                          className="overflow-hidden"
                        >
                          <div className="px-6 py-4 bg-gray-50 border-t border-gray-200">
                            <p className="text-gray-600 leading-relaxed">
                              {item.answer}
                            </p>
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </motion.div>
                ))}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default Aide; 