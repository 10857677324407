import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import AdminLogin from './pages/admin/AdminLogin';
import AdminDashboard from './pages/admin/AdminDashboard';
import UserLogin from './pages/user/Login';
import UserDashboard from './pages/user/Dashboard';
import IntroScreen from './components/ui/IntroScreen';
import API_CONFIG from './config/api';

const App: React.FC = () => {
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(false);
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const [showIntro, setShowIntro] = useState(false);
  const [justLoggedIn, setJustLoggedIn] = useState(false);

  useEffect(() => {
    const adminToken = localStorage.getItem('adminToken');
    const userToken = localStorage.getItem('userToken');
    setIsAdminAuthenticated(!!adminToken);
    setIsUserAuthenticated(!!userToken);
  }, []);

  const handleAdminLogin = async (email: string, password: string) => {
    try {
      const response = await fetch(`${API_CONFIG.baseURL}/api/auth/admin/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Erreur de connexion');
      }

      localStorage.setItem('adminToken', data.token);
      setIsAdminAuthenticated(true);
      return { success: true };
    } catch (error) {
      return { 
        success: false, 
        error: error instanceof Error ? error.message : 'Erreur de connexion' 
      };
    }
  };

  const handleUserLogin = async (email: string, password: string) => {
    try {
      const response = await fetch(`${API_CONFIG.baseURL}/api/auth/user/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Erreur de connexion');
      }

      localStorage.setItem('userToken', data.token);
      setIsUserAuthenticated(true);
      setShowIntro(true);
      setJustLoggedIn(true);
      return { success: true };
    } catch (error) {
      return { 
        success: false, 
        error: error instanceof Error ? error.message : 'Erreur de connexion' 
      };
    }
  };

  const handleUserLogout = () => {
    localStorage.removeItem('userToken');
    setIsUserAuthenticated(false);
  };

  const handleAdminLogout = () => {
    localStorage.removeItem('adminToken');
    setIsAdminAuthenticated(false);
  };

  if (showIntro && isUserAuthenticated && justLoggedIn) {
    return <IntroScreen onComplete={() => {
      setShowIntro(false);
      setJustLoggedIn(false);
    }} />;
  }

  return (
    <Router>
      <Routes>
        {/* Routes Admin */}
        <Route 
          path="/admin/login" 
          element={!isAdminAuthenticated ? <AdminLogin onLogin={handleAdminLogin} /> : <Navigate to="/admin/dashboard" />} 
        />
        <Route 
          path="/admin/dashboard" 
          element={isAdminAuthenticated ? 
            <AdminDashboard userName="Admin" onLogout={handleAdminLogout} /> : 
            <Navigate to="/admin/login" />
          } 
        />
        <Route 
          path="/admin" 
          element={<Navigate to="/admin/dashboard" />} 
        />

        {/* Routes Client */}
        <Route 
          path="/login" 
          element={!isUserAuthenticated ? <UserLogin onLogin={handleUserLogin} /> : <Navigate to="/dashboard" />} 
        />
        <Route 
          path="/dashboard" 
          element={isUserAuthenticated ? 
            <UserDashboard onLogout={handleUserLogout} /> : 
            <Navigate to="/login" />
          } 
        />
        <Route 
          path="/" 
          element={<Navigate to={isUserAuthenticated ? "/dashboard" : "/login"} />} 
        />
      </Routes>
    </Router>
  );
};

export default App;
